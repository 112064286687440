import { useEffect, useState } from "react";
import PageWrapper from "./PageWrapper";

import _ from 'lodash'
import styled from 'styled-components'

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProviders } from "../../../store/actions/templatesActions/providerActions";
import moment from "moment";
import Table from "../sharedComponents/Table";
import { IconButton, Tooltip, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Launch } from "@mui/icons-material";


export default function ProvidersPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const selectedCompany = useSelector((state) => state.session.selectedCompany);

  const {
    rows,
    //pagination,
  } = useSelector(state => state.provider)
  
  useEffect(() => {
    dispatch(fetchProviders(selectedCompany))
  }, [])


  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const handleOpenTemplates = (uuid) => {
    history.push(`/${uuid}`);  }

  const tableColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Название', flex: 1 },
    { field: 'company', headerName: 'Организация', flex: 1 },
    { field: 'date', headerName: 'Дата создания', flex: 1 },
    {
      field: "action",
      headerName: "Шаблоны",
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title="Открыть шаблоны" onClick={() => handleOpenTemplates(params.row.uuidProvider)}>
            <IconButton>
              <Launch />
            </IconButton>
          </Tooltip>
        );
      }
    },

  ]

  const tableRows = Array.isArray(rows?.data)
  ? rows.data?.map((provider, index) => ({
      id: index + 1,
      uuidProvider: provider.ids?.uuidProvider,
      name: provider.settings?.name,
      company: provider.provider  || "Неизвестная компания",
      date: provider.date?.created
        ? moment.unix(provider.date.created).format("DD.MM.yyyy")
        : "—",
    }))
  : [];

  return (
    <>
    
    <Box 
    onClick={() => history.push('/chat')} 
    sx={{
      position: "relative",
      top: "39px",
      left: "60px",
      display: "flex", 
      alignItems: "center", 
      color: "primary.main", 
      cursor: "pointer", 
      mb: 2,
      gap: 1 
    }}
  >
    <ArrowBackIcon />
    <span>Назад</span>
  </Box>
    <PageWrapper title={'Провайдеры'}>
      <Parent> 
      <Table
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          columns={tableColumns}
          rows={tableRows} />
      </Parent>
    </PageWrapper >
    </>
  );
}

const Parent = styled.div`
  width: 100%;
  .provider {
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .providers {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 5px;
    column-gap: 15px;
  }
`
