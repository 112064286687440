// Action Types
export const SHOW_MODAL = 'modal/SHOW_MODAL';
export const CLOSE_MODAL = 'modal/CLOSE_MODAL';

// Action Creators
export const showModal = ({ title, content }) => ({
  type: SHOW_MODAL,
  payload: { title, content },
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});
