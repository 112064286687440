import { useDispatch, useSelector } from 'react-redux';
import List from '@mui/material/List';
import QualityFeedbackOption from '../QualityFeedbackOption/QualityFeedbackOption';
import { useCallback } from 'react';
import { setQualityFeedbackOptions } from 'store/actions';
import { SettingsCardStyled } from 'views/Settings/components/SettingsNotifications/SettingsNotifications';
import { Box, Button, Typography } from '@mui/material';
import { Add as AddIcon } from '@material-ui/icons';

function QualityFeedbackOptions() {
  const dispatch = useDispatch();
  const showQualitySettings = useSelector(
    (state) => state.settings.showQualitySettings,
  );
  const feedbackOptions = useSelector((state) => state.quality.feedbackOptions);

  const handleChangeItem = useCallback(
    (value, newText) => {
      dispatch(
        setQualityFeedbackOptions(
          feedbackOptions.map((text, index) =>
            index + 1 === value ? newText : text,
          ),
        ),
      );
    },
    [dispatch, feedbackOptions],
  );

  const handleRemoveItem = useCallback(
    (index) => {
      dispatch(
        setQualityFeedbackOptions(
          feedbackOptions.filter((_, i) => i !== index),
        ),
      );
    },
    [dispatch, feedbackOptions],
  );

  const handleAddItem = useCallback(() => {
    dispatch(
      setQualityFeedbackOptions([...feedbackOptions, 'Новый вариант ответа']),
    );
  }, [dispatch, feedbackOptions]);

  return (
    <div>
      <Typography className="card-title">Варианты ответов</Typography>
      <SettingsCardStyled sx={{ marginTop: 2 }}>
        <List>
          {feedbackOptions.map((value, index) => (
            <QualityFeedbackOption
              key={index}
              text={value}
              value={index + 1}
              disabled={!showQualitySettings}
              onChange={handleChangeItem}
              onRemove={handleRemoveItem}
            />
          ))}
        </List>
        <Box display={'flex'} alignItems={'end'} justifyContent={'end'}>
          <Button
            onClick={handleAddItem}
            startIcon={<AddIcon />}
            variant="outlined"
            disabled={!showQualitySettings}
          >
            Добавить
          </Button>
        </Box>
      </SettingsCardStyled>
    </div>
  );
}

export default QualityFeedbackOptions;
