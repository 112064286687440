import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import 'moment/locale/ru';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { I18nextProvider } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Integrations } from '@sentry/tracing';
import SentryRRWeb from '@sentry/rrweb';
import { unregister } from 'serviceWorker';
import { ScrollReset } from './components';
import { DEFAULT_LANGUAGE } from './config';
import configureI18n from './i18n';
import Routing from './routing';
import { configureStore } from './store';
import { setLanguage } from './store/actions/settingsActions';
import configureTheme from './theme';
import GlobalStyle from './theme/global';
import { TimeTrackingProvider } from 'provider';

unregister();

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing(), new SentryRRWeb({})],
    tracesSampleRate: 1.4,
  });
}

const store = configureStore();

let currentLanguage = store.getState().settings.language;
if (!currentLanguage) {
  currentLanguage = DEFAULT_LANGUAGE;
  store.dispatch(setLanguage(currentLanguage));
}
const i18n = configureI18n(currentLanguage);

const App = () => {
  useEffect(() => {
    moment.locale('ru');
  }, []);
  const prefersDarkMode = store.getState().settings.darkMode;

  const theme = React.useMemo(() => configureTheme(prefersDarkMode), [
    prefersDarkMode,
  ]);

  return (
    <I18nextProvider i18n={i18n}>
      <StoreProvider store={store}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider maxSnack={3}>
                <BrowserRouter>
                  <TimeTrackingProvider>
                    <ScrollReset />
                    <GlobalStyle />
                    <Routing />
                  </TimeTrackingProvider>
                </BrowserRouter>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StoreProvider>
    </I18nextProvider>
  );
};

const AppRoot = process.env.REACT_APP_SENTRY_DSN ? Sentry.withProfiler(App) : App;

export default AppRoot;
