import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import {setSelectedCompany} from 'store/actions/statisticsActions';
import StatFilters from '../components/StatFilters';
import CompanySelect from './CompanySelect';
import DepartmentSelect from './DepartmentSelect';

export default function Filters() {
  const dispatch = useDispatch();
  const { selectedCompany: globalSelectedCompany } = useSelector((state) => state.session);
  const {selectedCompany} = useSelector((state) => state.statistics);

  useEffect(() => {
    if (!selectedCompany) {
      dispatch(setSelectedCompany(globalSelectedCompany))
    }
  }, [selectedCompany, globalSelectedCompany]);

  const handleSelect = (event) => {
    dispatch(setSelectedCompany(event.target.value));
  };

  return (
    <View>
      <StatFilters />
      <div className="filter">
        <CompanySelect
          value={selectedCompany}
          onSelect={handleSelect}
        />
      </div>
      <div className="filter">
        <DepartmentSelect />
      </div>
    </View>
  );
}

const View = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 22px;
  .filter {
    background-color: #f3f4f5;
    padding: 4px 4px 4px 9px;
    border-radius: 8px;
  }
  select:focus {
    background: inherit;
  }
  .MuiInput-underline {
    &:after {
      content: '';
      border: 0;
    }
    &:before {
      content: '';
      border: 0;
    }
  }
  .listFilterFix {
    border: 0;
    &:before {
      content: '';
      border: unset !important;
    }
  }
  .select {
    &:before,
    &:after {
      border-bottom: none !important;
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
  }
`;
