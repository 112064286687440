const SyncComponent = ({ handleSync }) => {
    return (
      <div
        onClick={handleSync}
        style={{
          padding: "5px",
          fontSize: "18px",
          cursor: "pointer",
          color: "#006ae4",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f5f5f5",
          gap: "8px", 
        }}
      >
        <svg
          width="22px"
          height="22px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 4V1L8 5l4 4V6a7 7 0 0 1 6.92 5.84 1 1 0 0 0 1.96-.38A9 9 0 0 0 12 4Zm7 11a1 1 0 0 0-1 1 7 7 0 0 1-6.92 5.84 1 1 0 0 0-1 .99 1 1 0 0 0 1 1A9 9 0 0 0 20 17a1 1 0 0 0-1-1ZM3 11a1 1 0 0 0 1-1 7 7 0 0 1 6.92-5.84 1 1 0 0 0 1-.99 1 1 0 0 0-1-1A9 9 0 0 0 2 10a1 1 0 0 0 1 1Zm3 2a1 1 0 0 0-1 1 7 7 0 0 0 6.92 5.84 1 1 0 0 1 1 .99 1 1 0 0 1-1 1A9 9 0 0 1 2 13a1 1 0 0 1 1-1Z"
            fill="currentColor"
          />
        </svg>
      </div>
    );
  };

  export default SyncComponent;
