import { FETCH_PROVIDERS_PENDING, 
         FETCH_PROVIDERS_SUCCESS, 
         FETCH_PROVIDERS_FAILURE, 
         SET_PROVIDERS } from '../../actions/templatesActions/providerActions';

const initialState = {
  rows: [],
  pagination: [],
  loading: false,
  error: null,
};

const providerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROVIDERS_PENDING:
      return { ...state, loading: true, error: null };

    case FETCH_PROVIDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        rows: action.payload,
        pagination: action.payload.pagination,
      };

    case FETCH_PROVIDERS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case SET_PROVIDERS:
      return { ...state, rows: action.payload };

    default:
      return state;
  }
};

export default providerReducer;
