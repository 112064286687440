import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppAccordion from '../../../../components/Accordion/AppAccordion';
import RoutingScriptAccordionTitle from './components/RoutingScriptAccordionTitle/RoutingScriptAccordionTitle';
import { changeRoutingScript, showModal } from '../../../../store/actions';
import AppButton from '../../../../components/AppButton/AppButton';
import { ReactComponent as UserIcon } from '../../../../assets/img/edit-user.svg';
import { ReactComponent as MessagesIcon } from '../../../../assets/img/messages.svg';
import { ReactComponent as SettingsIcon } from '../../../../assets/img/settings-outlined.svg';
import { ReactComponent as UsersIcon } from '../../../../assets/img/users.svg';
import HorizontalDivider from '../../../../layouts/Modal/Settings/components/HorizontalDivider';
import RoutingActionSelect from './components/RoutingActionSelect';
import { PERMISSIONS } from '../../index';
import { RouteEmployees, RouteHeader } from './components';

const SettingsRouting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const scripts = useSelector((state) => state.settings)?.scripts || [];
  const { settings, isAdmin } = useSelector((state) => state.session.user);
  const accessDialogRouting = useSelector((state) => state.settings.accessDialogRouting);
  const userPermissions = settings.permissions;
  const hasEditPermission =
    isAdmin || userPermissions.includes(PERMISSIONS.ROUTING.WRITE);

  const handleScriptChange = (scriptIndex) =>
    dispatch(showModal('SelectDialogConditionDialog', scriptIndex));
  const handleScriptAdd = () =>
    dispatch(showModal('CreateRoutingScriptDialog'));
  const handleTargetChange = (scriptIndex, type) =>
    dispatch(showModal('SelectTargetConditionDialog', { scriptIndex, type }));
  const handleVisibilityChange = (scriptIndex) =>
    dispatch(showModal('SelectVisibilityConditionDialog', scriptIndex));
  const changeScriptAction = (scriptIndex, value) => {
    const scriptUuid = scripts[scriptIndex].uuid;

    dispatch(
      changeRoutingScript(
        {
          additional: {
            ...scripts[scriptIndex].additional,
            action: value,
          },
        },
        scriptUuid,
      ),
    );
  };

  return (
    <Body>
      <RouteHeader />
      {accessDialogRouting &&
        <section className="scriptsList">
          {scripts.map((script, scriptIndex) => (
            <AppAccordion
              key={script.uuid}
              isDefaultOpen
              titleJSX={
                <RoutingScriptAccordionTitle
                  script={script}
                  title="Сценарий 1"
                  isDisabled={!hasEditPermission}
                />
              }
            >
              <ScriptBodyList>
                <div className="itemsWrapper">
                  <HorizontalDivider text={t('routingPage.conditions')} />

                  <div className="scriptBodyItem">
                    <div className="label">
                      <MessagesIcon />
                      <p className="text">
                        {t('routingPage.dialogSelectionCondition')}
                      </p>
                    </div>
                    <div className="actions">
                      <AppButton
                        type="secondary"
                        withIcon
                        onClick={() => handleScriptChange(scriptIndex)}
                      >
                        <SettingsIcon />
                        <span>{t('routingPage.change')}</span>
                      </AppButton>
                    </div>
                  </div>

                  <div className="scriptBodyItem">
                    <div className="label">
                      <UsersIcon fill="#18191B" />
                      <p className="text">
                        {t('routingPage.employeeSelectionCondition')}
                      </p>
                    </div>
                    <div className="actions">
                      <AppButton
                        type="secondary"
                        withIcon
                        onClick={() =>
                          handleTargetChange(scriptIndex, 'employees')
                        }
                      >
                        <UserIcon />
                        <span>{t('routingPage.employee')}</span>
                      </AppButton>
                      {/* <AppButton
                        type="secondary"
                        withIcon
                        onClick={() =>
                          handleTargetChange(scriptIndex, 'departments')
                        }
                      >
                        <DepartmentsIcon />
                        <span>{t('routingPage.department')}</span>
                      </AppButton> */}
                    </div>
                  </div>

                  {/* <div className="scriptBodyItem">
                    <div className="label">
                      <EyeIcon />
                      <p className="text">
                        {t('routingPage.visibilitySelectionCondition')}
                      </p>
                    </div>
                    <div className="actions">
                      <AppButton
                        type="secondary"
                        withIcon
                        onClick={() => handleVisibilityChange(scriptIndex)}
                      >
                        <SettingsIcon />
                        <span>{t('routingPage.change')}</span>
                      </AppButton>
                    </div>
                  </div> */}
                </div>

                <div className="itemsWrapper">
                  <HorizontalDivider text={t('routingPage.dialogAction')} />

                  <RoutingActionSelect
                    isDisabled={!hasEditPermission}
                    activeAction={scripts[scriptIndex].additional?.action}
                    onChange={(val) => changeScriptAction(scriptIndex, val)}
                  />
                </div>

                {script.additional?.comment && (
                  <div className="itemsWrapper">
                    <HorizontalDivider text={t('routingPage.comment')} />

                    <p className="comment">{script.additional.comment}</p>
                  </div>
                )}
              </ScriptBodyList>
            </AppAccordion>
          ))}

          {!accessDialogRouting || scripts.length === 0 && (
            <span className="empty-list">{t('routingPage.emptyScriptList')}</span>
          )}
        </section>
      }

      <AppButton
        type="dashed"
        className="dashed addScriptAction"
        disabled={!hasEditPermission || !accessDialogRouting}
        onClick={handleScriptAdd}
      >
        {t('routingPage.addScript')}
      </AppButton>
    </Body>
  );
};

export default SettingsRouting;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
  max-width: 634px;
  margin: 0 auto;

  .scriptsList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%;
  }

  .empty-list {
    padding: 15px 0;
    color: #c1baba;
  }

  .addScriptAction {
    width: 100%;
  }
`;

const ScriptBodyList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 24px;
  padding: 24px;

  .itemsWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;

    .scriptBodyItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      column-gap: 8px;
      row-gap: 4px;
      width: 100%;
      min-height: 48px;
      padding: 4px 4px 4px 12px;
      border-radius: 10px;
      background-color: #ffffff;

      .label {
        display: flex;
        align-items: center;
        column-gap: 8px;

        .text {
          font-size: 14px;
          line-height: 16px;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
      }
    }
  }

  .comment {
    font-size: 12px;
    line-height: 16px;
  }

  .actionWrapper {
    display: grid;
    row-gap: 8px;
    width: 100%;

    .title {
      margin-left: 24px;
      margin-top: 10px;
      font-size: 14px;
    }
  }
`;
