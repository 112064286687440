import { useMemo, useCallback } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem } from '@material-ui/core';
import { setSelectedDialogType } from 'store/actions';


function DialogTypeSelect() {
  const dispatch = useDispatch();
  const selectedDialogType = useSelector((state) => state.inWorkStat.selectedDialogType);
  
  const handleSelect = useCallback((event) => {
    dispatch(setSelectedDialogType(event.target.value));
  }, []);

  const list = useMemo(() => [
    { uuid: "all", name: "Все диалоги" },
    { uuid: "group", name: "Групповые диалоги" },
    { uuid: "private", name: "Персональные диалоги" },
  ], []);

  const renderValue = useMemo(() => {
    if (selectedDialogType) {
      const findedItem = list.find((item) => item.uuid === selectedDialogType);

      if (findedItem) {
        return findedItem.name;
      }
    }
    return "Выберите тип диалога";
  }, [selectedDialogType, list]);
  
  return (
    <Select
      className="select"
      onChange={handleSelect}
      value={selectedDialogType}
      displayEmpty
      renderValue={() => renderValue}
    >
      {list.map(({ uuid, name }) => (
        <MenuItem key={uuid} value={uuid}>
          {name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default DialogTypeSelect;