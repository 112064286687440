import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switcher } from '../../../../../components';

const TargetEmployeeActiveInputs = ({ orCondition, onValueChange }) => {
  const data = Object.values(orCondition)[0];

  useEffect(() => {
    if (JSON.stringify(data) === '{}') {
      onValueChange('value', true)
    }
  }, [])

  return (
    <div style={{margin: 'auto 0'}}>
      <Switcher checked={data.value} onChange={() => onValueChange('value', !data.value)} />
    </div>
  );
};
TargetEmployeeActiveInputs.propTypes = {
  orCondition: PropTypes.object,
  onValueChange: PropTypes.func,
};
TargetEmployeeActiveInputs.defaultProps = {
  orCondition: {},
  onValueChange: null,
};
export default TargetEmployeeActiveInputs;
