import * as React from 'react';
import { Button } from 'components';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Dialog, DialogTitle, Typography } from '@mui/material';
import Template from '../view/Template';
import styled from 'styled-components';

export default function TemplateModal({
  template,
  isEdit,
  isOpen,
  handleSave,
  handleClose,
}) {
  const [error, setError] = React.useState(false);

  const handleSaveAndClose = () => {
    handleSave();
    handleClose();
  };

  return (
    <Modal
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography>{isEdit ? 'Изменить шаблон' : 'Создать шаблон'}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Template isEdit={isEdit} data={template} onChange={() => setError(true)}  />
      </DialogContent>
      <DialogActions>
        <Button variant="text" autoFocus onClick={handleClose} style={{ border: 'none', color: 'rgb(0, 106, 228)' }}>
          Отменить
        </Button>
        <Button 
            variant="contained" 
            autoFocus 
            onClick={handleSaveAndClose} 
            disabled={!error} 
            style={{ backgroundColor: 'rgb(0, 106, 228)' }}
>
          Сохранить
        </Button>
      </DialogActions>
    </Modal>
  );
}

const Modal = styled(Dialog)`
 .MuiPaper-root {
  min-width: 600px;
 }
`;

