import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextareaAutosize, Typography } from '@mui/material';
import { setQualityFeedbackMessagePrefix } from 'store/actions';
import { SettingsCardStyled } from 'views/Settings/components/SettingsNotifications/SettingsNotifications';

function QualityFeedbackMessagePrefix() {
  const dispatch = useDispatch();
  const showQualitySettings = useSelector(
    (state) => state.settings.showQualitySettings,
  );
  const feedbackMessagePrefix = useSelector(
    (state) => state.quality.feedbackMessagePrefix,
  );
  const handelChange = useCallback(
    (event) => {
      dispatch(setQualityFeedbackMessagePrefix(event.currentTarget.value));
    },
    [dispatch],
  );

  return (
    <div>
      <Typography className="card-title">Текст сообщения</Typography>
      <SettingsCardStyled sx={{ marginTop: 2 }} style={{ minHeight: 'auto' }}>
        <TextareaAutosize
          style={{
            width: '100%',
            resize: 'none',
            border: 'none',
            outline: 'none',
          }}
          disabled={!showQualitySettings}
          onChange={handelChange}
          value={feedbackMessagePrefix}
        />
      </SettingsCardStyled>
    </div>
  );
}

export default QualityFeedbackMessagePrefix;
