import update from 'immutability-helper';
import * as actionTypes from '../actions';

export const defaultSchedule = {
  gmt: '',
  monday: {
    times: [],
    state: false,
  },
  tuesday: {
    times: [],
    state: false,
  },
  wednesday: {
    times: [],
    state: false,
  },
  thursday: {
    times: [],
    state: false,
  },
  friday: {
    times: [],
    state: false,
  },
  saturday: {
    times: [],
    state: false,
  },
  sunday: {
    times: [],
    state: false,
  },
}

const initialState = {
  schedule: defaultSchedule,
  isEdited: false,
};

const myScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MY_SCHEDULE:
      return update(state, {
        schedule: { $set: action.payload },
      });
    case actionTypes.ADD_TIME_TO_WEEKDAY:
      const { weekday, time } = action.payload;
      return update(state, {
        schedule: {
          [weekday]: {
            times: { $push: [time] },
          },
        },
      });
    case actionTypes.REMOVE_TIME_FROM_WEEKDAY:
      return update(state, {
        schedule: {
          [action.payload.weekday]: {
            times: { $splice: [[action.payload.index, 1]] },
          },
        },
      });
    case actionTypes.UPDATE_WEEKDAY_STATE:
      return update(state, {
        schedule: {
          [action.payload.weekday]: {
            state: { $set: action.payload.state },
          },
        },
      });
    case actionTypes.CHANGE_ISEDITED_STATUS:
      return update(state, {
        isEdited: { $set: action.payload },
      });
    case actionTypes.CHANGE_GMT:
      return update(state, {
        schedule: {
          gmt: { $set: action.payload },
        },
      });
    default:
      return state;
  }
};

export default myScheduleReducer;
