import { useMemo, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem } from '@material-ui/core';
import { setSelectedCompany } from 'store/actions/statisticsActions';


function CompanySelect() {
  const dispatch = useDispatch();
  const { selectedCompany: globalSelectedCompany, user } = useSelector((state) => state.session);
  const {selectedCompany} = useSelector((state) => state.statistics);
  
  useEffect(() => {
    if (!selectedCompany) {
      dispatch(setSelectedCompany(globalSelectedCompany))
    }
  }, [selectedCompany, globalSelectedCompany]);
  
  const handleSelect = useCallback((event) => {
    dispatch(setSelectedCompany(event.target.value));
  }, []);

  const list = useMemo(() => _.get(user, 'companies', []), []);

  const renderValue = useMemo(() => {
    if (selectedCompany) {
      const findedItem = list.find((item) => item.uuid === selectedCompany);

      if (findedItem) {
        return findedItem.name;
      }
    }
    return "Выберите компанию";
  }, [selectedCompany, list]);
  
  return (
    <Select
      className="select"
      onChange={handleSelect}
      value={selectedCompany}
      displayEmpty
      renderValue={() => renderValue}
    >
      {list.map(({ uuid, name }) => (
        <MenuItem key={uuid} value={uuid}>
          {name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CompanySelect;