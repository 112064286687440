import React from 'react';
import {
  Avatar, Card, CardActions, CardContent, CardHeader, Grid, IconButton, TextField, Typography,
} from '@material-ui/core';
import {
  AttachFile,
  Delete as DeleteIcon,
  Edit as EditIcon,
  KeyboardReturn as KeyboardReturnIcon,
  MoreVert as MoreVertIcon,
  Send as SendIcon,
} from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';


import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useTranslation } from 'react-i18next';
import {postTemplateAttachmentThroughBody, clearPostTemplateAttachment} from 'store/actions';
import { Button, Textarea } from '../../../../../../../../components';
import AttachFileForm from '../AttachFileForm/AttachFileForm';
import CreateTemplateModal  from '../CreateTemplateModal';
import _ from 'lodash';


const TemplateCard = styled(Card)`
  ${({ theme }) => `
    margin: ${theme.spacing(1)}px;
  `}
`;

const File = styled.div`
  padding-bottom: 12px;
  img {
    height: auto;
    width: 150px;
    max-width: 100%;
    border-radius: 3px;
  }
  .file-wrap {
    display: flex;
    justify-content: flex-start;
  }
  .file {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #F4F5F6;
    border-radius: 3px;
    padding: 6px 8px;
    color: #7F7F7F;
  }
`;
const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding: 10px 0;
`;

const TextareaWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SuggestionsList = styled.div`
  position: absolute;
  top: ${({ cursorPosition }) => cursorPosition.top}px;
  left: ${({ cursorPosition }) => cursorPosition.left}px;
  max-height: 150px;
  overflow-y: auto;
  background: white;
  border: 1px solid #f3eded;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
  z-index: 1000;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  p {
    padding: 4px 8px;
    cursor: pointer;
  }

  p:hover {
    background-color: #f0f0f0;
  }
`;

const TemplateMessage = ({
  mainInfo,
  uuid,
  avatar,
  userName,
  template,
  title,
  file,
  date,
  onSendTemplate,
  onForwardTemplate,
  onEdit,
  onDelete,
}) => {

  const { t } = useTranslation();
  const currentRef = React.useRef();
  const dispatch = useDispatch();
  const newFile = useSelector((state) => state.templates.attachedFile);
  const [attachedFile, setAttachedFile] = React.useState(file);
  const [isEdit, setEdit] = React.useState(false);
  const [value, setValue] = React.useState(template);
  const [caption, setCaption] = React.useState(title);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userInStopList = useSelector((state) => state.person.inStopList);
  const [isModalOpen, setModalOpen] = React.useState(false);


  const [suggestions, setSuggestions] = React.useState([]);
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const [cursorPosition, setCursorPosition] = React.useState({ top: 0, left: 0 });

  const mockSuggestions = mainInfo.filter(({ key }) => key === "name" || key === "phone") 
  .map(({ key, val }) => ({
    tag: key.toUpperCase(), 
    value: val,            
  }));


  const handleMouseOrKeyUp = (e) => {
    const cursorIndex = e.target.selectionStart;
    const charWidth = 8;
    const lineHeight = 20; 

    const lineNumber = Math.floor(cursorIndex / e.target.cols);
    const charPositionInLine = cursorIndex % e.target.cols;

    setCursorPosition({
      top: (lineNumber * lineHeight) + 3 ,
      left: (charPositionInLine * charWidth) + 20,
    });
  };

  const handleSuggestionClick = (suggestion) => {
    const words = value.split(' ');
    words.pop(); 
    const newText = [...words, `{{ ${suggestion.tag.toLowerCase()} }}`].join(' ') + ' ';
    setValue(newText); 
    setShowSuggestions(false); 

  };

  const processTemplateText = (text) => {
    return text.replace(/{{\s*(\w+)\s*}}/g, (_, key) => {
      const matchedItem = mainInfo.find((info) => info.key === key);
      return matchedItem ? matchedItem.val : ' '; // Keep the brackets if no match
    });
  };

  const reverseProcessTemplateText = (text) => {
    return mainInfo.reduce((acc, info) => {
      const regex = new RegExp(`\\b${info.val}\\b`, 'g'); // Match the value as a whole word
      return acc.replace(regex, `{{ ${info.key} }}`);
    }, text);
  };


  const handleInputChange = (e) => {
    const text = e.target.value;
    setValue(text);
  
    const lastWord = text.split(' ').pop();

    if (lastWord.startsWith('@')) {
      const query = lastWord.slice(1).toLowerCase();
      const filteredSuggestions = mockSuggestions.filter((item) =>
        item.tag.toLowerCase().includes(query) 
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }

  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if(isModalOpen ) {
      setValue(template)

    } else {
      setValue(reverseProcessTemplateText(template))
    }
  }, [template]);

  React.useEffect(() => {
    setCaption(title);
  }, [title]);

  React.useEffect(() => {
    if (newFile) {
      setAttachedFile(newFile);
    } else if (!attachedFile) {
      setAttachedFile(file);
    }
  }, [newFile]);


  React.useEffect(() => {
    function handleClickOutside(event) {
      if (currentRef.current && !currentRef.current.contains(event.target)) {
        setEdit(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentRef]);

  const handleSend = (file) => {  
    const newValue = processTemplateText(value)
    if (file) {
      onSendTemplate({
        text: newValue,
        file,
      });
    } else {
      onSendTemplate(newValue);
    }   
  };



  const handleEdit = () => {
   
  const payload = {
        title: caption,
        text: value, 
      };
  
    if (attachedFile) {
      payload.file = attachedFile;
    }
  
    onEdit(uuid, payload);
  
    if (isModalOpen) {
      handleSend(attachedFile); 
      setModalOpen(false);
    }
    setEdit(false);
    
  };
  
  
  const closeModal = () => {
    setModalOpen(false);

  }

  const handleOpenModal = () => {
     setModalOpen(true);
  };

  

  const handleInsert = () => {
    if (file) {
      onForwardTemplate({
        text: template,
        file,
      });
    } else {
      onForwardTemplate(template);
    }
  };

  const handleDelete = () => {
    setAnchorEl(false);
    onDelete({
      template,
      uuid,
    });
  };

  const handleAttach = (fileName, data) => {
    dispatch(postTemplateAttachmentThroughBody(fileName, data));
 
  }

  const handleClearAttachment = () => {
    setAttachedFile(null);
  }

  const handleCancel = () => {
    setEdit(false);
    dispatch(clearPostTemplateAttachment());
    setModalOpen(false);
  }

  const handleDestroyFileForm = () => {
    dispatch(clearPostTemplateAttachment());
  }


  

  const formEdit = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
         { !isModalOpen && (<TextField 
            onChange={(e) => setCaption(e.target.value)}
            fullWidth
            margin='dense'
            label={t("templatesTool.title")}
            variant='outlined'
            value={caption}
          />)}
            <TextareaWrapper>
              <Textarea
                onKeyUp={handleMouseOrKeyUp}
                onClick={handleMouseOrKeyUp}
                onChange={(e) => handleInputChange(e)}
                value={isModalOpen ? processTemplateText(value) : value}
                id="template_input"
              />
              {showSuggestions && suggestions.length > 0 && (
                <SuggestionsList cursorPosition={cursorPosition} 
                  className="visible">
                  {suggestions.map((suggestion, index) => (
                    <p  key={index} onClick={() => handleSuggestionClick(suggestion)}>
                          {suggestion.tag.toLowerCase()}
                    </p>
                  ))}
                </SuggestionsList>
              )}
          </TextareaWrapper>
          <AttachFileForm
            onClear={handleClearAttachment}
            attachedFile={attachedFile}
            onClose={handleDestroyFileForm}
            onAttach={handleAttach} />
        </Grid>
        <Grid item xs={12}>
          <div>
            <Button
              type="button"
              className="primary"
              style={{ margin: '10px 10px 10px 0' }}
              disabled={value.length < 1}
              onClick={handleEdit}
            >
             {isModalOpen ? t('templatesTool.send') : t('templatesTool.save')}
            </Button>
            <Button
              type="button"
              className="secondary"
              onClick={handleCancel}
            >
              {t('templatesTool.cancel')}

            </Button>
          </div>

        </Grid>
      </Grid>
    </>
  );

  const templateContent = (
    <Typography variant="body2" color="textSecondary" component="p">
      <Title>{title}</Title>
      {file && 
        <File>
          {file.mimeType.startsWith('image/') ?
            <img alt="file" src={file.url}/> :
            <div className="file-wrap">
              <div className="file">
                <AttachFile/>
                {file.originName}
              </div>
            </div>
          }
        </File>}
      {value}
    </Typography>
  );

  return (
    <TemplateCard ref={currentRef} key={uuid}>
      <CardHeader
        avatar={(
          <Avatar aria-label="recipe">
            {avatar || (userName && userName.length > 0) && userName[0]}
          </Avatar>
        )}
        action={(
          <div>

            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => {
                 setEdit(true);
                 handleClose();
              }}>
                <Tooltip title={t('templatesTool.edit')}>

                  <EditIcon />
                </Tooltip>
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <Tooltip title={t('templatesTool.delete')}>
                  <DeleteIcon />

                </Tooltip>
              </MenuItem>
            </Menu>
          </div>
        )}
        title={userName}
        subheader={moment.unix(date.created)
          .format('LLL')}
      />
      <CardContent>
        {isEdit ? formEdit : templateContent}
      </CardContent>
      {!isEdit && (
        <>
          <CardActions
            disableSpacing
            style={userInStopList ? { cursor: 'not-allowed' } : {}}
          >
            <Tooltip title={t('templatesTool.send')}>
              <span>
                <IconButton
                  aria-label="send"
                  onClick={userInStopList ? null : handleOpenModal}
                  disabled={userInStopList} 
                >
                  <SendIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('templatesTool.insert')}>
              <span>
                <IconButton
                  aria-label="use"
                  onClick={userInStopList ? null : handleInsert}
                  disabled={userInStopList} 
                >
                  <KeyboardReturnIcon />
                </IconButton>
              </span>
            </Tooltip>
          </CardActions>
        </>
      )}
       <CreateTemplateModal
        isOpen={isModalOpen}
        onClose={closeModal}
        attachedFile={attachedFile}
        value={value}
        caption={caption}
      >
        {formEdit} 
      </CreateTemplateModal>


    </TemplateCard>
  );
};
TemplateMessage.propTypes = {
  mainInfo: PropTypes.object,
  uuid: PropTypes.string,
  avatar: PropTypes.object,
  template: PropTypes.string,
  title: PropTypes.string,
  file: PropTypes.string,
  date: PropTypes.shape({ created: PropTypes.number }),
  onSendTemplate: PropTypes.func,
  onForwardTemplate: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  userName: PropTypes.string,
};
TemplateMessage.defaultProps = {
  mainInfo: {},
  uuid: 'new_template',
  avatar: undefined,
  template: '',
  title: '',
  file: null,
  date: { created: 0 },
  onSendTemplate: () => ({}),
  onForwardTemplate: () => ({}),
  onEdit: () => ({}),
  onDelete: () => ({}),
  userName: 'default User',
};
export default TemplateMessage;
