import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { getDepartmentUserName, stringToColor } from "views/Stat/utils";
import { FlexDiv } from "views/Stat/components/FlexDiv";
import moment from "moment";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
);

function PauseCountStatByDateRange({ employees }) {
	const {
		pauseCountStat,
	} = useSelector((state) => state.statistics);

	const { totalPauseCount, data } = useMemo(() => {
		const datasets = [];
		let totalPauseCount = 0;
	
		pauseCountStat.datasets.forEach((dataset) => {
			const foundEmployee = employees.find((e) => e.uuid === dataset.label);
	
			if (foundEmployee) {
				const userName = getDepartmentUserName(foundEmployee);
	
				datasets.push({
					...dataset,
					borderWidth: 1,
					fill: false,
					backgroundColor: stringToColor(userName),
					borderColor: stringToColor(userName),
					label: userName,
				});
	
				// Считаем общее количество пауз
				totalPauseCount += dataset.data.reduce((sum, value) => sum + value, 0);
			}
		});
	
		const formattedLabels = pauseCountStat.labels.map(date => moment(date, "YYYY-MM-DD").format("DD.MM.YYYY"));

		return {
			totalPauseCount,
			data: {
				labels: formattedLabels,
				datasets,
			},
		};
	}, [pauseCountStat, employees]);

	const options = {
		scales: {
			y: {
				ticks: {
					stepSize: 1,
					beginAtZero: true,
				},
				min: 0,
			},
		},
	};

	return (
		<div>
			<div className="lineChart-wrapper" style={{ minHeight: 'auto' }}>
				<h5 className="graph-title">Количество пауз по дням</h5>
				<FlexDiv
					style={{ width: 'calc(50%-5px)' }}
					direction="column"
					rGap="2px"
					margin="0 0 24px 0"
				>
					<span className="statistic-quantity">
						{totalPauseCount}
					</span>
					<span className="statistic-desc">
						Общее количество пауз за данный период
					</span>
				</FlexDiv>
			</div>
			<div
				style={{
					display: 'flex',
					border: '1px solid rgba(0, 0, 0, 0.16)',
					borderRadius: '4px',
					padding: '12px',
				}}
			>
				<Line data={data} options={options} />
			</div>
		</div>
	);
}

PauseCountStatByDateRange.propTypes = {
	employees: PropTypes.array.isRequired,
};


export default PauseCountStatByDateRange;