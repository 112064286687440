import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Emojione } from 'react-emoji-render';
import 'video-react/dist/video-react.css';
import { Player } from 'video-react';
import { Modal, Box, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

const StyledPlayer = styled(Player)`
  max-width: 100% !important;
  display: block;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 5px;
  ${({ isPost }) =>
    isPost
      ? css`
          padding-top: 100% !important;
          z-index: 100;
        `
      : css`
          padding-top: 56.25%;
        `}

  .video-react-big-play-button {
    left: 0 !important;
    top: 0 !important;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 1.5em !important;
    border-radius: 50% !important;
  }
`;

const VideoContent = ({ src, caption, size, poster, isPost }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <Typography color="inherit" variant="body1" onClick={handleOpenModal}>
        {src && (
          <StyledPlayer
            isPost={isPost}
            playsInline
            poster={poster}
            src={src}
            style={{ cursor: 'pointer' }}
          />
        )}
        {size && <p>{`${Math.trunc(size / 10485.76) / 100} Mb`}</p>}
        {caption && <Emojione size={64} svg text={caption} />}
      </Typography>

      {/* Fullscreen Video Modal */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 1300,
            position: 'relative',
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              color: '#fff',
            }}
          >
            <CloseIcon />
          </IconButton>
          <video
            src={src}
            controls
            autoPlay
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              outline: 'none',
              borderRadius: '4px',
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

VideoContent.propTypes = {
  src: PropTypes.string,
  caption: PropTypes.string,
  size: PropTypes.number,
  poster: PropTypes.string,
  isPost: PropTypes.bool,
};

VideoContent.defaultProps = {
  src: '',
  caption: '',
  poster: null,
  size: 0,
  isPost: false,
};

export default VideoContent;
