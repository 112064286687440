import React, { useMemo } from 'react';
import styled from 'styled-components';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PERMISSIONS, SETTINGS_NAV_LINKS } from '../../index';
import AppAccordion from '../../../../components/Accordion/AppAccordion';
import AppIconButton from '../../../../components/AppIconButton/AppIconButton';
import { ReactComponent as ArrowIcon } from '../../../../assets/img/arrow-rounded.svg';
import { ReactComponent as SecurityIcon } from '../../../../assets/img/security.svg';
import { ReactComponent as NotificationsIcon } from '../../../../assets/img/notifications.svg';
import { ReactComponent as ScheduleIcon } from '../../../../assets/img/schedule.svg';
import { ReactComponent as RoutingIcon } from '../../../../assets/img/routing.svg';
import { ReactComponent as AccessSettingsIcon } from '../../../../assets/img/accessSetting.svg';
import { ReactComponent as DialogsSettingsIcon } from '../../../../assets/img/messages.svg';
import { FeedbackOutlined } from '@material-ui/icons';

const CurrentIcon = (iconName) => {
  if (iconName === 'security') return <SecurityIcon />;
  if (iconName === 'notifications') return <NotificationsIcon />;
  if (iconName === 'schedule') return <ScheduleIcon />;
  if (iconName === 'routing') return <RoutingIcon />;
  if (iconName === 'accessSettings') return <AccessSettingsIcon />;
  if (iconName === 'dialogs') return <DialogsSettingsIcon />;
  if (iconName === 'qualitySettings')
    return <FeedbackOutlined style={{ fontSize: 16 }} />;

  return <></>;
};

CurrentIcon.protoTypes = {
  iconName: PropTypes.string.isRequired,
};

export default function SettingsNavBar() {
  const { t } = useTranslation();

  const {
    isAdmin: isAdminValue,
    group: userRole,
    settings,
  } = useSelector((state) => state.session.user);
  const userPermissions = settings.permissions;
  const hasRoutingPermission = userPermissions.includes(
    PERMISSIONS.ROUTING.READ || PERMISSIONS.ROUTING.WRITE,
  );
  // TODO: временное решение до тех пор пока нет контента для 3 и 4 кнопок
  const handleClick = (event, isDisabled) => {
    if (isDisabled) {
      event.preventDefault();
    }
  };

  const isAdmin = useMemo(() => isAdminValue === 'true', [isAdminValue]);

  const AVAILABLE_SETTINGS_NAV_LINKS = useMemo(() => {
    if (isAdmin) {
      return SETTINGS_NAV_LINKS;
    }
    const groups = [];

    SETTINGS_NAV_LINKS.forEach((group) => {
      const items = group.links.filter((item) =>
        item.availableFor.includes(userRole),
      );

      if (items.length > 0) {
        groups.push({
          ...group,
          links: items,
        });
      }
    });

    return groups;
  }, [isAdmin, userRole]);

  return (
    <Wrapper>
      <div className="title">
        <RouterLink to="/chat">
          <AppIconButton>
            <ArrowIcon />
          </AppIconButton>
        </RouterLink>
        <h2 className="titleText">{t('settingsDrawer.title')}</h2>
      </div>
      <div className="links">
        {AVAILABLE_SETTINGS_NAV_LINKS.map((group) => (
          <AppAccordion
            key={group.title}
            title={t(`settingsDrawer.${group.title}`)}
            isDefaultOpen
            className="dense-accordion"
          >
            <AccordionBody>
              {group.links.map((link) => (
                <NavLink
                  key={link.name}
                  to={link.path}
                  className={`link${link.isDisabled ? ' disabled' : ''}`}
                  activeClassName="active"
                  exact
                  onClick={(event) => handleClick(event, link.isDisabled)}
                >
                  {CurrentIcon(link.iconName)}
                  {t(`settingsDrawer.${link.name}`)}
                </NavLink>
              ))}
            </AccordionBody>
          </AppAccordion>
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  max-width: 280px;
  overflow-x: auto;
  background-color: #f6f8fc;
  border-right: 2px solid #eaeaea;

  .title {
    display: flex;
    align-items: center;
    max-height: 98px;
    height: 100%;
    padding: 0 8px;
    border-bottom: 1px solid #dadce0;

    .text {
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
    }
  }

  .links {
    padding: 16px;

    .link {
      display: flex;
      align-items: center;
      column-gap: 8px;
      cursor: pointer;
      background-color: transparent;
      color: #222f44;
      font-size: 14px;
      width: -webkit-fill-available;
      padding: 8px;
      transition: background-color 0.3s ease;
      border-radius: 4px;
      margin: 2px 0 2px 16px;

      &:not(.active):not(.disabled):hover {
        background-color: rgb(231, 233, 239);
      }

      svg path {
        fill: #18191b;
      }
    }

    .active {
      background: rgba(0, 106, 228, 0.05);
      color: rgba(0, 106, 228, 1);

      svg path {
        fill: rgba(0, 106, 228, 1);
      }
    }
    .disabled {
      cursor: default;
      color: #c1baba;

      svg path {
        fill: #c1baba;
      }
    }
  }

  .dense-accordion {
    background-color: transparent;

    .accordion__button {
      padding: 4px 0 8px 0;
    }

    .accordion__button-title {
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      color: #18191b;
    }

    svg path {
      fill: #18191b;
    }
  }
`;

const AccordionBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
`;
