import { Autocomplete, TextField } from '@mui/material';
import { useTimeZoneSelectStyles } from './TimeZoneSelect';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

function EmployeeSelect({ value, list, onSelect }) {
  const classes = useTimeZoneSelectStyles();

  const handleSelect = useCallback((_, newValue) => {
    onSelect(newValue);
  }, [onSelect])

  return (
    <Autocomplete
      disablePortal
      className={classes.autocomplete}
      value={value}
      options={list}
      renderInput={(params) => <TextField {...params} />}
      onChange={handleSelect}
      isOptionEqualToValue={(option, selected) =>
        option.value === selected.value
      }
    />
  );
}

EmployeeSelect.propTypes = {
  value: PropTypes.string,
  list: PropTypes.array,
  onSelect: PropTypes.func,
};


export default EmployeeSelect;