import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  TextField,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
} from '@mui/icons-material';

function QualityFeedbackOption({ disabled, value, text, onChange, onRemove }) {
  const [isEditing, setIsEditing] = useState(false);
  const [newText, setNewText] = useState(text);

  const handleBeginEditing = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleCompleteEditing = useCallback(() => {
    setIsEditing(false);
    onChange(value, newText);
  }, [newText, onChange, value]);

  const handleChange = useCallback((event) => {
    setNewText(event.currentTarget.value);
  }, []);

  const handleRemove = useCallback(
    (event) => {
      const value = event.currentTarget.dataset.value;
      onRemove(Number(value) - 1);
    },
    [onRemove],
  );

  return (
    <ListItem
      key={value}
      secondaryAction={
        <Box gap={1.5} display={'flex'}>
          {isEditing ? (
            <IconButton
              onClick={handleCompleteEditing}
              edge="end"
              aria-label="complete"
              disabled={disabled}
              sx={{ color: 'green' }}
            >
              <CheckIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleBeginEditing}
              edge="end"
              aria-label="edit"
              disabled={disabled}
              sx={{ color: 'gray' }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            data-value={value}
            onClick={handleRemove}
            edge="end"
            disabled={disabled}
            aria-label="delete"
            sx={{ color: 'red' }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      }
      disablePadding
    >
      <ListItemIcon>{value}</ListItemIcon>
      <ListItemText
        id={`checkbox-list-label-${value}`}
        primary={
          <div>
            {isEditing ? (
              <TextField
                sx={{ width: 180 }}
                size={'small'}
                value={newText}
                onChange={handleChange}
              />
            ) : (
              <p>{text}</p>
            )}
          </div>
        }
      />
    </ListItem>
  );
}

QualityFeedbackOption.propTypes = {
  disabled: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default QualityFeedbackOption;
