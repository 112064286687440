import { createReq } from '../../../components/tamplate/api';
import { HOST } from '../../../config';


export const FETCH_PROVIDERS_PENDING = 'FETCH_PROVIDERS_PENDING';
export const FETCH_PROVIDERS_SUCCESS = 'FETCH_PROVIDERS_SUCCESS';
export const FETCH_PROVIDERS_FAILURE = 'FETCH_PROVIDERS_FAILURE';
export const SET_PROVIDERS = 'SET_PROVIDERS';


export const fetchProviders = (companyId) => async (dispatch, getState) => {
  dispatch({ type: FETCH_PROVIDERS_PENDING });
  const tokens = getState().session.tokenData; 
  try {
    const url = `${HOST}/template/server/${companyId}/providers`;
    const response = await createReq({ method: 'get', url }, tokens);

    dispatch({
      type: FETCH_PROVIDERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_PROVIDERS_FAILURE,
      payload: error.message,
    });
  }
};


export const setProviders = (providers) => ({
  type: SET_PROVIDERS,
  payload: providers,
});
