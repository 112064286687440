import { useState } from 'react';
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";



const CustomDataGrid = styled(DataGrid)({
  "& .MuiDataGrid-viewport": {
    overflow: "unset !important",
  },
});

const useStyles = makeStyles({
  root: {
    border: "1px solid #D4D4D4", 
    color: "black", 
  },
  cell: {
    color: "black !important", 
  },
  columnHeader: {
    backgroundColor: "#F5F5F5", 
    color: "black !important", 
    borderBottom: "2px solid  #D4D4D4 !important"
  },
});

export default function Table({
  rows,
  columns,
  rowCount,
  onPageChange, 
  onPageSizeChange, 
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10); 
  const handlePageChange = (newPage) => {
    if (newPage >= 0) {
      setPage(newPage);
      if (onPageChange) onPageChange(newPage + 1); 
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    if (onPageSizeChange) {
      onPageSizeChange(newPageSize); 
    }
  };

  const classes = useStyles();
  return (
   
    <CustomDataGrid
      paginationMode="server"
      rowCount={rowCount}
      rows={rows}
      columns={columns}
      page={page}
      pageSize={pageSize}
      onPageChange={handlePageChange} 
      onPageSizeChange={handlePageSizeChange} 
      classes={{
        root: classes.root,
        cell: classes.cell,
        columnHeader: classes.columnHeader,
      }}
    />
  )
}
