import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MuiRootDialog from '../../MuiRootDialog/MuiRootDialog';
import { changeRoutingScript, hideModal } from '../../../../store/actions';
import DialogAndGroupConditions from './components/DialogAndGroupConditions';
import { PERMISSIONS } from '../../../../views/Settings';

const SelectDialogConditionDialog = ({ scriptIndex }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleModalHide = () => dispatch(hideModal());

  const { settings, isAdmin } = useSelector((state) => state.session.user);
  const userPermissions = settings.permissions;
  const hasEditPermission =
    isAdmin || userPermissions.includes(PERMISSIONS.ROUTING.WRITE);

  const { conditions } = useSelector(
    (state) => state.settings.scripts[scriptIndex],
  );
  const { dialogs: groupConditions } = conditions;
  const [andConditions, setAndConditions] = useState([...groupConditions]);

  const { uuid: scriptUuid } = useSelector(
    (state) => state.settings.scripts[scriptIndex],
  );

  const changeScript = () => {
    const dialogs = structuredClone(andConditions).map((subArr) =>
      subArr.map((obj) => {
        const newObj = { ...obj };

        if ('mainOption' in newObj) {
          delete newObj.mainOption;
        }

        if ('opened' in newObj) {
          newObj.unopened = newObj.opened;
          delete newObj.opened;
          newObj.unopened.value = false;
        }

        if ('UNinWork' in newObj) {
          newObj.inWork = newObj.UNinWork;
          delete newObj.UNinWork;
          newObj.inWork.value = false;
        }

        if ('UNclosed' in newObj) {
          newObj.closed = newObj.UNclosed;
          delete newObj.UNclosed;
          newObj.closed.value = false;
        }

        return newObj;
      }),
    );

    dispatch(
      changeRoutingScript(
        {
          conditions: {
            ...conditions,
            dialogs,
          },
        },
        scriptUuid,
      ),
    );

    handleModalHide();
  };

  return (
    <MuiRootDialog
      PaperProps={{
        style: {
          maxWidth: 984,
          width: '100%',
          backgroundColor: '#F5F5F6',
        },
      }}
      title={t('routingPage.dialogSelectionCondition')}
      declineAction={handleModalHide}
      isConfirmDisabled={!hasEditPermission}
      confirmAction={changeScript}
      handleClose={handleModalHide}
    >
      <DialogAndGroupConditions
        andConditions={andConditions}
        setAndConditions={setAndConditions}
      />
    </MuiRootDialog>
  );
};

SelectDialogConditionDialog.propTypes = {
  scriptIndex: PropTypes.number.isRequired,
};

export default SelectDialogConditionDialog;
