import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'; 

const Timer = ({ lastInMessage }) => {
  const [timeLeft, setTimeLeft] = useState(24 * 60 * 60); 

  useEffect(() => {
    const calculateInitialTimeLeft = () => {
      if (!lastInMessage || !lastInMessage.date || !lastInMessage.date.created) {
        return 24 * 60 * 60; 
      }

      const now = moment(); // Current time.
      const lastMessageTime = moment(lastInMessage.date.created);
      const timePassedInSeconds = now.diff(lastMessageTime, 'seconds');

      return Math.max(24 * 60 * 60 - timePassedInSeconds, 0); 
    };

    const initialTimeLeft = calculateInitialTimeLeft();
    setTimeLeft(initialTimeLeft);

    if (initialTimeLeft > 0) {
      const interval = setInterval(() => {
        setTimeLeft((prevTime) => {
          const updatedTime = Math.max(prevTime - 1, 0);
          if (updatedTime === 0) {
            clearInterval(interval); 
          }
          return updatedTime;
        });
      }, 1000);

      return () => clearInterval(interval); 
    }
  }, [lastInMessage]);

  // Format time as HH:MM:SS
  const formatTime = (seconds) => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const secs = String(seconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${secs}`;
  };

  return (
    <div
      className="timer"
      style={{
        color: 'red', 
        fontSize: '14px', 
        fontWeight: 'bold',
        padding: '5px' 
      }}
    >
      {formatTime(timeLeft)}
    </div>
  );
};

Timer.propTypes = {
  lastInMessage: PropTypes.shape({
    date: PropTypes.shape({
      created: PropTypes.string.isRequired, 
    }),
  }).isRequired,
};


export default Timer;
