
import {
    FETCH_TEMPLATES_REQUEST,
    FETCH_TEMPLATES_SUCCESS,
    FETCH_TEMPLATES_FAILURE,
    UPDATE_TEMPLATE_REQUEST,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
    CREATE_TEMPLATE_REQUEST,
    CREATE_TEMPLATE_SUCCESS,
    CREATE_TEMPLATE_FAILURE,
    UPLOAD_TEMPLATE_SUCCESS,
    UPLOAD_TEMPLATE_FAILURE,
    RESET_ERROR,
    SET_TEMPLATE,
    SYNC_DATA_FAILURE
  } from '../../actions/templatesActions/templatesActions';
  
  const initialState = {
    templates: [],
    loading: false,
    error: null,
    template: {},
    handleId: null

  };
  
  const templateReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TEMPLATES_REQUEST:
      case UPDATE_TEMPLATE_REQUEST:
      case CREATE_TEMPLATE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case FETCH_TEMPLATES_SUCCESS:
        return {
          ...state,
          loading: false,
          templates: action.payload,
        };
      case UPLOAD_TEMPLATE_SUCCESS:
        return {
          ...state,
          loading: false,
          handleId: action.payload,
        };
        case FETCH_TEMPLATES_SUCCESS:
        return {
          ...state,
          loading: false,
          templates: action.payload,
        };
      case UPDATE_TEMPLATE_SUCCESS:
      case CREATE_TEMPLATE_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
        };
  
      case FETCH_TEMPLATES_FAILURE:
      case UPDATE_TEMPLATE_FAILURE:
      case CREATE_TEMPLATE_FAILURE:
      case UPLOAD_TEMPLATE_FAILURE:
      case SYNC_DATA_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
  
      case SET_TEMPLATE:
        return {
          ...state,
          template: {
              ...state.template, 
              ...action.payload,  
          },
      };

      case RESET_ERROR:
      return {
        ...state,
        error: null,
      };
      default:
        return state;
    }
  };
  
  export default templateReducer;
  