import styled from 'styled-components';

export default function PageWrapper({
  title,
  children,
  actions,
}) {
  return (
    <Parent>
      <div className='header'>
        <div className='title'>
          {title}
        </div>
        <div className='actions'>
          {actions}
        </div>
      </div>
      <div className='page'>
        {children}
      </div>
    </Parent>
  );
}

const Parent = styled.div`
  height: calc(100% - 5rem);
  padding: 2.5rem 4rem;
  display: flex;
  flex-direction: column;
  .page {
    display: flex;
    height: calc(100% - 5rem);
    width: 100%;
  }
  .title {
    color: #000;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 600;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2.5rem;
  }
`;