import React from 'react';
import { Route } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCompanyProviders,
  getCompanySetting,
  setCompanyShowQuality,
} from 'store/actions';

import OperatorsPage from 'views/Stat/OperatorsPage';
import ProvidersPage from 'views/Stat/ProvidersPage';
import TagsPage from 'views/Stat/TagsPage';
import WorkTimePage from 'views/Stat/WorkTimePage';
import InWorkStatPage from 'views/Stat/InWorkStatPage';
import SettingsPage from '../views/Settings';
import DasboardLayout from '../layouts/Dashboard';
import ChatPage from '../views/Chat/Chat';
import TaskPage from '../views/Task';
import StatPage from '../views/Stat';

const UnauthorizedRouter = () => {
  const initSelectedCompany = useSelector(
    (state) => state.session.selectedCompany,
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (initSelectedCompany) {
      dispatch(fetchCompanyProviders({ uuidCompany: initSelectedCompany }));
      dispatch(
        getCompanySetting({
          uuidCompany: initSelectedCompany,
          setting: 'showQualitySettings',
        }),
      ).then((value) => {
        dispatch(setCompanyShowQuality(value === 'true'));
      });
    }
  }, [dispatch, initSelectedCompany]);
  return (
    <DasboardLayout>
      <>
        <Route path="/chat" component={ChatPage} />
        <Route path="/tasks" component={TaskPage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/stat/general" component={StatPage} />
        <Route path="/stat/operators" component={OperatorsPage} />
        <Route path="/stat/providers" component={ProvidersPage} />
        <Route path="/stat/tags" component={TagsPage} />
        <Route path="/stat/worktime" component={WorkTimePage} />
        <Route path="/stat/inwork" component={InWorkStatPage} />
      </>
    </DasboardLayout>
  );
};

export default UnauthorizedRouter;
