import {
  UPDATE_STATISTICS,
  STATISTICS_LOADING_SET,
  STATISTICS_LOADING_UNSET,
  STATISTICS_SUBSCRIBED,
} from 'store/actions';
import {
  STATISTICS_SET_CURRENT_DIALOG_TAGS,
  STATISTICS_SET_SELECTED_COMPANY,
  STATISTICS_SET_TAGS,
  STATISTICS_SET_SELECTED_PROVIDER,
  STATISTICS_SET_SELECTED_DEPARTMENT,
  STATISTICS_SET_SELECTED_EMPLOYEES,
  SET_WORK_TOTAL_STAT,
  SET_WORK_AVERAGE_STAT,
  SET_PAUSE_COUNT_STAT,
} from 'store/actions/statisticsActions';

const initialState = {
  statistics: {},
  isLoading: false,
  isSubscribed: false,
  dialogsCount: 0,
  tags: {total: 0, rows: []},
  selectedCompany: null,
  selectedProviders: null,
  selectedEmployees: [],
  selectedDepartment: { assignee: null, type: 'all' },
  currentDialogTags: [],
  workTotalStat: {
    labels: [],
    datasets: []
  },
  workAverageStat: {
    labels: [],
    datasets: []
  },
  pauseCountStat: {
    labels: [],
    datasets: []
  },
};

const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATISTICS_SET_CURRENT_DIALOG_TAGS:
      return { ...state, currentDialogTags: action.payload };
    case STATISTICS_SET_SELECTED_COMPANY:
      return { ...state, selectedCompany: action.payload };
    case STATISTICS_SET_SELECTED_EMPLOYEES:
      return { ...state, selectedEmployees: action.payload };
    case STATISTICS_SET_SELECTED_PROVIDER:
      return { ...state, selectedProviders: action.payload };
    case STATISTICS_SET_SELECTED_DEPARTMENT:
      return { ...state, selectedDepartment: action.payload };
    case UPDATE_STATISTICS:
      return { ...state, statistics: action.payload };
    case STATISTICS_SET_TAGS:
      return { ...state, tags: action.payload };
    case STATISTICS_LOADING_SET:
      return { ...state, isLoading: true, dialogsCount: action.payload };
    case STATISTICS_LOADING_UNSET:
      return { ...state, isLoading: false, dialogsCount: 0 };
    case STATISTICS_SUBSCRIBED:
      return { ...state, isSubscribed: true };
    case SET_WORK_TOTAL_STAT:
      return { ...state, workTotalStat: action.payload };
    case SET_WORK_AVERAGE_STAT:
      return { ...state, workAverageStat: action.payload };
    case SET_PAUSE_COUNT_STAT:
      return { ...state, pauseCountStat: action.payload };
    default:
      return state;
  }
};

export default statisticsReducer;
