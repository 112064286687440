import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { statCalendarDate, statCalendarOpen } from 'store/actions';

import { ReactComponent as CalendarSvg } from '../../../assets/img/calendar.svg';
import CalendarModal, { dateTransform } from '../components/CalendarModal';
import { FlexDiv } from '../components/FlexDiv';


const StyledStatFilters = styled.div`
  position: relative;
`;

const FilterItem = styled.button`
  display: flex;
  column-gap: 7px;
  background: #f3f4f5;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  padding: 4px 4px 4px 10px;
  span {
    padding: 5px 8px 5px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #383838;
    background: #eaebec;
    border-radius: 6px;
  }
`;

const CalendarSelect = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    calendarDate,
    calendarIsOpen
  } = useSelector((state) => state.statCalendar);

  const handleOpen = useCallback(() => {
    dispatch(statCalendarOpen());
  }, [dispatch]);

  useEffect(() => {
    if (!calendarDate.from && !calendarDate.to) {
      dispatch(statCalendarDate({
        from: new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() || 7) + 1)),
        to: new Date(),
      }));
    }
  }, [calendarDate, dispatch]);

  const text = useMemo(() => {
    if (!calendarDate.from && !calendarDate.to) {
      return t('profileFilters.filter1');
    }
    return `${dateTransform(calendarDate.from)} - ${dateTransform(calendarDate.to)}`
  }, [calendarDate])

  return (
    <StyledStatFilters>
      <FlexDiv wrap="wrap" cGap="18px" rGap="18px">
        <FilterItem
          onClick={handleOpen}
        >
          <CalendarSvg />
          <span>
            {text}
          </span>
        </FilterItem>
      </FlexDiv>
      {calendarIsOpen && <CalendarModal />}
    </StyledStatFilters>
  );
};

export default CalendarSelect;
