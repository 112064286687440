import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box } from '@mui/material';

const CreateTemplateModal = ({ isOpen, onClose, attachedFile, value, caption, handleInputChange, children, ...props }) => {

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="edit-template-modal"
      aria-describedby="edit-template-form"
      {...props} 
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          width: '100%',
          backgroundColor: '#fff',
          padding: 3,
          borderRadius: 2,
          boxShadow: 4,
          zIndex: 1301, 
        }}
      >
        <div>
          {children}
        </div>
      </Box>
    </Modal>
  );
};

CreateTemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired, 
  attachedFile:  PropTypes.instanceOf(File),
  children: PropTypes.node, 
  value: PropTypes.string,
  caption: PropTypes.string,
  handleInputChange: PropTypes.func

};

export default CreateTemplateModal;
