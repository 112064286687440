import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography } from '@material-ui/core';
import ModalWrapper, { ActionButton } from './layout';
import { useTimeTracking } from 'hooks';

const Wrapper = styled.div`
  .line {
    margin-top: 25px;
    label {
      display: block;      
      margin-bottom: 10px;
      color: #7d8392;
    }
  }
  .modal-buttons {
    display: flex;
    margin-top: 25px;
  }
  button {
    flex: 1;
  }
  .font {
    font-weight: 700;
  }
`;

const StartTrackerDialog = ({ onClose }) => {
  const { start } = useTimeTracking();

  const handleConfirm = () => {
    start();
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <ModalWrapper
      modalStyle={{
        width: '380px',
        paddingRight: '30px'
      }}
      noButtonBg
      onClose={handleClose}
      title='Вы уверены что хотите начать рабочий день?'>
      <Wrapper>
        <div className='line'>
          <Typography variant='subtitle1'>
            Желаем продуктивного дня!
          </Typography>
        </div>
        <div className="modal-buttons">
          <ActionButton
            className='font'
            bgColor='#5ACC00'
            onClick={handleConfirm}
            type="button"
            disabled={false}>
              Начать рабочий день
          </ActionButton>
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

StartTrackerDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default StartTrackerDialog;