import PropTypes from 'prop-types';
import { Pause } from '@material-ui/icons';
import { useTimeTracking } from 'hooks';
import { useTrackingTimeStyles } from '../hooks';

function PauseWorkingTime() {
  const classes = useTrackingTimeStyles();
  const { displayTime, pause } = useTimeTracking();

  return (
    <div
      className={`${classes.container} ${classes.pauseHover}`}
      onClick={pause}
    >
      <div style={{ fontSize: '10px' }}>
        <div>{"Пауза"}</div>
      </div>
      <span className={`${classes.height} ${classes.pauseButton}`}>
        <Pause className={classes.yellow} />
      </span>
      <div className={classes.time}>{displayTime}</div>
    </div>
  );
}

export default PauseWorkingTime;

PauseWorkingTime.propTypes = {
  time: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
