import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from 'store/actions';

import { useTrackingTimeStyles } from '../hooks';
import { Stop } from '@material-ui/icons';

function StopWorkingTime() {
  const classes = useTrackingTimeStyles();
  const dispatch = useDispatch();

  const handlePause = useCallback(() => {
    dispatch(showModal('FinishTimeTracker'));
  }, [dispatch]);

  return (
    <div
      className={`${classes.container} ${classes.endHover}`}
      onClick={handlePause}
    >
      <span className={`${classes.height} ${classes.endButton}`}>
        <Stop className={classes.red} />
      </span>
    </div>
  );
}

export default StopWorkingTime;
