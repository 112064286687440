


import { useState } from 'react';
import { FormControl, 
       InputLabel, MenuItem, TextField, Select, 
       Button, Stack, Typography, Tooltip, IconButton, 
       Alert, Snackbar }  from '@mui/material';     
import { Add, HelpOutline } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setTemplate, sendUploadedFile } from '../../../store/actions/templatesActions/templatesActions';
import FileUpload from "../sharedComponents/FileUpload"; 
import CustomSelect from "../sharedComponents/Select";
import { CATEGORY, LANGUAGE, TYPE, TYPES, BUTTONS } from "../constants/templateConstants";



const selectStyles = {
  '& .MuiInputBase-input': {
    font: 'inherit',
    letterSpacing: 'inherit',
    color: 'currentColor',
    border: 0,
    background: 'none',
    height: '1.4375em',
    margin: 0,
    padding: '16.5px 14px',
  }
};


export default function Template({ isEdit, data, onChange }) {
  const dispatch = useDispatch();
  const [newData, setNewData] = useState( isEdit ? data : {});
  const { elementName, category, languageCode, data: propText, templateType,  buttons: propsButtons, example: propExample } = newData;
  const [example, setExample] = useState(propExample || "");
  const [text, setText] = useState(propText || "");


  const [buttonsType, setButtonsType] = useState(
    propsButtons?.length ? propsButtons[0].type : Object.keys(BUTTONS)[0]
  );

  const [buttons, setButtons] = useState( propsButtons ||  []);
  const {
      handleId,
      error,
    } = useSelector(state => state?.template || {});

  const updateButtonType = (index, type) => {
    const newButtons = [...buttons];
    newButtons[index].type = type;
    setButtons(newButtons);
  };

  const updateType = (key) => {
    if (!key) return; 
    const updatedTemplate = {
      ...newData,  
      templateType: key, 
    };
    setNewData(updatedTemplate)
    dispatch(setTemplate({ template: updatedTemplate }));
  };
  
  const updateButtonText = (index, value) => {
    const updatedButtons = [...buttons];
    updatedButtons[index].text = value;
    setButtons(updatedButtons); 
  };
  const updateButtonAction = (index, field, value) => {
    const newButtons = [...buttons];
    newButtons[index][field] = value;
    setButtons(newButtons);
    const updatedTemplate = {
            ...newData, 
            buttons: newButtons,
          };
    dispatch(setTemplate({ template: updatedTemplate }));

  };
  
  const addButton = () => {
    const updatedButtons = [...buttons, { text: "Новая кнопка", type: buttonsType }];
    setButtons(updatedButtons); 
    const updatedTemplate = {
      ...newData,  
      buttons: updatedButtons, 
    };
    dispatch(setTemplate({ template: updatedTemplate }));
  };
  
  const updateTemplate = (event, key) => {
    const value = event.target.value;
    if (onChange) {
      onChange(); 
    }
    let cleanedValue
   if (key === "content") {
      cleanedValue = value.replace(/{{(.*?)}}/g, "$1"); 
      setText(value); 
      setExample(cleanedValue ? cleanedValue : example);
    }

    if (key === "buttons") {
      let cleanedValue = text.replace(/{{(.*?)}}/g, "$1")
      setButtonsType(value);
      const updatedTemplate = {
        ...newData,
        buttons: buttons,
        vertical: "Internal_vertical",
        example: cleanedValue 
      };
      setNewData(updatedTemplate);
      dispatch(setTemplate({template: updatedTemplate}));
    } else {
      const updatedTemplate = {
        ...newData,
        [key]: value,
        vertical: "Internal_vertical",
        example: cleanedValue
      };
      if (templateType === "DOCUMENT" || templateType === "IMAGE" || templateType === "VIDEO"  ) {
        updatedTemplate.exampleMedia = handleId;
      }
      setNewData(updatedTemplate);
      dispatch(setTemplate({template: updatedTemplate}));
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    let uuidProvider = location.pathname.replace("/", "")
    dispatch(sendUploadedFile({uuidProvider, file}))
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1.5}>
        <TextField
        sx={selectStyles}
          disabled={isEdit}
          fullWidth
          value={elementName}
          onChange={(e) => updateTemplate(e, "elementName")}
          label="Имя шаблона"
          variant="outlined"
        />
        <CustomSelect
            label="Категория"
            value={category}
            onChange={(e) => updateTemplate(e, "category")}
            options={CATEGORY}
        />

       <CustomSelect
          label="Язык"
          value={newData.languageCode}
          onChange={(e) => updateTemplate(e, "languageCode")}
          options={LANGUAGE}
        />
      </Stack>
      <Stack spacing={1.5}>
      <Stack spacing={0.3}>
        <Typography variant="subtitle1" fontWeight="bold">
          Заголовок
        </Typography>
        <Typography variant="body2">
          Добавьте или выберите тип файла шаблона
        </Typography>
      </Stack>

        <FormControl fullWidth>
        <InputLabel id="selector-type-label">Выберите тип</InputLabel>
        <Select
        sx={selectStyles}
          value={templateType}
          onChange={(e) => {
            updateType(e.target.value);
          }}
          labelId="selector-type-label"
        >
         {Object.keys(TYPE).map((key) => (
              <MenuItem value={key} key={key} 
               style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '8px 16px',
              }}>
                {TYPE[key]}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {(templateType === "MEDIA" || templateType === "IMAGE" || templateType === "VIDEO" || templateType === "DOCUMENT") && (
        <FileUpload
          accept={
            templateType === "IMAGE"
              ? "image/png, image/jpeg, image/jpg"
              : templateType === "VIDEO"
              ? "video/avi, video/mp4, video/mov"
              : templateType === "DOCUMENT"
              ? ".docx, .xls, .doc, .pdf"
              : "*"
          }
          onFileUpload={handleFileUpload}
          selectedType={newData.templateType}
          types={TYPES}
          updateType={updateType}
        />
        )}

      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography>Пишите только на русском</Typography>
          <Tooltip title="Введите текст только на русском языке">
            <IconButton size="small">
              <HelpOutline fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack spacing={1}>
          <Typography>Введите текст сообщения на языке, который вы выбрали</Typography>
          <TextField placeholder="Введите текст" onChange={(e) => updateTemplate(e, "content")} fullWidth value={text} multiline />
       </Stack>
       <Stack spacing={1}>
          <TextField   fullWidth  value={example} disabled multiline/>
        </Stack>
      </Stack>  
  </Stack>
     
  <Stack spacing={1.5}>
    <Typography>
      <strong>Кнопки</strong>
    </Typography>

{buttons.map((button, index) => (
  <Stack key={index} spacing={1.5}>
    <FormControl fullWidth>
      <InputLabel>Тип кнопок</InputLabel>
      <Select
        sx={selectStyles}
        value={button.type || "NONE"}
        onChange={(e) => updateButtonType(index, e.target.value)}
      >
        {["QUICK_REPLY", "PHONE_NUMBER", "URL"].map((key) => (
          <MenuItem
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "8px 16px",
            }}
            key={key}
            value={key}
          >
            {BUTTONS[key]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    {button.type === "QUICK_REPLY" && (
      <TextField
        fullWidth
        value={button.text}
        onChange={(e) => updateButtonText(index, e.target.value)}
        label="Текст кнопки"
        variant="outlined"
      />
    )}

    {(button.type === "PHONE_NUMBER" || button.type === "URL") && (
      <>
        <TextField
          fullWidth
          value={button.text}
          onChange={(e) => updateButtonText(index, e.target.value)}
          label="Текст кнопки"
          variant="outlined"
        />
        <TextField
          fullWidth
          value={button.type === "PHONE_NUMBER" ? button.phone_number || "" : button.url || ""}
          onChange={(e) =>
            updateButtonAction(index, button.type === "PHONE_NUMBER" ? "phone_number" : "url", e.target.value)
          }
          label={button.type === "PHONE_NUMBER" ? "Номер телефона" : "URL"}
          variant="outlined"
        />
        </>
      )}
      </Stack>
    ))}
      <Button onClick={addButton} disabled={buttons.length === 3} variant="outlined" startIcon={<Add />}>
        Добавить
      </Button>
    </Stack>

    <Snackbar onClose={() => dispatch(resetError())} open={error} autoHideDuration={1000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
      <Alert severity="error">
        Не удалось выполнить запрос
      </Alert>
    </Snackbar>   

  </Stack>
  );
};