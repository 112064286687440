// @ts-nocheck
import { Card, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FormApi } from 'final-form';
import styled from 'styled-components';
import { Box, Stack } from '@mui/material';
import Divider from 'components/Divider';
import AppButton from 'components/AppButton/AppButton';
import { ReactComponent as WarningIcon } from 'assets/img/password_change_info.svg';
import { useEffect, useRef, useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateUserEmail,
  updateUserPassword,
} from 'store/actions/sessionActions';

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 4px;
  color: #7d8392;
  .required {
    color: red;
    font-size: 12px;
    font-weight: 400;
    margin-left: 4px;
  }
`;
const Wrapper = styled.form`
  font-size: 15px;
  .form-input {
    margin-top: 25px;
  }
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    font-size: 16px;
    height: 40px;
    box-sizing: border-box;
    border-radious: 16px;
  }
  .PrivateNotchedOutline-legendNotched-7 {
    max-width: 0;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #bdbdbd;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
    border-color: #bdbdbd;
    background: rgb(244, 246, 248);
  }
`;
const validateEmail = (email: string | undefined): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return email ? re.test(email) : false;
};
const validateRequired = (value: string | undefined | null): boolean => {
  return !!value;
};
const initialValues = { password: '', confirmPassword: '' };
interface IFormValues {
  password: string;
  confirmPassword: string;
}

const SettingsPassword = () => {
  const session = useSelector((state: any) => state.session);
  const dispatch = useDispatch();

  const { t: translate } = useTranslation();
  const emailRef = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleUpdateEmail = () => {
    const email = emailRef.current?.value;
    if (validateRequired(email) && validateEmail(email)) {
      dispatch(updateUserEmail({ email }));
      setError(null);
    } else {
      if (!validateRequired(email)) {
        setError('Email is required.');
      } else {
        setError('Please enter a valid email address.');
      }
    }
  };

  const onSubmit = (
    values: IFormValues,
    form: FormApi<IFormValues, Partial<IFormValues>>,
  ) => {
    dispatch(
      updateUserPassword({
        password: values.password,
        confirm: values.confirmPassword,
      }),
    );
    form.reset(initialValues);
  };
  const handleValidate = (values: IFormValues) => {
    const errors: Partial<IFormValues> = {};

    if (!values.password) {
      errors.password = translate('security.passwordRequired');
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = translate('security.confirmPasswordRequiered');
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = translate('security.passwordMatch');
    }

    return errors;
  };

  useEffect(() => {
    if (session && emailRef.current) {
      emailRef.current.value = session?.user.email || '';
    }
  }, [session]);

  return (
    <Card
      style={{
        padding: 24,
        maxWidth: 640,
        margin: '0 auto',
        borderRadius: '16px',
      }}
    >
      {/* <Divider title={translate('security.personalInfo')} />

      <StyledLabel htmlFor="email">
        {translate('security.email')}
      </StyledLabel> */}
      {/* <Stack
        sx={{
          flexDirection: { sm: 'column', md: 'row' },
          position: 'relative',
        }}
        gap={2}
        mb={4}
      >
        <StyledTextField
          style={{ flex: 1 }}
          id="message"
          variant="outlined"
          placeholder=""
          inputRef={emailRef}
        />
        {error && (
          <p
            style={{
              color: 'red',
              position: 'absolute',
              left: 0,
              bottom: '-20px',
              fontSize: '11px',
            }}
          >
            {error}
          </p>
        )}
        <AppButton type="secondary" onClick={handleUpdateEmail}>
          {translate('security.updateEmailBtn')}
        </AppButton>
      </Stack> */}
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      <Form<IFormValues>
        validate={handleValidate}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ form, handleSubmit, invalid }) => (
          <Wrapper onSubmit={handleSubmit}>
            {/* <Divider title={translate('security.updatePasswordTitle')} /> */}
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 4 }}>
              <div>{translate('security.updatePasswordTitle')}</div>
              <div style={{ color: "gray" }}>{session?.user.email}</div>
            </div>
            <div className="form-input">
              <StyledLabel htmlFor="password">
                {translate('security.password')}
                <span className="required">*</span>
              </StyledLabel>
        
              <Field<string> name="password">
                {({ input, meta }) => (
                  <StyledTextField
                    error={
                      meta.touched && meta.dirty && meta.error !== undefined
                    }
                    helperText={meta.touched && meta.dirty && meta.error}
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    {...input}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Field>
            </div>
            <div className="form-input">
              <StyledLabel htmlFor="confirmPassword">
                {translate('security.confirmPassword')}
                <span className="required">*</span>
              </StyledLabel>
           
              <Field<string> name="confirmPassword">
                {({ input, meta }) => (
                  <StyledTextField
                    error={
                      meta.touched && meta.dirty && meta.error !== undefined
                    }
                    helperText={meta.touched && meta.dirty && meta.error}
                    type={showPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    {...input}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Field>
            </div>

            <Stack
              gap={2}
              justifyContent="flex-end"
              className="action_button_wrapper"
              mt={4}
              sx={{
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: { xs: 'flex-end', md: 'center' },
              }}
            >
              {/*<Box*/}
              {/*  style={{*/}
              {/*    display: 'flex',*/}
              {/*    gap: '4px',*/}
              {/*    alignItems: 'center',*/}
              {/*    color: 'red',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <WarningIcon width={16} height={16} stroke="red" />*/}
              {/*  {translate('security.textLastUpdate')}*/}
              {/*</Box>*/}
              <Box
                sx={{
                  width: { xs: '100%', md: 'auto' },
                  gap: { xs: 2, md: 1 },
                  display: 'flex',
                  '&>button': {
                    flex: { xs: 1, md: 0 },
                  },
                }}
              >
                <AppButton
                  type="secondary"
                  onClick={() => form.reset(initialValues)}
                >
                  {translate('common.cancel')}
                </AppButton>
                <AppButton
                  type="common"
                  onClick={() => {}}
                  disabled={invalid}
                  buttonType="submit"
                >
                  {translate('common.edit')}
                </AppButton>
              </Box>
            </Stack>
          </Wrapper>
        )}
      />
    </Card>
  );
};

export default SettingsPassword;
