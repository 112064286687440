import { makeStyles } from "@material-ui/core";

const useTrackingTimeStyles = makeStyles(() => ({
  container: {
    margiRight: '18px',
    padding: '4px 9px',
    height: '40px',
    borderRadius: '8px',
    color: '#263238',
    fontSize: '13px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#F3F4F5',
    cursor: 'pointer',
    border: '1px solid #fff',
  },
  height: {
    height: '24px',
  },
  startButton: {
    border: 0,
  },
  pauseButton: {
    border: 0,
  },
  continueButton: {
    border: 0,
  },
  endButton: {
    border: 0,
  },
  startHover: {
    '&:hover': {
      border: '1px solid #5ACC00',
      color: '#5ACC00',
    },
  },
  pauseHover: {
    '&:hover': {
      border: '1px solid #FFD029',
      color: '#FFD029',
    },
  },
  unpauseHover: {
    color: '#fff',
    backgroundColor: '#FFD029',
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #FFD029',
      color: '#FFD029',
    },
  },
  endHover: {
    '&:hover': {
      border: '1px solid #FF5F5F',
    },
  },
  green: {
    color: '#5ACC00',
  },
  red: {
    color: '#FF5F5F',
  },
  yellow: {
    color: '#FFD029',
  },
  time: {
  }
}));

export default useTrackingTimeStyles;