import _ from 'lodash';
import { createSelector } from 'reselect';
import { combineSelectors } from 'utils/combineSelectors';

export const selectCurrentDialog = (state) => state.session.currentDialog;
export const selectDialogList = (state) => state.chat.dialogs;

export const selectChatFilter = (state) => state.settings.customFilter;

// export const selectChatFilter = (state) => {
//   const dialogView = new URLSearchParams(window.location.search).get('dialogView');
//   return dialogView ? {} : state.settings.customFilter;
// };



export const assigneeFilter = (state) => state.settings.assigneeFilter;

const CHAT_FILTERS = {
  unreadFilter: (x, filters) =>
    filters.unread ? _.get(x, 'additional.countUnread') > 0 : true,
  noAnswerForClient: (x, filters) =>
    filters.noAnswerForClient
      ? _.get(x, 'additional.lastMessageFrom') === 'client'
      : true,
  noAnswerForSupport: (x, filters) =>
    filters.noAnswerForSupport
      ? _.get(x, 'additional.lastMessageFrom') === 'support'
      : true,
  closed: (x) => x.status === 'closed',
  isMailing: (x, filters) =>
    filters.isMailing
      ? _.get(x, 'additional.isMailing') === true
      : true,
  myDepartment: (x) => {
    let assigness = assigneeFilter || [];
    if (_.isString(assigneeFilter)) {
      assigness = [assigneeFilter];
    }
    return assigness.includes(x?.assignee?.uuid) || true;
  },
};
export const selectDialogsMemorized = combineSelectors(
  [selectDialogList, selectChatFilter],
  (dialogs, filters) => {
    const filteredDialogs = dialogs.filter((x) => {
      return (
        CHAT_FILTERS.unreadFilter(x, filters) &&
        CHAT_FILTERS.noAnswerForClient(x, filters) &&
        CHAT_FILTERS.noAnswerForSupport(x, filters) &&
        CHAT_FILTERS.isMailing(x, filters)
      );
    });
    return filteredDialogs;
  }
);


export const checkFilter = (dialog) => 
  CHAT_FILTERS.unreadFilter(dialog, selectChatFilter) &&
  CHAT_FILTERS.noAnswerForClient(dialog, selectChatFilter) &&
  CHAT_FILTERS.noAnswerForSupport(dialog, selectChatFilter) &&
  CHAT_FILTERS.closed(dialog, selectChatFilter) &&
  CHAT_FILTERS.myDepartment(dialog, selectChatFilter);

/**
 * Selector: get current dialog present in loaded dialogs
 * @
 */
 export const selectCurrentDialogInLoadedDialogs = combineSelectors(
  [selectCurrentDialog, selectDialogList],
  (uuidDialog, dialogs) => dialogs.find((x) => x.uuid === uuidDialog),
);

/**
 * Selector: get selected dialog | memorized
 * @param {string| null} uuidDialog  dialog uuid
 */
export const selectCurrentDialogMemorized = createSelector(
  [selectDialogList, selectCurrentDialog],
  (dialogs, dialogUuid) => dialogs.find((x) => x.uuid === dialogUuid),
);
