
import _ from "lodash";
import styled from 'styled-components';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { StatNav } from './components';
import { GraphWrap, Wrap } from './style';
import { WorkTotalStatByDateRange, PauseCountStatByDateRange, WorkTimeFilters } from './charts';
import {
  getWorkTotalStatByDateRange,
  getPauseCountStatByDateRange
} from "store/actions/statisticsActions";

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
}));

export default function WorkTimePage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const calendarDate = useSelector((state) => state.statCalendar.calendarDate);
  const {
    departments,
  } = useSelector((state) => state.session);
  const {
    selectedCompany,
    selectedEmployees,
  } = useSelector((state) => state.statistics);

  const getStartOfDay = useCallback((date) => {
    if (!date) return 0;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
  }, []);
  
  const dateRange = useMemo(() => {
    try {
      const { from, to } = calendarDate;
  
      return {
        from: from.getTime(),
        to: to.getTime(),
      };
    } catch {
      return null;
    }
  }, [calendarDate, getStartOfDay]);

  const companyEmployees = useMemo(() => {
    if (departments && selectedCompany in departments) {
      return _.flatMap(departments[selectedCompany], 'users');
    }
    return [];
  }, [departments, selectedCompany]);

  useEffect(() => {
    if (selectedCompany && dateRange) {
      setLoading(true);

      const payload = {
        uuidCompany: selectedCompany,
        employees: selectedEmployees,
        ...dateRange,
      }

      dispatch(getWorkTotalStatByDateRange(payload));
      dispatch(getPauseCountStatByDateRange(payload));

      setLoading(false);
    }
  }, [dateRange, selectedCompany, selectedEmployees]);

  return (
    <Page
      className={clsx({ [classes.root]: true })}
      style={{ flexDirection: 'column' }}
      title="IntellectDialog | Stat"
    >
      <StatNav />
      <Wrap>
        <WorkTimeFilters />
        <StyledStat>
          <WorkTotalStatByDateRange employees={companyEmployees} />
          <GraphWrap>
            <PauseCountStatByDateRange employees={companyEmployees} />
          </GraphWrap>
        </StyledStat>
      </Wrap>
    </Page>
  );
}

export const StyledStat = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 100px;
  padding-bottom: 60px;

  & > * {
    flex: 1 1 calc(50% - 25px); /* По 2 элемента в строке с отступами */
    max-width: calc(50% - 25px);
  }

  @media (max-width: 1055px) {
    & > * {
      flex: 1 1 100%; /* На узких экранах по 1 элементу */
      max-width: 100%;
    }
  }
`;