import { apiPost } from 'api';
import { HOST } from 'config';

// filters
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_SELECTED_EMPLOYEES = 'SET_SELECTED_EMPLOYEES';
export const SET_SELECTED_PROVIDER = 'SET_SELECTED_PROVIDER';
export const SET_SELECTED_DIALOG_TYPE = 'SET_SELECTED_DIALOG_TYPE';
// stat
export const SET_COMPLETE_STAT = 'SET_COMPLETE_STAT';
export const SET_IN_WORK_STAT = 'SET_IN_WORK_STAT';

export const setSelectedDialogType = (payload) => ({
  type: SET_SELECTED_DIALOG_TYPE,
  payload,
});

export const setInWortState = (payload, type) => ({
  type,
  payload,
});

export const getInWorkTotalByDateRange =
  ({ uuidCompany, from, to, employees = [], dialogType = "all" }) =>
    (dispatch, getState) =>
      apiPost({
        dispatch,
        url: `${HOST}/chat/history/interaction/getInWorkTotalByDateRange`,
        tokens: getState().session.tokenData,
        body: {
          uuidCompany,
          from,
          to,
          employees,
          dialogType,
        },
        callback: (data) => {
          if (data) {
            dispatch(setInWortState(data, SET_IN_WORK_STAT));
          }
        },
      });

export const getCompleteTotalByDateRange =
  ({ uuidCompany, from, to, employees = [], dialogType = "all" }) =>
    (dispatch, getState) =>
      apiPost({
        dispatch,
        url: `${HOST}/chat/history/interaction/getCompleteTotalByDateRange`,
        tokens: getState().session.tokenData,
        body: {
          uuidCompany,
          from,
          to,
          employees,
          dialogType,
        },
        callback: (data) => {
          if (data) {
            dispatch(setInWortState(data, SET_COMPLETE_STAT));
          }
        },
      });