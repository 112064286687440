import { useCallback, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import AppIconButton from 'components/AppIconButton/AppIconButton';
import AppButton from 'components/AppButton/AppButton';
import AppSwitcher from 'components/AppSwitcher/AppSwitcher';
import AddClockDialog from './../../SettingsSchedule/components/AddClockDialog';
import { ReactComponent as CloseIcon } from '../../../../../assets/img/time-close.svg';
import { ReactComponent as TimeClock } from '../../../../../assets/img/time-clock.svg';
import { ReactComponent as TimePlus } from '../../../../../assets/img/time-plus.svg';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const pack = (num) => (num < 10 ? `0${num}` : num);
const toStruct = (minute) => {
  const hours = (minute - (minute % 60)) / 60;
  const minutes = minute % 60;
  return { hours, minutes };
};

const WeekdayRow = ({ weekday, times, status, onSelect, onSelectTimes, onDeleteTimes }) => {
  const { t: translate } = useTranslation();
  const [clockModal, setClockModal] = useState(false);

  const addInterval = useCallback((newInterval) => {
    onSelectTimes(weekday, newInterval);
    setClockModal(false);
  }, [onSelectTimes, weekday]);

  const deleteInterval = useCallback((index) => {
    onDeleteTimes(weekday, index);
  }, [onDeleteTimes, weekday]);

  const handleSelect = useCallback(() => {
    onSelect(weekday, !status)
    //dispatch(changeIsEditedStatus(true));
  }, [onSelect, status, weekday])

  return (
    <>
      <Container>
        <AppSwitcher
          onChange={handleSelect}
          isOn={status}
          size=""
        />
        <Typography>{translate(`weekdays.${weekday}`)}</Typography>
        <ClocksWrapper isDisabled={status}>
          <TimeClock />
          {times.map((el, index) => {
            const from = toStruct(el.from);
            const to = toStruct(el.to);
            return (
              <TimeWrapper key={index}>
                {pack(from.hours)}:{pack(from.minutes)}
                {' - '}
                {pack(to.hours)}:{pack(to.minutes)}
                <AppIconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteInterval(index);
                  }}
                  style={{ padding: 0 }}
                >
                  <CloseIcon />
                </AppIconButton>
              </TimeWrapper>
            );
          })}
          <AppButton
            type="dashed"
            onClick={() => setClockModal(true)}
            size="small"
            style={{
              height: '24px',
              padding: '4px 8px 4px 4px',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '102px',
              color: '#18191B',
              borderColor: '#DADCE0',
              borderRadius: '6px',
            }}
          >
            <TimePlus />
            {translate('common.addButton')}
          </AppButton>
        </ClocksWrapper>
      </Container>

      {/* Adding Dialog  */}
      {clockModal && (
        <AddClockDialog
          open={clockModal}
          handleClose={() => setClockModal(false)}
          addInterval={addInterval}
          lastSelectedTime={[...times].pop()}
        />
      )}
    </>
  );
};

export default WeekdayRow;

WeekdayRow.propTypes = {
  timezone: PropTypes.string,
  onSelect: PropTypes.func,
};

const Container = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'auto 105px 1fr',
  alignItems: 'center',
  gap: '8px',
});

const ClocksWrapper = styled(Box)(({ isDisabled }) => ({
  boxSizing: 'border-box',
  border: '1px solid rgb(218, 220, 224)',
  borderRadius: 8,
  padding: 8,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: 8,
  maxWidth: '426px',
  width: '100%',
  marginLeft: 'auto',
  opacity: !isDisabled ? 0.4 : 1,
  pointerEvents: !isDisabled ? 'none' : 'auto',
  background: !isDisabled ? 'rgb(244, 246, 248)' : '',
}));

const TimeWrapper = styled(Box)({
  borderRadius: 6,
  background: '#F4F6F8',
  fontSize: '14px',
  fontWeight: 400,
  boxSizing: 'border-box',
  padding: '4px 4px 4px 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 4,
  height: '24px',
  width: '100%',
  maxWidth: '127px',
});

WeekdayRow.propTypes = {
  weekday: PropTypes.string,
  times: PropTypes.array,
  status: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectTimes: PropTypes.func,
  onDeleteTimes: PropTypes.func,
};
