import update from 'immutability-helper';

import {
  QUALITY_SET_FEEDBACK_MESSAGE_PREFIX,
  QUALITY_SET_FEEDBACK_OPTIONS,
} from '../actions/qualityActions';

const QUALITY_FEEDBACK_OPTIONS = [
  'Очень плохо',
  'Плохо',
  'Нормально',
  'Хорошо',
  'Отлично',
];

const QUALITY_DEFAULT_FEEDBACK_MESSAGE_PREFIX = `Пожалуйста оцените качество обслуживания`;

const initialState = {
  feedbackMessagePrefix: QUALITY_DEFAULT_FEEDBACK_MESSAGE_PREFIX,
  feedbackOptions: QUALITY_FEEDBACK_OPTIONS,
  feedbackIsShow: false,
};

const qualityReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUALITY_SET_FEEDBACK_MESSAGE_PREFIX:
      return update(state, {
        feedbackMessagePrefix: { $set: action.payload },
      });

    case QUALITY_SET_FEEDBACK_OPTIONS:
      return update(state, {
        feedbackOptions: { $set: action.payload },
      });

    default:
      return state;
  }
};

export default qualityReducer;
