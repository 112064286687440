import {
  STAT_CALENDAR_CLOSE,
  STAT_CALENDAR_OPEN,
  STAT_CALENDAR_DATE,
  STAT_INTERVAL_KEY,
} from '../actions/statCalendarActions';

const STAT_CALENDAR_INTERVAL = {
  today: () => ({
    from: new Date(),
    to: new Date()
  }),
  yesterday: () => ({
    from: new Date(Date.now() - 86400000),
    to: new Date(Date.now() - 86400000)
  }),
  ["7day"]: () => ({
    from: new Date(Date.now() - 604800000),
    to: new Date()
  }),
  lastMonth1: () => ({
    from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    to: new Date()
  }),
  thisMonth: () => ({
    from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  }),
  lastMonth2: () => ({
    from: new Date(
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
    ),
    to: new Date(new Date().getFullYear(), new Date().getMonth(), 0)
  })
};


const initialState = {
  intervalKey: null,
  calendarIsOpen: false,
  calendarDate: {
    from: null,
    to: null,
  },
};

const statCalendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case STAT_INTERVAL_KEY:
      return {
        ...state,
        intervalKey: action.payload,
      }
    case STAT_CALENDAR_OPEN:
      return {
        ...state,
        calendarIsOpen: true,
      };

    case STAT_CALENDAR_CLOSE:
      return {
        ...state,
        calendarIsOpen: false,
      };

    case STAT_CALENDAR_DATE:
      return {
        ...state,
        calendarDate: action.payload,
      };

    default:
      return state;
  }
};

export {
  STAT_CALENDAR_INTERVAL,
}
export default statCalendarReducer;
