export function getDateRange(calendarDateRange) {
  function transformDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }
  return {
    from: Array.isArray(calendarDateRange.dateBefore)
      ? transformDate(calendarDateRange.dateBefore[0])
      : transformDate(calendarDateRange.dateBefore),
    to: transformDate(calendarDateRange.dateAfter),
  };
}

export function getDepartmentUserName(user) {
  const { name, surname, lastname } = user;
  if (!name && !surname && !lastname) return 'noname';
  return `${name} ${surname} ${lastname}`;
};

export function stringToColor(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Генерируем оттенок (hue) в диапазоне 0-360 градусов
  const hue = Math.abs(hash) % 360;
  const saturation = 70; // Насыщенность (чем выше, тем ярче цвета)
  const lightness = 50; // Яркость (50% - сбалансированное значение)

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};