export const STAT_CALENDAR_CLOSE = 'STAT_CALENDAR_CLOSE';
export const STAT_CALENDAR_OPEN = 'STAT_CALENDAR_OPEN';
export const STAT_CALENDAR_DATE = 'STAT_CALENDAR_DATE';
export const STAT_INTERVAL_KEY = 'STAT_INTERVAL_KEY';

export const statIntervalKey = (key) => ({
  type: STAT_INTERVAL_KEY,
  payload: key
});

export const statCalendarClose = () => ({
  type: STAT_CALENDAR_CLOSE,
});

export const statCalendarOpen = () => ({
  type: STAT_CALENDAR_OPEN,
});

export const statCalendarDate = ({ from, to }) => ({
  type: STAT_CALENDAR_DATE,
  payload: {
    from,
    to,
  },
});
