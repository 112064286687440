import styled from 'styled-components';

export const StyledStat = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1055px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  grid-column-gap: 50px;
  grid-row-gap: 32px;
  margin-bottom: 100px;
  padding-bottom: 60px;
`;

export const GraphWrap = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1515px) {
    .lineChart-wrapper {
      min-height: 189px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  h5.graph-title {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 25px;
    color: #202020;
  }
  .statistic-quantity {
    font-weight: 400;
    font-size: 28px;
    line-height: 39px;
    color: #454545;
  }

  .statistic-desc {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #7f7f7f;
  }

  .statistic-question-mark {
    min-width: 12px;
    height: 12px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    margin-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .statistic-question-mark:before {
    content: '\\003F';
    font-size: 8px;
  }
  .statistic-question {
    top: 140%;
    color: #fff;
    width: 200px;
    text-align: center;
    padding: 7px 15px;
    position: absolute;
    display: none;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.80);
  }
  .statistic-question2 {
    left: 7%;
  }
  .statistic-question:after {
    content: '';
    position: absolute;
    bottom: calc(100%);
    left: calc(50% - 5.5px);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 9px solid rgba(0,0,0,0.5);
}
  }
  .statistic-question-mark:hover .statistic-question {
    display: block;
  }

  .graph-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #454545;
    margin-bottom: 12px;
  }

  .lineChart-wrapper {
  }
`;

export const Wrap = styled.div`
  width: 100%;
  padding: 32px 40px 0 40px;
  background-color: #fff;
  .title {
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 34px;
    line-height: 47px;
  }
  .section {
    padding-bottom: 32px;
  }
`;