import { ReactComponent as PictureImage } from '../images/picture.svg';
import { ReactComponent as PlayImage } from '../images/play.svg';
import { ReactComponent as DocumentImage } from '../images/document.svg';

export const CATEGORY = {
    AUTHENTICATION: "Аутентификация",
    MARKETING: "Рассылка",
    UTILITY: "Служебный",
  };
  
  export const LANGUAGE = {
    ru: "Русский",
    en: "Английский",
  };
  
  export const TYPE = {
    NONE: "None",
    MEDIA: "Медиа",
    TEXT: "Текст",
  };
  
  export const BUTTONS = {
    NONE: "Без кнопок",
    QUICK_REPLY: "Быстрые ответы",
    PHONE_NUMBER: "Звонок",
    URL: "Посетите website",
  };
  
  export const TYPES = [
    { key: "IMAGE", text: "Изображение", icon: <PictureImage /> },
    { key: "VIDEO", text: "Видео", icon: <PlayImage /> },
    { key: "DOCUMENT", text: "Документ", icon: <DocumentImage /> },
  ];


  