
import { Box, Stack, Typography } from "@mui/material";



const Upload = ({ accept, onFileUpload, selectedType, types, updateType }) => {
  return (
    <Stack justifyContent="center" direction="row" spacing={1.5}>
      {types.map(({ key, text, icon }) => (
        <label key={key}>
          <input type="file" accept={accept} style={{ display: "none" }} onChange={onFileUpload} />
          <Box
            sx={{
              cursor: "pointer",
              padding: "8px",
              borderRadius: "8px",
              border: key === selectedType ? "2px solid #007BFF" : "2px solid transparent",
              backgroundColor: key === selectedType ? "#E3F2FD" : "transparent",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => updateType(key)}
          >
            {icon}
            <Typography variant="subtitle2">{text}</Typography>
          </Box>
        </label>
      ))}
    </Stack>
  );
};

export default Upload;
