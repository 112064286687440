import { SHOW_MODAL, CLOSE_MODAL } from '../../actions/templatesActions/modalTemplateActions';

// Initial State
const initData = {
  show: false,
  title: '',
  content: '',
};

const initialState = {
  data: { ...initData },
};

// Reducer
const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        data: {
          title: action.payload.title,
          content: action.payload.content,
          show: true,
        },
      };

    case CLOSE_MODAL:
      return { ...state, data: { ...initData } };

    default:
      return state;
  }
};

export default modalReducer;
