import StartWorkingTime from './StartWorkingTime';
import PauseWorkingTime from './PauseWorkingTime';
import ResumeWorkingTime from './ResumeWorkingTime';
import StopWorkingTime from './StopWorkingTime';
import { useTimeTracking } from 'hooks';

const TrackingTimeItem = () => {
  const {state} = useTimeTracking();
  
  if (!state || state?.type === 'stop') {
    return <StartWorkingTime />;
  }

  if (state.type === 'pause') {
    return (
      <>
        <ResumeWorkingTime />
        <StopWorkingTime />
      </>
    );
  }

  return (
    <>
      <PauseWorkingTime />
      <StopWorkingTime />
    </>
  );
};

export default TrackingTimeItem;
