import { apiGet, apiPost } from 'api';
import { HOST } from 'config';
import { wsSndMessage } from './wsActions';
import { updateStatistics } from './chatActions';

export const STATISTICS_SET_TAGS = 'STATISTICS_SET_TAGS';
export const STATISTICS_SET_CURRENT_DIALOG_TAGS = 'STATISTICS_SET_CURRENT_DIALOG_TAGS';
// Filters
export const STATISTICS_SET_SELECTED_EMPLOYEES = 'STATISTICS_SET_SELECTED_EMPLOYEES';
export const STATISTICS_SET_SELECTED_COMPANY = 'STATISTICS_SET_SELECTED_COMPANY';
export const STATISTICS_SET_SELECTED_PROVIDER = 'STATISTICS_SET_SELECTED_PROVIDER';
export const STATISTICS_SET_SELECTED_DEPARTMENT = 'STATISTICS_SET_SELECTED_DEPARTMENT';
export const SET_WORK_TOTAL_STAT = 'SET_WORK_TOTAL_STAT';
export const SET_WORK_AVERAGE_STAT = 'SET_WORK_AVERAGE_STAT';
export const SET_PAUSE_COUNT_STAT = 'SET_PAUSE_COUNT_STAT';

export const setSelectedProviders = (payload) => ({
  type: STATISTICS_SET_SELECTED_PROVIDER,
  payload,
});

export const setSelectedDepartment = (payload) => ({
  type: STATISTICS_SET_SELECTED_DEPARTMENT,
  payload,
});

export const setCurrentDialogTags = (payload) => ({
  type: STATISTICS_SET_CURRENT_DIALOG_TAGS,
  payload,
});

export const setTags = (payload) => ({
  type: STATISTICS_SET_TAGS,
  payload,
});

export const setSelectedCompany = (payload) => ({
  type: STATISTICS_SET_SELECTED_COMPANY,
  payload,
});

export const setSelectedEmployees = (payload) => ({
  type: STATISTICS_SET_SELECTED_EMPLOYEES,
  payload,
});

export const setWorkTotalStat = (payload) => ({
  type: SET_WORK_TOTAL_STAT,
  payload,
});

export const setWorkAverageStat = (payload) => ({
  type: SET_WORK_AVERAGE_STAT,
  payload,
});

export const setPauseCountStat = (payload) => ({
  type: SET_PAUSE_COUNT_STAT,
  payload,
});

export const getWorkTotalStatByDateRange =
  ({
    uuidCompany,
    from,
    to,
    employees,
  }) => (dispatch, getState) =>
    apiPost({
      dispatch,
      url: `${HOST}/user/worktime/getWorkTotalStatByDateRange`,
      tokens: getState().session.tokenData,
      body: {
        uuidCompany,
        from,
        to,
        employees,
      },
      callback: (res) => {
        if (res) {
          dispatch(setWorkTotalStat(res));
        }
      },
  });

export const getPauseCountStatByDateRange =
  ({
    uuidCompany,
    from,
    to,
    employees,
  }) => (dispatch, getState) =>
    apiPost({
      dispatch,
      url: `${HOST}/user/worktime/getPauseCountStatByDateRange`,
      tokens: getState().session.tokenData,
      body: {
        uuidCompany,
        from,
        to,
        employees,
      },
      callback: (res) => {
        if (res) {
          dispatch(setPauseCountStat(res));
        }
      },
  });

export const getStatistics =
  ({ uuidCompany }) =>
  (dispatch) =>
    apiGet({
      dispatch,
      url: `${HOST}/chat/history/${uuidCompany}/fakestat`,
      callback: (res) => {
        dispatch(updateStatistics(res));
      },
    });

export const getStat = (payload) => (dispatch) => {
  const { uuidCompany, dateRange, uuidConnection, uuidAssigns } = payload;
  dispatch(
    wsSndMessage({
      action: 'chat.stat.get',
      data: { dateRange, uuidCompany, uuidConnection, uuidAssigns },
    }),
  );
};

export const fetchTagsStat = ({ uuidCompany, data }) => (dispatch, getState) =>
    apiPost({
      dispatch,
      url: `${HOST}/chat/history/stat/tags/${uuidCompany}`,
      tokens: getState().session.tokenData,
      body: data,
      callback: (tags) => {
        dispatch(setTags(tags));
      },
  });