import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Snackbar, Slide, Alert } from '@mui/material';
import uuidGen from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import {
  QualityFeedbackMessage,
  QualityFeedbackMessagePrefix,
  QualityFeedbackOptions,
} from './components';
import {
  createQualitySettings,
  getQualitySettings,
  setQualityFeedbackMessagePrefix,
  setQualityFeedbackOptions,
  updateQualitySettings,
} from 'store/actions';

function QualitySettings() {
  const dispatch = useDispatch();
  const showQualitySettings = useSelector(
    (state) => state.settings.showQualitySettings,
  );

  const [settingsIsExist, setSettingsIsExist] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    dispatch(getQualitySettings())
      .then((res) => {
        const { options, text } = res;
        dispatch(setQualityFeedbackMessagePrefix(text));
        dispatch(setQualityFeedbackOptions(options));
        setSettingsIsExist(true);
      })
      .catch(() => setSettingsIsExist(false));
  }, [dispatch]);

  const showAlert = useCallback((message, success = true) => {
    setSnackbar({
      open: true,
      message,
      severity: success ? 'success' : 'error',
    });
  }, []);

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  const handleSave = useCallback(() => {
    if (!settingsIsExist) {
      dispatch(createQualitySettings(uuidGen.v4()))
        .then(() => {
          showAlert('Настройки успешно сохранены', true);
          setSettingsIsExist(true);
        })
        .catch(() =>
          showAlert(
            'Не удалось сохранить настройки. Обратитесь в тех поддержку',
            false,
          ),
        );
    } else {
      dispatch(updateQualitySettings())
        .then(() => {
          showAlert('Настройки успешно обновлены', true);
        })
        .catch(() =>
          showAlert(
            'Не удалось обновить настройки. Обратитесь в тех поддержку',
            false,
          ),
        );
    }
  }, [dispatch, settingsIsExist, showAlert]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={4}>
      <QualityFeedbackMessage />
      <Box flexWrap={'wrap'} display={'flex'} gap={4}>
        <div style={{ flex: 1, height: '100%', minWidth: 350 }}>
          <QualityFeedbackOptions />
        </div>
        <div style={{ flex: 1, height: '100%', minWidth: 350 }}>
          <QualityFeedbackMessagePrefix />
        </div>
      </Box>
      <div>
        <Button
          onClick={handleSave}
          disabled={!showQualitySettings}
          color="success"
          variant={'outlined'}
        >
          Сохранить
        </Button>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbar.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={2000}
        TransitionComponent={Slide}
      >
        <Alert
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default QualitySettings;
