import _ from 'lodash';
import styled from 'styled-components';
import CompanySelect from './CompanySelect';
import DepartmentSelect from './DepartmentSelect';
import CalendarSelect from './CalendarSelect';

export default function WorkTimeFilters() {
  return (
    <View>
      <CalendarSelect />
      <div className="filter">
        <CompanySelect />
      </div>
      <div className="filter">
        <DepartmentSelect />
      </div>
    </View>
  );
}

const View = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 22px;
  .filter {
    background-color: #f3f4f5;
    padding: 4px 4px 4px 9px;
    border-radius: 8px;
  }
  select:focus {
    background: inherit;
  }
  .MuiInput-underline {
    &:after {
      content: '';
      border: 0;
    }
    &:before {
      content: '';
      border: 0;
    }
  }
  .listFilterFix {
    border: 0;
    &:before {
      content: '';
      border: unset !important;
    }
  }
  .select {
    &:before,
    &:after {
      border-bottom: none !important;
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
  }
`;
