import axios from "axios";

export const createReq = ({
  method, url, body, formData,
}, tokens ) => {
  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (body) config.data = JSON.stringify(body);

  if (formData) {
    config.data = formData;
    config.headers['Content-Type'] = 'multipart/form-data';
  }
  config.headers.Authorization = `Bearer ${tokens.access.token}`;
  return axios(config);
};