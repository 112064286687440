import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Avatar, ListItem, ListItemText } from '@material-ui/core';
import { Instagram } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';

import ClearButton from './components/ClearButton';
import SelectFilter from './components/SelectFilter';


export default function ProviderFilter({
  handleClear,
  handleSelect,
  selected
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState();
  const providers = useSelector((state) => state.chat.companyProviders);
  //console.log(providers,'providers')

  const classes = useStyles();

  const handleOpenClose = (openOrClose) => {
    setOpen(openOrClose);
  }
  const getProviderTextById = (selectedUuid) => {
    const provider = providers.find(({ uuid }) => uuid === selectedUuid);
    return provider ? provider.name : null;
  }
  return (
    <View className={classes.filter}>
      <Instagram className={classes.filter_icon} />
      <SelectFilter text={getProviderTextById(selected) || t('tagStat.byProvider')} handleOpenClose={handleOpenClose} open={open}>
        {providers.map(({ uuid, name, provider }) =>
          <ListItem onClick={() => {
            handleSelect(uuid);
            setOpen(false);
          }}>
            <Avatar
              alt={provider}
              src={`/img/messengers/${provider}.svg`}
              className={classes.avatarImg} />
            <ListItemText primary={name} />
          </ListItem>)
        }
      </SelectFilter>
      <ClearButton handleClear={handleClear} />
    </View >
  );
}

ProviderFilter.propTypes = {
  handleClear: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
}

const View = styled.div`
`;