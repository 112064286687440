import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function CustomSelect({ label, value, onChange, options }) {
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={onChange}>
        {Object.entries(options).map(([key, label]) => (
          <MenuItem 
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "8px 16px",
            }}
            key={key} value={key}>{label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
