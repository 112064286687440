import { createContext, useCallback, useEffect, useMemo } from "react";
import uuid from "uuid/v4";

import PropTypes from 'prop-types';
import { useStopwatch } from "react-timer-hook";

import { useDispatch, useSelector } from "react-redux";
import { fetchWorkTimeCurrentState, sendWorkingTime } from "store/actions";

const TimeTrackingProviderContext = createContext(null);

function TimeTrackingProvider({ children }) {
  const dispatch = useDispatch();
  const currentState = useSelector((state) => state.workTime);

  const { user, currentDepartment, selectedCompany } = useSelector((state) => state.session);

  const { hours, minutes, seconds, pause, reset } = useStopwatch({
    autoStart: false,
  });

  useEffect(() => {
    if (currentState) {
      switch (currentState.type) {
        case 'start': {
          const durationTime = Date.now() - currentState.startTime;
          const stopwatchOffset = new Date(Date.now() + durationTime);

          reset(stopwatchOffset);
          break;
        }
        case 'pause': {
          const durationTime = currentState.pauseTime - currentState.startTime - currentState.totalPause;
          const stopwatchOffset = new Date(Date.now() + durationTime);

          reset(stopwatchOffset, false);
          break;
        }
        case 'resume': {
          const durationTime =  Date.now() - currentState.startTime - currentState.totalPause;
          const stopwatchOffset = new Date(Date.now() + durationTime);

          reset(stopwatchOffset);
          break;
        }
        case 'stop': {
          stop();
          break
        }
        default:
          break;
      }
    }
  }, [currentState]);

  useEffect(() => {
    dispatch(fetchWorkTimeCurrentState({ uuidUser: user.uuid }))
  }, [dispatch, user.uuid])

  const displayTime = useMemo(
    () => `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
    [hours, minutes, seconds]
  );

  const handleSend = useCallback(
    ({ type, sessionId }) => {
      if (!user && !selectedCompany) return;

      const data = {
        uuidUser: user.uuid,
        uuidCompany: selectedCompany,
        type,
        date: Date.now(),
        sessionId: sessionId || currentState?.sessionId,
      };

      if (!data.sessionId) {
        return;
      }

      if (currentDepartment) {
        data.uuidDepartment = currentDepartment;
      }

      return dispatch(sendWorkingTime(data));
    },
    [user, currentState, currentDepartment, dispatch]
  );

  const handleStart = useCallback(() => {
    handleSend({ type: 'start', sessionId: uuid() }).then(() => {
      reset();
    });
  }, [handleSend, reset])  

  const handleResume = useCallback(() => {
    handleSend({ type: 'resume' }).then((result) => {
      const durationTime =  Date.now() - result.startTime - result.totalPause;
      const stopwatchOffset = new Date(Date.now() + durationTime);

      reset(stopwatchOffset);
    });
  }, [handleSend, reset])

  const handlePause = useCallback(() => {
    handleSend({ type: 'pause' }).then(() => {
      pause();
    });
  }, [handleSend, pause])

  const handleStop = useCallback(() => {
    handleSend({ type: 'stop' }).then(() => {
      stop();
    });
  }, [handleSend])

  return (
    <TimeTrackingProviderContext.Provider value={{
      displayTime,
      state: currentState,
      start: handleStart,
      resume: handleResume,
      pause: handlePause,
      stop: handleStop,
      hours,
      minutes,
    }}>
      {children}
    </TimeTrackingProviderContext.Provider>
  );
}

TimeTrackingProvider.propTypes = {
  children: PropTypes.node
}

export { TimeTrackingProviderContext }
export default TimeTrackingProvider;