import update from 'immutability-helper';
import moment from 'moment';
import { set } from 'idb-keyval';
import _ from 'lodash';
import * as actionTypes from '../actions';

const initialState = {
  getCompanyProviders: {
    isFetching: false,
    error: null,
  },
  message: '',
  unreadCount: null,
  companyProviders: [],
  tags: [],
  selecetedCompany: null,
  dialogs: [],
  dialogsPagination: {},
  dialogsLoading: false,
  messagesLoading: false,
  newAssignDialogs: [],
  shouldOpenDialog: null,
  attachedFile: null,
  reply: null,
  isDebug: { persone: false, dialog: false },
  selectedMsg: [],
  forwardStatus: null,
  search: '',
  forwardDialogs: [],
  forwardDialogsPagination: null,
  forwardLoading: false,
  searchedDialogs: [],
  searchedDialogsLoading: false,
  typing: [],
  options: {},
  amoBaseUrl: '',
  dialogTitleLoading: false,
  sorted: false,
  countStat: {},
  settings: {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    schedule: {},
  },
};


const chatReducer = (state = initialState, action) => {
  const { type, payload, session } = action;
  switch (type) {
    case actionTypes.SET_USER_SETTINGS: {
      return update(state, {
        settings: {
          $set: { ...state.settings, ...payload },
        },
      });
    }
    case actionTypes.DIALOG_TASK_LIST_HAS_TODO_SET:
      return update(state, {
        dialogs: {
          $apply: (dialogs) =>
            dialogs.map((dialog) => {
              if (dialog.uuid === payload.uuidDialog) {
                return {
                  ...dialog,
                  hasTodoTask: payload.hasTodoTask,
                };
              }
              return dialog;
            }),
        },
      });
    case actionTypes.DELETE_TAG:
      return update(state, {
        tags: {
          $set: state.tags.filter(
            ({ uuidCompany, title }) =>
              !(payload.title === title && payload.uuidCompany === uuidCompany),
          ),
        },
      });
    case actionTypes.REMOVE_DIALOG:
      return update(state, {
        dialogs: {
          $set: state.dialogs.filter(({ uuid }) => uuid !== payload.uuid),
        },
      });
    case actionTypes.LOADING_UPDATE_DIALOG_TITLE:
      return update(state, {
        dialogTitleLoading: { $set: payload },
      });
    case actionTypes.DELETE_DIALOG:
      return update(state, {
        dialogs: {
          $set: state.dialogs.filter(
            ({ uuid }) => !(payload.uuidDialog === uuid),
          ),
        },
      });
    case actionTypes.UPDATE_DIALOG_ADDITIONAL:
      return update(state, {
        dialogs: {
          $apply: (d) => d.map((dialog) => {

            if (payload.ids.uuidDialog !== dialog.uuid) {
              return dialog;
            }

            dialog.history.push(payload);

            return {
              ...dialog,
              additional: payload.additional,
            };
          }),
        },
      });
    case actionTypes.UPDATE_DIALOG_TITLE:
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.uuid !== payload.uuid) return dialog;
              return {
                ...dialog,
                additional: payload.additional,
              };
            }),
        },
        dialogTitleLoading: { $set: false },
      });
    case actionTypes.SET_AMO_BASE_URL:
      return update(state, {
        amoBaseUrl: { $set: payload },
      });
    case actionTypes.UPDATE_PERSONES_NAME:
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              const phone = dialog.persone.info.find(
                (info) => info.key === 'phone',
              );
              if (!phone) return dialog;
              const contact = payload.find(({ phones }) =>
                phones.find((contactPhone) => contactPhone.includes(phone.val)),
              );
              if (!contact) return dialog;
              if (dialog.ids.uuidPersone && dialog.persone) {
                set(`persone:${dialog.ids.uuidPersone}`, dialog.persone).then();
              }
              const name = dialog.persone.info.find(
                (info) => info.key === 'name',
              );
              if (!name) {
                return {
                  ...dialog,
                  persone: {
                    ...dialog.persone,
                    info: [
                      ...dialog.persone.info,
                      {
                        key: 'name',
                        val: contact.name,
                        type: 'text',
                      },
                    ],
                  },
                };
              }
              return {
                ...dialog,
                persone: {
                  ...dialog.persone,
                  info: dialog.persone.info.map((info) => {
                    if (info.key !== 'name') return info;
                    return {
                      key: 'name',
                      val: contact.name,
                      type: 'text',
                    };
                  }),
                },
              };
            }),
        },
      });
    case actionTypes.OPEN_DIALOG_OPTIONS: {
      return update(state, {
        options: { $set: payload },
      });
    }
    case actionTypes.SET_TYPING: {
      return update(state, {
        typing: { $set: payload },
      });
    }
    case actionTypes.CHAT_SET_FORWARD_DIALOG: {
      return update(state, {
        forwardDialogs: { $set: payload.rows },
        forwardDialogsPagination: { $set: payload.pagination },
      });
    }
    case actionTypes.CHAT_SET_FORWARD_DIALOG_LOADING: {
      return update(state, {
        forwardLoading: { $set: payload },
      });
    }
    case actionTypes.CHAT_SEARCH_SET_DIALOG_LOADING: {
      return update(state, {
        searchedDialogsLoading: { $set: payload },
      });
    }
    case actionTypes.SORT_DIALOGS: {
      const assignSorting = (a, b, originalSorting) => {
        if (
          state.newAssignDialogs.indexOf(a.uuid) >
          state.newAssignDialogs.indexOf(b.uuid)
        ) {
          return -1;
        }
        if (
          state.newAssignDialogs.indexOf(a.uuid) <
          state.newAssignDialogs.indexOf(b.uuid)
        ) {
          return 1;
        }
        return originalSorting;
      };
      return update(state, {
        dialogs: {
          $set: state.dialogs.sort((a, b) => {
            if (a.date.lastMessage > b.date.lastMessage) {
              return assignSorting(a, b, -1);
            }
            if (a.date.lastMessage < b.date.lastMessage) {
              return assignSorting(a, b, 1);
            }
            return assignSorting(a, b, 0);
          }),
        },
      });
    }
    case actionTypes.RESET_SORTED: {
      return update(state, {
        sorted: { $set: false },
      });
    }
    case actionTypes.SET_FORWARD_STATUS: {
      return update(state, {
        forwardStatus: { $set: payload },
      });
    }
    case actionTypes.CHAT_CHANGE_COPMANY: {
      const { uuid } = payload;
      return update(state, {
        selecetedCompany: { $set: uuid },
      });
    }
    case actionTypes.CHAT_UPLOAD_REQUEST: {
      const { uuidDialog } = payload;
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.uuid !== uuidDialog) return dialog;

              return {
                ...dialog,
                isUploading: true,
              };
            }),
        },
      });
    }
    case actionTypes.CHAT_UPLOAD_COMPLETE: {
      const { uuidDialog } = payload;
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.uuid !== uuidDialog) return dialog;

              return {
                ...dialog,
                isUploading: false,
              };
            }),
        },
      });
    }
    case actionTypes.CHAT_UPLOAD_FILE: {
      const { uuidDialog, files } = payload;
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.uuid !== uuidDialog) return dialog;

              return {
                ...dialog,
                files: dialog.files ? [...dialog.files, ...files] : files,
              };
            }),
        },
      });
    }
    case actionTypes.CHAT_UPLOAD_FILE_CLEAR: {
      const { uuidDialog } = payload;
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.uuid !== uuidDialog) return dialog;
              return {
                ...dialog,
                files: [],
              };
            }),
        },
      });
    }
    case actionTypes.CHAT_UPLOAD_FILE_REMOVE: {
      const { uuidDialog, uuidFile } = payload;
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.uuid !== uuidDialog) return dialog;
              return {
                ...dialog,
                files: dialog.files.filter((x) => x.uuid !== uuidFile),
              };
            }),
        },
      });
    }

    case actionTypes.CHAT_SET_DIALOG_LOADING: {
      const { loading } = payload;
      return update(state, {
        dialogsLoading: { $set: loading },
      });
    }

    case actionTypes.RECEIVE_CHAT_ATTACHMENT: {
      return update(state, {
        attachedFile: { $set: action.data },
      });
    }

    case actionTypes.SEARCH_DIALOGS: {
      return update(state, {
        searchedDialogsLoading: { $set: false },
        searchedDialogs: { $set: payload },
      });
    }

    case actionTypes.CLEAR_CHAT_ATTACHMENT: {
      return update(state, {
        attachedFile: { $set: null },
      });
    }

    case actionTypes.CHAT_ADD_PERSONE_TAG: {
      const { dialogs } = state;
      dialogs.forEach((d, i) => {
        if (d.persone.uuid === payload.uuid) {
          dialogs[i].persone.tags.push(payload.tag);
        }
      });
      return update(state, {
        dialogs: { $set: dialogs },
      });
    }
    case actionTypes.CHAT_ASSIGNEE_SET: {
      const { uuidDialog } = payload.ids;
      let newTitle;
      if (_.get(payload, 'content.data.type') === 'group.titleChanged') {
        newTitle = _.get(payload, 'content.data.params.title');
      }
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.uuid !== uuidDialog) return dialog;
              dialog.history.push(payload);
              const newDialog = dialog;
              if (newTitle && _.get(dialog, 'additional.group.title')) {
                newDialog.additional.group.title = newTitle;
              }
              return {
                ...newDialog,
                assignee: {
                  type: _.get(payload, 'content.data.type').split('.')[1],
                  uuid: _.get(payload, 'content.data.params.toUuid'),
                },
              };
            }),
        },
      });
    }

    case actionTypes.CHAT_DEL_PERSONE_TAG: {
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.persone.uuid !== payload.uuid) return dialog;
              return {
                ...dialog,
                persone: {
                  ...dialog.persone,
                  tags: dialog.persone.tags.filter(
                    (tag) => tag !== payload.tag,
                  ),
                },
              };
            }),
        },
      });
    }
    case actionTypes.CHAT_DEBUG_ENABLE: {
      return update(state, {
        isDebug: {
          $set: { persone: payload.debugPersone, dialog: payload.debugDialog },
        },
      });
    }
    case actionTypes.CHAT_DEBUG_DISABLE: {
      return update(state, {
        isDebug: {
          $set: { persone: payload.debugPersone, dialog: payload.debugDialog },
        },
      });
    }
    case actionTypes.CHAT_ADD_PERSONE_INFO: {
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.persone.uuid !== payload.uuid) return dialog;
              return {
                ...dialog,
                persone: {
                  ...dialog.persone,
                  info: [
                    ...dialog.persone.info,
                    {
                      key: payload.field,
                      val: payload.value,
                      type: payload.type,
                    },
                  ],
                },
              };
            }),
        },
      });
    }
    case actionTypes.SET_CHAT_MESSAGE: {
      return update(state, {
        message: { $set: action.message },
      });
    }

    case actionTypes.SET_CHAT_MESSAGES_COUNT: {
      return update(state, {
        unreadCount: { $set: payload },
      });
    }

    case actionTypes.CHAT_UPDATE_PERSONE_INFO: {
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.persone.uuid !== payload.uuid) return dialog;
              return {
                ...dialog,
                persone: {
                  ...dialog.persone,
                  info: dialog.persone.info.map((info) => {
                    if (info.key !== payload.field) return info;
                    return {
                      key: info.key,
                      val: payload.value,
                      type: payload.type,
                    };
                  }),
                },
              };
            }),
        },
      });
    }
    case actionTypes.CHAT_GET_PERSONE: {
      const { persone } = payload;
      set(`persone:${persone.uuid}`, persone).then();
      return state;
    }

    case actionTypes.CHAT_RCV_DIALOG: {
      let { dialogs } = state;
      const dialog = payload;
      // Cache for persones
      set(`persone:${dialog.persone.uuid}`, dialog.persone).then();

      if (dialogs.find((d) => d.uuid === dialog.uuid)) {
        dialogs.forEach((d, i) => {
          if (d.uuid === dialog.uuid) {
            dialogs[i] = dialog;
          }
        });
      } else {
        dialogs = [dialog, ...dialogs];
      }

      return update(state, {
        dialogsLoading: { $set: false },
        dialogs: { $set: dialogs },
      });
    }

    case actionTypes.CHAT_RCV_DIALOGS: {
      const { rows = [], pagination = {} } = payload;

      // Cache for persones
      rows.forEach(({ persone }) =>
        set(`persone:${persone.uuid}`, persone).then(),
      );

      const saveCurrentDialog = state.dialogs.filter(
        (dialog) => dialog.uuid === session.currentDialog,
      )[0];

      let updatedDialogs = [];
      let addCurrentDialog = [];
      let dialogsInState = state.dialogs;

      if (
        saveCurrentDialog !== null &&
        saveCurrentDialog !== undefined &&
        !rows.find((dialog) => dialog.uuid === saveCurrentDialog.uuid) &&
        pagination.page < 2
      ) {
        addCurrentDialog = [saveCurrentDialog];
      }

      if (
        saveCurrentDialog !== null &&
        saveCurrentDialog !== undefined &&
        rows.find((dialog) => dialog.uuid === saveCurrentDialog.uuid)
      ) {
        addCurrentDialog = [];
        dialogsInState = dialogsInState.filter(
          (dialog) => dialog.uuid !== saveCurrentDialog.uuid,
        );
      }

      if (pagination.page > state.dialogsPagination.page) {
        updatedDialogs = [...addCurrentDialog, ...dialogsInState, ...rows];
      } else {
        updatedDialogs = [...addCurrentDialog, ...rows];
      }
      return update(state, {
        dialogsLoading: { $set: false },
        dialogs: {
          $set: updatedDialogs,
        },
        dialogsPagination: { $set: pagination },
      });
    }
    case actionTypes.CLEAR_COUNT_STAT: {
      return update(state, {
        countStat: { $set: {} },
      });
    }
    case actionTypes.SET_COUNT_STAT: {
      return update(state, {
        countStat: { $set: payload },
      });
    }
    case actionTypes.CHAT_DIALOG_OPENED: {
      const { dialogs } = state;

      const dialog = dialogs.find(({ uuid }) => uuid === payload.uuidDialog);
      if (!dialog || !_.has(dialog, 'additional')) return state;

      dialog.additional.unopened = payload.unopened;
      return update(state, {
        dialogs: { $set: [...dialogs] },
      });
    }
    case actionTypes.CHAT_DIALOG_READ: {
      const { dialogs, countStat } = state;

      const dialog = dialogs.find(({ uuid }) => uuid === payload.uuid);
      if (
        !dialog ||
        !_.get(dialog, 'additional.countUnread') ||
        !_.get(dialog, 'additional.lastMessageFrom')
      ) {
        return state;
      }
      const {
        additional: { countUnread, lastMessageFrom },
      } = dialog;
      if (lastMessageFrom) {
        if (countStat.unread) {
          if (countStat.unread > countUnread) {
            countStat.unread -= countUnread;
          } else {
            countStat.unread = 0;
          }
        }
        if (lastMessageFrom === 'client' && countStat.noAnswerForClient) {
          if (countStat.noAnswerForClient > countUnread) {
            countStat.noAnswerForClient -= countUnread;
          } else {
            countStat.noAnswerForClient = 0;
          }
        } else if (
          lastMessageFrom === 'support' &&
          countStat.noAnswerForSupport
        ) {
          if (countStat.noAnswerForSupport > countUnread) {
            countStat.noAnswerForSupport -= countUnread;
          } else {
            countStat.noAnswerForSupport = 0;
          }
        }
      }
      dialog.additional.countUnread = 0;
      return update(state, {
        dialogs: { $set: [...dialogs] },
        countStat: { $set: { ...countStat } },
      });
    }

    case actionTypes.CHAT_DIALOG_UNREAD: {
      const { dialogs } = state;
      const finded = dialogs.find(({ uuid }) => uuid === payload.uuid);
      if (!finded) {
        return state;
      }
      dialogs.forEach((dialog, i) => {
        if (dialog.uuid !== payload.uuid) return;
        const { additional = {} } = dialog;
        const { count } = payload;
        const countUnread =
          count === '++' ? ((additional || {}).countUnread || 0) + 1 : count;
        dialogs[i].additional = {
          ...(additional || {}),
          countUnread,
        };
      });
      return update(state, {
        dialogs: { $set: [...dialogs] },
      });
    }

    case actionTypes.CHAT_MESSAGE_STATUS: {
      const {
        ids: { uuidMessage, newUuidMessage },
        status,
        errors,
      } = payload;
      const { dialogs } = state;

      let updated = false;
      // eslint-disable-next-line
      for (const i in dialogs) {
        for (const j in dialogs[i].history) {
          if (
            dialogs[i].history[j].uuid !== uuidMessage &&
            dialogs[i].history[j].ids.uuidMessage !== uuidMessage
          ) {
            continue;
          }
          dialogs[i].history[j] = {
            ...dialogs[i].history[j],
            status,
            additional: {
              ...(dialogs[i].history[j].additional || {}),
              errors,
            },
          };
          if (newUuidMessage) {
            dialogs[i].history[j].ids.uuidMessage = newUuidMessage;
          }
          updated = true;
          break;
        }
        if (updated) break;
      }

      if (updated) return update(state, { dialogs: { $set: [...dialogs] } });
      return state;
    }

    case actionTypes.CHAT_REPLY_MESSAGE: {
      return update(state, {
        reply: {
          $set: payload,
        },
      });
    }

    case actionTypes.CHAT_MESSAGE: {
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.uuid !== payload.ids.uuidDialog) return dialog;
              return {
                ...dialog,
                history: [...dialog.history, payload],
                date: {
                  ...dialog.date,
                  lastMessage: moment().valueOf(),
                },
                hasNew: true,
              };
            }),
        },
      });
    }

    case actionTypes.CHAT_CLEAR_NEW_MESSAGES_STATE: {
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.uuid !== action.uuidDialog) return dialog;
              return {
                ...dialog,
                hasNew: false,
              };
            }),
        },
      });
    }

    case actionTypes.ARCHIVE_DIALOG: {
      return update(state, {
        dialogs: {
          $apply: (d) =>
            d.map((dialog) => {
              if (dialog.uuid !== payload.uuid) return dialog;
              return {
                ...dialog,
                status: 'closed',
              };
            }),
        },
      });
    }

    case actionTypes.ADD_NEW_ASSIGN_DIALOG: {
      return update(state, {
        newAssignDialogs: { $push: [payload.uuid] },
      });
    }

    case actionTypes.REMOVE_NEW_ASSIGN_DIALOG: {
      return update(state, {
        newAssignDialogs: {
          $set: state.newAssignDialogs.filter((d) => d !== payload.uuid),
        },
      });
    }

    case actionTypes.ADD_SELECTED_MSG: {
      const newList = [...state.selectedMsg];
      const msgId = action.payload;
      const index = newList.indexOf(msgId);
      if (index === -1) {
        newList.push(msgId);
      } else {
        newList.splice(index, 1);
      }
      return update(state, {
        selectedMsg: {
          $set: newList,
        },
      });
    }

    case actionTypes.CLEAR_SELECTED_MSG:
      return update(state, {
        selectedMsg: {
          $set: [],
        },
      });

    case actionTypes.CHAT_UPDATE_DIALOG_TAGS: {
      return update(state, {
        dialogs: {
          $apply: (dialogs) =>
            dialogs.map((dialog) => {
              if (payload.uuid === dialog.uuid) {
                return {
                  ...dialog,
                  tags: payload.tags,
                };
              }
              return dialog;
            }),
        },
      });
    }

    case actionTypes.CHAT_UPDATE_DIALOG_HISTORY: {
      return update(state, {
        dialogs: {
          $apply: (dialogs) =>
            dialogs.map((dialog) => {
              if (action.payload.rows[0].ids.uuidDialog === dialog.uuid) {
                let history = payload.rows;
                if (dialog.historyPagination) {
                  const uuidSet = new Set(
                    dialog.history.map((msg) => msg.uuid),
                  );
                  const uniqueRows = payload.rows.filter(
                    ({ uuid }) => !uuidSet.has(uuid),
                  );
                  history = [...uniqueRows, ...dialog.history];
                }
                return {
                  ...dialog,
                  first: payload.first || false,
                  history,
                  historyPagination: payload.pagination,
                };
              }
              return dialog;
            }),
        },
      });
    }

    case actionTypes.CHAT_SET_MESSAGES_GET_LOADING_STATE: {
      return update(state, {
        messagesLoading: { $set: action.isLoading },
      });
    }

    case actionTypes.REQUEST_COMPANY_PROVIDERS:
      return update(state, {
        getCompanyProviders: {
          isFetching: { $set: true },
          error: { $set: null },
        },
      });

    case actionTypes.RECEIVE_COMPANY_PROVIDERS:
      return update(state, {
        getCompanyProviders: {
          isFetching: { $set: false },
        },
      });

    case actionTypes.ERROR_COMPANY_PROVIDERS:
      return update(state, {
        getCompanyProviders: {
          isFetching: { $set: false },
          error: { $set: action.err },
        },
      });

    case actionTypes.SET_COMPANY_PROVIDERS: {
      return update(state, {
        companyProviders: { $set: action.payload },
      });
    }

    case actionTypes.CHAT_OPEN_DIALOG: {
      return update(state, {
        shouldOpenDialog: { $set: action.uuidDialog },
      });
    }

    case actionTypes.CHAT_GET_TAGS: {
      return update(state, {
        tags: { $set: action.payload },
      });
    }

    case actionTypes.CHAT_GET_PERSON_TAGS: {
      return update(state, {
        personTags: { $set: action.payload },
      });
    }

    default: {
      return state;
    }
  }
};

export default chatReducer;
