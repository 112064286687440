// main select'а как такового нет (эта опция передается во время выбора модалки)
export const ROUTING_TARGET_MAIN_SELECT = {
  EMPLOYEES: 'settingsDrawer.employees',
  DEPARTMENTS: 'settingsDrawer.departments',
};

export const ROUTING_TARGET_EMPLOYEE_LABELS = {
  ACTIVE: 'Активен сотрудник',
  ONLINE: 'routingPage.employeeOnline', // Сотрудник онлайн
  SCHEDULE: 'routingPage.schedule', // График работы
  AVERAGE_IN_WORK: 'routingPage.taskTakingTime', // Время взятия диалога в работу
  AVERAGE_CLOSE: 'routingPage.averageTimeForClosing', // Среднее время завершения диалога
  DIALOG_COUNT: 'routingPage.amountOfDialogsInWork', // Количество диалогов в работе
  DEPARTMENT: 'routingPage.department', // Отдел
};
export const ROUTING_TARGET_EMPLOYEE_VALUES = {
  ACTIVE: 'active',
  ONLINE: 'online',
  SCHEDULE: 'schedule',
  AVERAGE_IN_WORK: 'averageInWork',
  AVERAGE_CLOSE: 'averageClose',
  DIALOG_COUNT: 'dialogCount',
  DEPARTMENT: 'department',
};
export const DEFAULT_EMPLOYEE_IN_WORK_CONDITIONS = {
  [ROUTING_TARGET_EMPLOYEE_VALUES.AVERAGE_IN_WORK]: {
    value: 0,
  },
};
export const DEFAULT_EMPLOYEE_ACTIVE_CONDITIONS = {
  [ROUTING_TARGET_EMPLOYEE_VALUES.ACTIVE]: {
    operator: 'eq',
    duration: 0,
  },
};
export const DEFAULT_EMPLOYEE_SCHEDULE_CONDITIONS = {
  [ROUTING_TARGET_EMPLOYEE_VALUES.SCHEDULE]: {
    monthDays: [],
  },
};
export const DEFAULT_EMPLOYEE_IN_CLOSE_CONDITIONS = {
  [ROUTING_TARGET_EMPLOYEE_VALUES.AVERAGE_CLOSE]: {
    operator: 'eq',
    duration: 0,
  },
};
export const DEFAULT_EMPLOYEE_DIALOG_COUNT_CONDITIONS = {
  [ROUTING_TARGET_EMPLOYEE_VALUES.DIALOG_COUNT]: {
    value: 0,
    operator: 'eq',
  },
};
export const DEFAULT_EMPLOYEE_ONLINE_CONDITIONS = {
  [ROUTING_TARGET_EMPLOYEE_VALUES.ONLINE]: false,
};

export const ROUTING_SCHEDULE_VALUES = {
  WEEK: 'weekDays',
  MONTH: 'monthDays',
};
export const ROUTING_SCHEDULE_OPTIONS = [
  { value: ROUTING_SCHEDULE_VALUES.WEEK, label: 'routingPage.weekDays' },
  { value: ROUTING_SCHEDULE_VALUES.MONTH, label: 'routingPage.monthDays' },
];
export const ROUTING_TARGET_EMPLOYEE_MAIN_SELECT_OPTIONS = [
  {
    value: ROUTING_TARGET_EMPLOYEE_VALUES.DIALOG_COUNT,
    label: ROUTING_TARGET_EMPLOYEE_LABELS.DIALOG_COUNT,
  },
  {
    value: ROUTING_TARGET_EMPLOYEE_VALUES.SCHEDULE,
    label: ROUTING_TARGET_EMPLOYEE_LABELS.SCHEDULE,
  },
  // {
  //   value: ROUTING_TARGET_EMPLOYEE_VALUES.AVERAGE_IN_WORK,
  //   label: ROUTING_TARGET_EMPLOYEE_LABELS.AVERAGE_IN_WORK,
  // },
  {
    value: ROUTING_TARGET_EMPLOYEE_VALUES.AVERAGE_CLOSE,
    label: ROUTING_TARGET_EMPLOYEE_LABELS.AVERAGE_CLOSE,
  },
  // TODO: может пригодиться
  {
    value: ROUTING_TARGET_EMPLOYEE_VALUES.ACTIVE,
    label: ROUTING_TARGET_EMPLOYEE_LABELS.ACTIVE,
  },
  {
    value: ROUTING_TARGET_EMPLOYEE_VALUES.ONLINE,
    label: ROUTING_TARGET_EMPLOYEE_LABELS.ONLINE,
  },
  // {
  //   value: ROUTING_TARGET_EMPLOYEE_VALUES.DEPARTMENT,
  //   label: ROUTING_TARGET_EMPLOYEE_LABELS.DEPARTMENT,
  // },
];

export const ROUTING_TARGET_DEPARTMENTS_LABELS = {
  SCHEDULE: 'routingPage.schedule',
};
export const ROUTING_TARGET_DEPARTMENTS_VALUES = {
  SCHEDULE: 'schedule',
};
export const ROUTING_TARGET_DEPARTMENT_MAIN_SELECT_OPTIONS = [
  {
    value: ROUTING_TARGET_DEPARTMENTS_VALUES.SCHEDULE,
    label: ROUTING_TARGET_DEPARTMENTS_LABELS.SCHEDULE,
  },
];
export const DEFAULT_DEPARTMENT_SCHEDULE = {
  [ROUTING_TARGET_DEPARTMENTS_VALUES.SCHEDULE]: {
    monthDays: [],
    departmentUuid: '',
  },
};
