import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Box } from "@mui/material";
import Divider from '../../../../components/Divider';

import { StyledScheduleContainer } from "../SettingsSchedule/SettingsSchedule";
import AppButton from "components/AppButton/AppButton";
import { EmployeeSelect, TimeZoneSelect, WeekdayRow } from "./components";
import { getEmployeesWithSchedule, setEmployeeSchedule } from "store/actions";
import { defaultSchedule } from "store/reducers/myScheduleReducer";


const FirstScheduleContainer = styled(StyledScheduleContainer)({
  marginBottom: '24px !important',
  paddingBottom: '6px !important',
});

const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

function SettingsEmployeeSchedules() {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  useEffect(() => {
    dispatch(getEmployeesWithSchedule()).then((data) => {
      const employeeList = data.map((employee) => ({ value: employee.uuid, label: employee.name, schedule: employee.schedule || defaultSchedule }));

      if (employeeList.length > 0) {
        setSelectedEmployee(employeeList[0]);
      }

      setEmployees(employeeList);
    });
  }, [dispatch])

  // handlers

  const handleSelectTimeZone = useCallback((timeZone) => {
    if (selectedEmployee) {
      setSelectedEmployee({
        ...selectedEmployee,
        schedule: {
          ...selectedEmployee.schedule,
          gmt: timeZone,
        }
      })
    }
  }, [selectedEmployee])

  const handleSelectEmployee = useCallback(setSelectedEmployee, [setSelectedEmployee])

  const handelSelectWeekDay = useCallback((weekday, status) => {
    if (selectedEmployee) {
      setSelectedEmployee({
        ...selectedEmployee,
        schedule: {
          ...selectedEmployee.schedule,
          [weekday]: {
            times: selectedEmployee.schedule[weekday].times,
            state: status,
          },
        }
      })
    }
  }, [selectedEmployee])

  const handelSelectTimes = useCallback((weekday, newInterval) => {
    if (selectedEmployee) {
      setSelectedEmployee({
        ...selectedEmployee,
        schedule: {
          ...selectedEmployee.schedule,
          [weekday]: {
            times: [...selectedEmployee.schedule[weekday].times ,newInterval],
            state: selectedEmployee.schedule[weekday].state,
          },
        }
      })
    }
  }, [selectedEmployee])

  const handleDeleteTimes = useCallback((weekday, index) => {
    if (selectedEmployee) {
      const updatedTimes = selectedEmployee.schedule[weekday].times.filter((_, i) => i !== index);
      
      setSelectedEmployee({
        ...selectedEmployee,
        schedule: {
          ...selectedEmployee.schedule,
          [weekday]: {
            ...selectedEmployee.schedule[weekday],
            times: updatedTimes
          }
        }
      });
    }
  }, [selectedEmployee, setSelectedEmployee]);
  

  const handleSave = useCallback(() => {
    if (selectedEmployee) {
      dispatch(setEmployeeSchedule({
        uuidUser: selectedEmployee.value,
        schedule: selectedEmployee.schedule,
      })).then((newSchedule) => {
        setEmployees((emps) =>
          emps.map((emp) =>
            emp.value === selectedEmployee.value
              ? { ...emp, schedule: newSchedule }
              : emp
          )
        );
      });
    }
  }, [dispatch, selectedEmployee]);

  return (
    <>
    <FirstScheduleContainer>
      <Divider title={"Для кого"} />
      <EmployeeSelect
        value={selectedEmployee?.label || null}
        list={employees}
        onSelect={handleSelectEmployee}
      />
    </FirstScheduleContainer>
    <StyledScheduleContainer>
      <Divider title={translate('schedules.timezone')} />
      <TimeZoneSelect
        timezone={selectedEmployee?.schedule?.gmt || ""}
        onSelect={handleSelectTimeZone}
      />

      <Divider title={translate('schedules.workTime')} />
      <Box display="flex" flexDirection="column" gap={1}>
        {weekdays.map((day) => (
          <WeekdayRow
            key={day}
            weekday={day}
            times={selectedEmployee?.schedule[day]?.times || []}
            status={selectedEmployee?.schedule[day]?.state || false}
            onSelect={handelSelectWeekDay}
            onSelectTimes={handelSelectTimes}
            onDeleteTimes={handleDeleteTimes}
          />
        ))}
      </Box>

      <Box textAlign="right" mt="20px">
        <AppButton
          type="common  "
          onClick={handleSave}
        >
          {translate('templatesTool.apply')}
        </AppButton>
      </Box>
    </StyledScheduleContainer>
   </>
  )
}

export default SettingsEmployeeSchedules;