import { useTranslation } from 'react-i18next';
import React from 'react';
import { Card, CardContent, Grid, TextField } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  postTemplateCreateAttachmentThroughBody,
  clearPostCreateTemplateAttachment,
} from 'store/actions';
import { Button, Textarea } from '../../../../../../../../components';
import AddButton from '../../../../../../../../components/AddButton';
import AttachFileForm from '../AttachFileForm';


const TextareaWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SuggestionsList = styled.div`
  position: absolute;
  top: ${({ cursorPosition }) => cursorPosition.top}px;
  left: ${({ cursorPosition }) => cursorPosition.left}px;
  max-height: 150px;
  overflow-y: auto;
  background: white;
  border: 1px solid #f3eded;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
  z-index: 1000;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  p {
    padding: 4px 8px;
    cursor: pointer;
  }

  p:hover {
    background-color: #f0f0f0;
  }
`;

const CreateForm = styled.div`
  margin: 8px;
`;
const TemplateCard = styled(Card)`
  ${({ theme }) => `
    margin: ${theme.spacing(1)}px;
  `}
`;
const CreateTemplateForm = ({ onCreate, mainInfo }) => {
  const { t } = useTranslation();
  const currentRef = React.useRef();
  const dispatch = useDispatch();

  const attachedFile = useSelector(
    (state) => state.templates.createdAttachedFile,
  );

  const [isCreate, setCreate] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [caption, setCaption] = React.useState('');



  const [suggestions, setSuggestions] = React.useState([]);
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const [cursorPosition, setCursorPosition] = React.useState({ top: 0, left: 0 });  
 

  const mockSuggestions = mainInfo.filter(({ key }) => key === "name" || key === "phone") 
    .map(({ key, val }) => ({
      tag: key.toUpperCase(), 
      value: val,            
    }));
 

  
  const handleInputChange = (e) => {
    const text = e.target.value;
    setValue(text);
  
    const lastWord = text.split(' ').pop();

    if (lastWord.startsWith('@')) {
      const query = lastWord.slice(1).toLowerCase();
      const filteredSuggestions = mockSuggestions.filter((item) =>
        item.tag.toLowerCase().includes(query) 
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }

  };

  const handleMouseOrKeyUp = (e) => {
    const cursorIndex = e.target.selectionStart;
    const charWidth = 8;
    const lineHeight = 20; 

    const lineNumber = Math.floor(cursorIndex / e.target.cols);
    const charPositionInLine = cursorIndex % e.target.cols;

    setCursorPosition({
      top: (lineNumber * lineHeight) + 3 ,
      left: (charPositionInLine * charWidth) + 20,
    });
  };

  const handleSuggestionClick = (suggestion) => {
    const words = value.split(' ');
    words.pop(); 
    const newText = [...words, `{{ ${suggestion.tag.toLowerCase()} }}`].join(' ') + ' ';
    setValue(newText); 
    setShowSuggestions(false); 

  };
  

  



  const handleClearAttachment = () => {
    dispatch(clearPostCreateTemplateAttachment());
  };



const handleCreate = () => {


  if (value.length > 0) {
    const payload = {
      title: caption,
      text: value, 
    };

    if (attachedFile) {
      payload.file = attachedFile;
    }

    onCreate(payload);

    handleClearAttachment();
    setCreate(false);
  }
  setValue('')
};


  const handleCancel = () => {
    setCreate(false);
    handleClearAttachment();
    setValue('');
  };
  const handleAttach = (fileName, data) => {
    dispatch(postTemplateCreateAttachmentThroughBody(fileName, data));
  };
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (currentRef.current && !currentRef.current.contains(event.target)) {
        handleClearAttachment();
        handleCancel();
      }
    }
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [currentRef]);
  const formCreate = (
    <TemplateCard ref={currentRef}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
        
            <TextField 
              onChange={(e) => setCaption(e.target.value)}
              fullWidth
              margin='dense'
              label={t("templatesTool.title")}
              variant='outlined'
              value={caption}
            />
        <TextareaWrapper>
            <Textarea
              onKeyUp={handleMouseOrKeyUp}
              onClick={handleMouseOrKeyUp}
              onChange={(e) => handleInputChange(e)}
              value={value}
              id="template_input"
            />
            {showSuggestions && suggestions.length > 0 && (
              <SuggestionsList cursorPosition={cursorPosition} 
                className="visible">
                {suggestions.map((suggestion, index) => (
                  <p  key={index} onClick={() => handleSuggestionClick(suggestion)}>
                        {suggestion.tag.toLowerCase()}
                  </p>
                ))}
              </SuggestionsList>
            )}
        </TextareaWrapper>
            <AttachFileForm
              onClear={handleClearAttachment}
              attachedFile={attachedFile}
              onAttach={handleAttach}
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <Button
                type="button"
                className="primary"
                style={{ margin: '10px 10px 10px 0' }}
                disabled={value.length < 1}
                onClick={handleCreate}
              >
                {t('templatesTool.save')}
              </Button>
              <Button
                type="button"
                className="secondary"
                onClick={handleCancel}
              >
                {t('templatesTool.cancel')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </TemplateCard>
  );
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AddButton
            text={t('templatesTool.createTemplate')}
            // onClick={handleAddNewButton}
            onClick={() => setCreate(true)}
            style={{
              margin: '15px 0 0 15px',
              paddingBottom: '15px',
              borderBottom: '1px solid #eeeff2',
              fontSize: '15px',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CreateForm>{isCreate ? formCreate : ''}</CreateForm>
        </Grid>
      </Grid>
    </>
  );
};

CreateTemplateForm.propTypes = {
  onCreate: PropTypes.func,
  mainInfo:PropTypes.array
};
CreateTemplateForm.defaultProps = {
  onCreate: () => {},
  mainInfo: []
};
export default CreateTemplateForm;
