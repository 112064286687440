import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WorkTimeLoading = ({ isLoading }) => {
  const { t } = useTranslation();

  if (!isLoading) {
    return null;
  }

  return (
    <StatisticsLoadingDiv>
      <p>
        {t('graphInfo.loading', { dial: 1 })}
      </p>
    </StatisticsLoadingDiv>
  );
};

WorkTimeLoading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};


const StatisticsLoadingDiv = styled.div`
  background: #f7f7f7;
  border-radius: 6px;
  padding: 7px 0;
  margin-bottom: 32px;
  p {
    text-align: center;
    font-size: 14px;
    color: #a6a6a6;
    font-family: 'Helvetica';
  }
`;

export default WorkTimeLoading;
