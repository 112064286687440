import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  showModal,
} from 'store/actions';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useTrackingTimeStyles } from '../hooks';

function StartWorkingTime() {
  const classes = useTrackingTimeStyles();
  const dispatch = useDispatch();

  const handleStart = useCallback(() => {
    dispatch(showModal('StartTimeTracker'));
  }, [dispatch]);

  return (
    <div onClick={handleStart} className={`${classes.container} ${classes.startHover}`}>
      <div className={classes.startButton}>
        Начать
      </div>
      <span className={classes.height}>
        <PlayArrowIcon className={classes.green} />
      </span>
    </div>
  )
}


export default StartWorkingTime;