import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Typography } from '@material-ui/core';
import { useTimeTracking } from 'hooks';
import ModalWrapper, { ActionButton } from './layout';

const Wrapper = styled.div`
  .line {
    margin-top: 25px;
    label {
      display: block;
      margin-bottom: 10px;
      color: #7d8392;
    }
  }
  .modal-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }
  button {
    flex: 1;
  }
  .font {
    font-weight: 700;
  }
  .underline {
    text-transform: none;
    text-decoration: underline;
  }
`;

const FinishTrackerDialog = ({ onClose }) => {
  const { pause, stop, state } = useTimeTracking();

  const handleConfirm = () => {
    stop();
    onClose();
  };

  const handleCancel = () => {
    pause();
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <ModalWrapper
      modalStyle={{
        width: '380px',
        paddingRight: '30px',
      }}
      noButtonBg
      onClose={handleClose}
      title="Закончить рабочий день?"
    >
      <Wrapper>
        <div className="modal-buttons">
          <ActionButton
            className="font"
            bgColor="#FF5F5F"
            onClick={handleConfirm}
            type="button"
            disabled={false}
          >
            Закончить рабочий день
          </ActionButton>
          {state?.type !== 'pause' &&
            <Button onClick={handleCancel}>
              <Typography className="underline">Поставить на паузу</Typography>
            </Button>
          }
        </div>
      </Wrapper>
    </ModalWrapper>
  );
};

FinishTrackerDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FinishTrackerDialog;
