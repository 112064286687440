import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { SettingsCardStyled } from 'views/Settings/components/SettingsNotifications/SettingsNotifications';
import { Box, Typography } from '@mui/material';
import { updateShowQualitySettings } from 'store/actions';
import AppSwitcher from 'components/AppSwitcher/AppSwitcher';

function QualityFeedbackMessage() {
  const dispatch = useDispatch();

  const showQualitySettings = useSelector(
    (state) => state.settings.showQualitySettings,
  );

  const { feedbackMessagePrefix, feedbackOptions } = useSelector(
    (state) => state.quality,
  );

  const optionsText = useMemo(() => {
    const options = feedbackOptions.map(
      (text, index) => `${index + 1} - ${text}`,
    );
    return options.join(', ');
  }, [feedbackOptions]);

  const handleToggle = useCallback(() => {
    dispatch(updateShowQualitySettings(!showQualitySettings));
  }, [dispatch, showQualitySettings]);

  return (
    <div>
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={12}
        className="header-container"
      >
        <Typography className="card-title">Сообщение обратной связи</Typography>
        <AppSwitcher isOn={showQualitySettings} onChange={handleToggle} />
      </Box>
      <SettingsCardStyled
        sx={{ marginTop: 2 }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          minHeight: 'auto',
        }}
      >
        <Typography className="card-text">
          <p>{feedbackMessagePrefix}</p>
          <p>{optionsText}</p>
        </Typography>
      </SettingsCardStyled>
    </div>
  );
}

export default QualityFeedbackMessage;
