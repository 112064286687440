import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import chatReducer from './chatReducer';
import filesReducer from './filesReducer';
import modalReducer from './modalReducer';
import settingsReducer from './settingsReducer';
import supportReducer from './supportReducer';
import searchReducer from './searchReducer';
import wsReducer from './wsReducer';
import rightPanelReducer from './rightPanelReducer';
import templateReducer from './templateReducer';
import galleryReducer from './galleryReducer';
import taskReducer from './taskReducer';
import personReducer from './personReducer';
import statReducer from './statReducer';
import calendarReducer from './calendarReducer';
import statisticsReducer from './statisticsReducer';
import postReducer from './postReducer';
import aiReducer from './aiReducer';
import workTimeReducer from './workTimeReducer';
import myScheduleReducer from './myScheduleReducer';
import templatesReducer from './templatesReducer/tempaltesReducer';
import modalTemplateReducer from './templatesReducer/modalTemplateReducer';
import providerReducer from './templatesReducer/providerReducer';



import inWorkStatReducer from './inWorkStatReducer';
import statCalendarReducer from "./statCalendarReducer";
import qualityReducer from "./qualityReducer";

const rootReducer = combineReducers({
  session: sessionReducer,
  chat: chatReducer,
  post: postReducer,
  files: filesReducer,
  modal: modalReducer,
  settings: settingsReducer,
  support: supportReducer,
  search: searchReducer,
  ws: wsReducer,
  rightPanel: rightPanelReducer,
  task: taskReducer,
  templates: templateReducer,
  gallery: galleryReducer,
  person: personReducer,
  stat: statReducer,
  calendar: calendarReducer,
  statistics: statisticsReducer,
  ai: aiReducer,
  workTime: workTimeReducer,
  mySchedule: myScheduleReducer,
  template: templatesReducer,
  modalTempalte: modalTemplateReducer,
  provider: providerReducer,
  inWorkStat: inWorkStatReducer,
  statCalendar: statCalendarReducer,
  quality: qualityReducer,
});

export default rootReducer;
