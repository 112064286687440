import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MuiRootDialog from '../../MuiRootDialog/MuiRootDialog';
import { changeRoutingScript, hideModal } from '../../../../store/actions';
import TargetAndGroupConditions from './components/TargetAndGroupConditions';
import { PERMISSIONS } from '../../../../views/Settings';

const SelectTargetConditionDialog = ({ data }) => {
  const { t } = useTranslation();

  const { scriptIndex, type } = data;
  const isDepartments = type === 'departments';

  const { settings, isAdmin } = useSelector((state) => state.session.user);
  const userPermissions = settings.permissions;
  const hasEditPermission =
    isAdmin || userPermissions.includes(PERMISSIONS.ROUTING.WRITE);

  const dispatch = useDispatch();
  const handleModalHide = () => dispatch(hideModal());

  const conditions = useSelector(
    (state) => state.settings.scripts[scriptIndex].conditions,
  );
  const { employees, departments } =
    useSelector(
      (state) => state.settings.scripts[scriptIndex].conditions.target,
    ) || null;

  const [andConditions, setAndConditions] = useState(
    isDepartments ? [...departments] : [...employees],
  );

  const { uuid: scriptUuid } = useSelector(
    (state) => state.settings.scripts[scriptIndex],
  );

  const changeScript = () => {
    const target = structuredClone(andConditions);

    dispatch(
      changeRoutingScript(
        {
          conditions: {
            ...conditions,
            target: {
              ...conditions.target,
              [isDepartments ? 'departments' : 'employees']: target,
            },
          },
        },
        scriptUuid,
      ),
    );

    handleModalHide();
  };

  return (
    <MuiRootDialog
      PaperProps={{
        style: {
          maxWidth: 984,
          width: '100%',
          backgroundColor: '#F5F5F6',
        },
      }}
      title={
        isDepartments
          ? t('routingPage.departmentSelectionCondition')
          : t('routingPage.employeeSelectionCondition')
      }
      declineAction={handleModalHide}
      isConfirmDisabled={!hasEditPermission}
      confirmAction={changeScript}
      handleClose={handleModalHide}
    >
      <TargetAndGroupConditions
        isDepartment={isDepartments}
        andConditions={andConditions}
        setAndConditions={setAndConditions}
      />
    </MuiRootDialog>
  );
};

SelectTargetConditionDialog.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SelectTargetConditionDialog;
