import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import SearchPanelFilters from './components';

import { showModal } from '../../../../store/actions/modalActions';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  position: relative;
  flex-wrap: wrap-reverse;

  .input {
    flex: 2;
    background-image: url(/img/main-search.svg);
    background-repeat: no-repeat;
    background-position: 21px 21px;
    background-color: #fff;
  }

  input {
    height: 65px;
    border: none;
    margin-left: 64px;
    outline: none;
    font-size: 15px;
  }

  .go-button {
    position: absolute;
    bottom: 7.5px;
    width: 52px;
    height: 52px;
    cursor: pointer;
    background-color: #006ae4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 3px;
    svg {
      margin: 0 auto;
      fill: #fff;
    }
  }

  .fast-icon {
    display: flex;
    flex-direction: center;
    justify-content: center;
    color: #727887;
  }

  .close-icon {
    cursor: pointer;
  }

 @media (max-width: 960px) {
  justify-content: center;

  & > :not(.input):not(.go-button) {
    display: none;
  }

  .input {
    flex: 1;
    width: 100%;
    background-size: 20px 20px; 
    background-position: 15px center; 
  }

  input {
    width: calc(100% - 40px);  
  }

  .go-button {
    position: absolute; 
    left: 0;
    margin-left: 3px;
  }
}


`;


const ConversationSearchPanel = ({
  withFilters,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [text, setText] = useState('');
  /*
  useEffect(() => {
    let timeout;
    if (!_.isEmpty(text)) {
      timeout = setTimeout(() => {
        dispatch(setSearchDialogs(text));
      }, 1500);
    } else {
      dispatch(resetSearchdDialogs());
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [text])
  */
  const handleClickButton = () => {
    dispatch(showModal('Search', { query: text }));
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleClickButton();
    }
  };

  return (
    <Wrapper>
      <div className='input'>
        <input
          type="text"
          value={text}
          placeholder={t('searchPanel.placeholder')}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handleInputKeyDown} />
      </div>
      {text ? (
        <div className="go-button" onClick={handleClickButton}>
          <SearchIcon />
        </div>
      ) : null}
      {withFilters && <SearchPanelFilters />}
    </Wrapper>
  );
};

ConversationSearchPanel.propTypes = {
  withFilters: PropTypes.bool,
};

ConversationSearchPanel.defaultProps = {
  withFilters: true,
};

export default ConversationSearchPanel;
