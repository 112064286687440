import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux'
import { fetchTemplates, 
         updateTemplate, 
        createTemplate, 
        syncData,
        deleteTemplate,
        resetError } from "../../../store/actions/templatesActions/templatesActions";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import PageWrapper from './PageWrapper.jsx';
import Table from '../sharedComponents/Table.js';
import { Edit } from '@mui/icons-material';
import { Alert, IconButton, Snackbar, Tooltip } from '@mui/material';
import TemplateModal from '../sharedComponents/TemplateModal.js';
import { Button } from 'components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


import Typography from "@mui/material/Typography";
//import DeleteIcon from "@mui/icons-material/Delete";
import SyncComponent from "./SyncComponet"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useHistory } from "react-router-dom";

  

export default function TemplatesPage() {
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [dialog, setDialog] = useState({ show: false });
  const selectedCompany = useSelector((state) => state.session.selectedCompany);
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [hoverBoxStyles, setHoverBoxStyles] = useState({ top: 0, left: 0 });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory();
  const {
    templates,
    error,
  } = useSelector(state => state.template)

  useEffect(() => {
    if (location?.pathname && selectedCompany) {
      dispatch(fetchTemplates({
        uuidCompany: selectedCompany,
        uuidProvider: location.pathname.replace("/", ""),
        pageSize: pageSize,
        page: 1,
        order: 1
      }));
    }
  }, [location, selectedCompany]);


    const handlePageChange = (newPage) => {
      setPage(newPage);
      dispatch(fetchTemplates({
        uuidCompany: selectedCompany,
        uuidProvider: location.pathname.replace("/", ""),
        pageSize: pageSize,
        page: newPage,
        order: 1
      }));
    };

    const handlePageSizeChange = (newPageSize) => {
      setPageSize(newPageSize);
    };

    const handleSync = () => {
      dispatch(syncData({ 
        uuidCompany: selectedCompany,
        uuidProvider: location.pathname.replace("/", ""),
      })).then(() => {
        dispatch(fetchTemplates({
          uuidCompany: selectedCompany,
          uuidProvider: location.pathname.replace("/", ""),
          pageSize: pageSize,
          page: page,
          order: 1
        }));
      });
    };

     const handleDelete = (uuidTemplate) => {
      console.log(page,'page')
        dispatch(deleteTemplate(
          uuidTemplate
        )).then(() => {
          dispatch(fetchTemplates({
            uuidCompany: selectedCompany,
            uuidProvider: location.pathname.replace("/", ""),
            pageSize: pageSize,
            page: page,
            order: 1
          }));
       });;
    };

    const handleOpenDialog = () => {
      setDialog({
        show: true,
        isEdit: false,
        handleSave: () => {
          dispatch(createTemplate({
            uuidProvider: location.pathname.replace("/", ""),
            uuidCompany: selectedCompany
          })).then(() => {
            dispatch(fetchTemplates({
              uuidCompany: selectedCompany,
              uuidProvider: location.pathname.replace("/", ""),
              pageSize: pageSize,
              page: page,
              order: 1
            }));
          });
        }
      });
    };    

  const handleOpenDialogForEdit = (template) => {
    setSelectedTemplate(template)
    setDialog({
      show: true,
      isEdit: true,
      handleSave: () => {
        dispatch(updateTemplate({
          uuid: template.uuidTemplate,  
          info: {
            uuidProvider: location.pathname.replace("/", ""),
            uuidCompany: selectedCompany,
          }
        })).then(() => {
          dispatch(fetchTemplates({
            uuidCompany: selectedCompany,
            uuidProvider: location.pathname.replace("/", ""),
            pageSize: pageSize,
            page: page,
            order: 1
          }));
        });
      },
    })
  }

  const handleDialogClose = () => {
    setDialog({
      show: false,
    })
  }


const handleCopyText = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    alert('Template text copied to clipboard!');
  });
};

const handleMouseEnter = (rowId, event) => {
  if (!event.currentTarget) return;
  const cell = event.currentTarget.getBoundingClientRect(); 
  setHoveredRowId(rowId);
  setHoverBoxStyles({
    top: cell.bottom - 220, 
    left: cell.left- 50  
  });
};

const handleMouseLeave = () => {
  setHoveredRowId(null);
};

  const tableColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'elementName', headerName: 'Название', flex: 1 },
    { field: 'category', headerName: 'Категория', flex: 1 },
    {
      field: 'preview',
      headerName: 'Предпросмотр',
      flex: 2,
      renderCell: (params) => {
        const isRowOpen = hoveredRowId === params.row.id;
        return <Box sx={{ display: 'flex', alignItems: 'center'}}
                    onMouseEnter={(event) => handleMouseEnter(params.row.id, event)}                
                    onMouseLeave={handleMouseLeave}
                >
              <Box         
                  sx={{
                  position: "relative",
                  cursor:"pointer",
                  gap: '4px',
                  borderRadius: '8px',
                  height: '31px',
                  minWidth: '83px',
                  border: '1px solid #ccc',
                  color: 'black',
                  fontSize: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': { backgroundColor: '#e0e0e0' },
                }}
              >
            {getLanguageCode(params.row.languageCode)}
            <Box
              sx={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: params.row.statusColor,
              }}
            />
          </Box>

          {isRowOpen &&(
         <Box
         sx={{
           position: "absolute",
           zIndex: 1000,
           width: '280px',
           borderRadius: '8px',
           border: '1px solid #ccc',
           backgroundColor: '#f9f9f9',
           padding: '16px',
           boxSizing: 'border-box',
           overflowY: 'auto', 
           maxHeight: '400px', 
         }}
         style={hoverBoxStyles} 
       >
         
         <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '8px', 
          padding: '5px 10px',
          backgroundColor: '#EBF0FF',
          borderRadius: '8px',
          marginBottom: '8px',
          maxWidth: '260px', 
          wordBreak: 'break-word', 
          overflow: 'hidden',
        }}>
      <ContentCopyIcon
        sx={{ cursor: 'pointer', color: '#006ae4', fontSize: '20px' }}
        onClick={() => handleCopyText(selectedTemplate.text)}
      />
      <Typography 
        sx={{ 
          fontSize: "12px !important",
          color: '#006ae4', 
          fontSize: '14px', 
          whiteSpace: 'normal',
        }}
      >
        Скопировать текст шаблона
      </Typography>
</Box>
  <Box
  sx={{
    width: '260px',
    maxHeight: '300px',  
    borderRadius: '8px',
    color: 'black',
    whiteSpace: 'normal',
    marginBottom: '16px',
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'flex-start',
    overflowY: 'auto', 
    wordBreak: 'break-word',
    fontSize: "13px",
    lineHeight: "2"
}}    
    >
    {params.row.data}
</Box>
       
{/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
  <Box
    sx={{
      width: '110px',
      maxWidth: '100%', 
      height: '25px',
      backgroundColor: '#EEEFF2',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
    }}
  >
    Записаться
  </Box>
  <Box
    sx={{
      width: '110px',
      maxWidth: '100%', 
      height: '25px',
      backgroundColor: '#EEEFF2',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
    }}
  >
    Соглсен
  </Box>
  <Box
    sx={{
      width: '110px',
      maxWidth: '100%', 
      height: '25px',
      backgroundColor: '#EEEFF2',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
    }}
  >
    Заинтересовало
  </Box>
</Box> */}
</Box>
)}
</Box>
    },
    },
    {
      field: 'action',
      headerName: 'Изменить',
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip 
            title={params.row.statusColor === "#eaea6a" ? "Disabled" : "Edit"}
            >
              <span> 
                <IconButton
                  onClick={params.row.statusColor === "#eaea6a" ? undefined : () => handleOpenDialogForEdit(params.row)}
                  disabled={params.row.statusColor === "#eaea6a"}
                  sx={{
                    cursor: params.row.statusColor === "#eaea6a" ? 'not-allowed' : 'pointer',
                    pointerEvents: params.row.statusColor === "#eaea6a" ? 'none' : 'auto',
                  }}
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
          <Tooltip
            title={params.row.statusColor === "#eaea6a" ? "Disabled" : "Delete"}>
             <IconButton   
                onClick={params.row.statusColor === "#eaea6a" ? undefined : () => handleDelete(params.row.uuidTemplate)}
                disabled={params.row.statusColor === "#eaea6a"}
                sx={{
                  cursor: params.row.statusColor === "#eaea6a" ? 'not-allowed' : 'pointer',
                  pointerEvents: params.row.statusColor === "#eaea6a" ? 'none' : 'auto',
                }}
                >
                <MoreHorizIcon sx={{ fontSize: '20px' }} />
             </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const getLanguageCode = (language) => {
    switch (language) {
      case 'ru':
        return 'Русский'
      case 'en':
        return 'English'
      default:
        return 'none'
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'green'
      case 'FAILED':
        return '#e12f2f'
      case 'PENDING':
        return '#eaea6a'
      default:
        return 'gray'
    }
  }
  
  const tableRows = Array.isArray(templates?.data?.rows)
  ? templates.data.rows.map((template, index) => ({
      id: index + 1,
      buttons: template.template?.containerMeta?.buttons?.length > 0 ? template.template.containerMeta?.buttons : [],
      example: template.template.meta
      ? JSON.parse(template.template.meta).example 
      : JSON.parse(template.template.template.meta).example,
      templateType: template.template.templateType ? template.template.templateType : template.template.template.templateType ,
      elementName: template.template.elementName ? template.template.elementName : template.template.template.elementName ,
      category: template.template.category ? template.template.category : template.template.template.category,
      data: template.template.data ? template.template.data : template.template.template.data,
      uuidTemplate: template.uuidTemplate,
      statusColor: template.template?.template?.status ?  getStatusColor(template.template.template.status) : getStatusColor(template.template.status),
      languageCode: template.template.languageCode ? template.template.languageCode : template.template.template.languageCode
    }))
  : [];

  return (
    <>
      <Box 
        onClick={() => history.push(`/templates`)}
        sx={{ 
            position: "relative",
            top: "39px",
            left: "60px",
            display: "flex", 
            alignItems: "center", 
            color: "primary.main", 
            cursor: "pointer", 
            mb: 2,
            gap: 1 
          }}
        >
          <ArrowBackIcon />
          <span>Назад</span>
        </Box>
    <PageWrapper
      title={"Шаблоны"}
      actions={ <div style={{ display: "flex", gap: "10px" }}>
       
      <Button
        style={{ borderRadius: "20px", backgroundColor: "#006ae4",height: "40px" }}
        variant="contained"
        onClick={handleOpenDialog}
      >
        Создать шаблон
      </Button>
      <Button
        style={{
          borderRadius: "8px",
          backgroundColor: "#ece9e9",
          border: "1px solid #ece9e9",
          color: "gray",
          height: "40px",
        }}
        onClick={() => window.open("https://yandex.ru", "_blank")}
      >
        Инструкция
      </Button>
        <SyncComponent handleSync={handleSync}/>
     
    </div>}>     
      <Parent>
       {templates?.data && <Table
          rowCount={templates?.data.total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          columns={tableColumns}
          rows={tableRows} /> }
      </Parent>
     
      <Snackbar onClose={() => dispatch(resetError())} open={error} autoHideDuration={1000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="error">
          Не удалось выполнить запрос
        </Alert>
      </Snackbar>     
    </PageWrapper>
    <TemplateModal
        sx={{ zIndex: 1500 }} 
        template={selectedTemplate}
        isEdit={dialog.isEdit}
        isOpen={dialog.show}
        handleSave={dialog.handleSave}
        handleClose={handleDialogClose} />
    </>
  );
}

const Parent = styled.div`
  height: 100%;
  width: 100%;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e1dede;
    border-radius: 5px;
  }
`