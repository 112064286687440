import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';
import { Spinner } from './../../../components';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  > div {
    max-width: 50%;
  }
  @media (max-width: 960px) {
      > div {
      max-width: 100%;
    }
  }
  .search-persone-wrapper {
    color: #263238;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: rgb(235, 238, 243);
    display: flex;
    font-size: 14px;
    margin-bottom: 16px;
    cursor: pointer;

    &.without-dialog {
      cursor: no-drop;
    }

    .search-persone-avatar {
      margin-right: 16px;
      width: 50px;
      height: 50px;
      display: flex;
      overflow: hidden;
      position: relative;
      font-size: 1.25rem;
      align-items: center;
      flex-shrink: 0;
      line-height: 1;
      user-select: none;
      border-radius: 50%;
      justify-content: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(/img/avatar-default.jpg);
      > div {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .search-persone-name {
      font-size: 16px;
      font-weight: 900;
      text-overflow: ellipsis;
    }
      .content-person {
        display: flex;
        flex-direction: row;
        align-items: center;

      }
        .provider-name {
            margin-top: 4px;
        }

    .search-persone-messengers {
      display: flex;
      margin-top: 5px;
      > div {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 10px;
        &.telegram {
          background-color: #0088cc;
          background-image: url(/img/messengers/badges/telegram.svg);
        }
        &.whatsapp {
          background-color: #25d366;
          background-image: url(/img/messengers/badges/whatsapp.svg);
        }
        &.facebook {
          background-color: #3b5998;
          background-image: url(/img/messengers/badges/facebook.svg);
        }
        &.vk {
          background-color: #45668e;
          background-image: url(/img/messengers/badges/vk.svg);
        }
        &.viber {
          background-color: #fff;
          background-image: url(/img/messengers/badges/viber.svg);
          background-size: cover;
        }
      }
    }
  }
`;

const PersoneItem = React.memo(({ persone, handleOpenPersone, providerName }) => {

  const {
    info: personeInfo,
    dialog: lastDialog,
    connections: personeConnections,
  } = persone;

 

  const openPersone = () => {
    if (lastDialog) {
      handleOpenPersone(persone);
    }
  };

  const avatar = personeInfo.find((i) => i.key === 'avatar');
  const name = personeInfo.find((i) => i.key === 'name');

  let wrapperClassName = 'search-persone-wrapper';

  if (!lastDialog) {
    wrapperClassName += ' without-dialog';
  }

  const getLastDate = () => {
    let lastDate = lastDialog.date.lastMessage;
    if (
      _.get(lastDialog, 'additional.lastMessageBot') &&
      lastDialog.additional.lastMessageBot > lastDialog.date.lastMessage
    ) {
      lastDate = lastDialog.additional.lastMessageBot;
    }
    return moment(lastDate).fromNow();
  };

  return (
    <div key={persone.uuid} onClick={openPersone}>
      <div className={wrapperClassName}>
        <div className="search-persone-avatar">
          <div
            style={{
              backgroundImage: `url(${
                avatar ? avatar.val : '/img/avatar-default.jpg'
              })`,
            }}
          />
        </div>
        <div>
          {name ? <div className="search-persone-name">{name.val}</div> : null}
          <div className="search-persone-time">
            {lastDialog
              ? `последнее сообщение ${getLastDate()}`
              : 'Нет ни одного диалога'}
          </div>
          {personeConnections && personeConnections.length ? (
            <div className='content-person'>
             <div className="search-persone-messengers">
              {personeConnections.map((m, index) => (
                <>
                 <div className={m.type} key={index} />
                  <span className={m.name} />               
                </>
               
              ))}
             
            </div>
              <div className='provider-name'>{providerName}</div>
            </div>
           
          ) : null}
         
        </div>
      </div>
    </div>
  );
});

PersoneItem.displayName = 'PersoneItem';

PersoneItem.propTypes = {
  persone: PropTypes.object.isRequired,
  handleOpenPersone: PropTypes.func.isRequired,
  providerName: PropTypes.string
};




const PersonesTab = ({ persones, handleOpenPersone, fetchNextPage, loadingMin }) => {

  const providers = useSelector((state) => state.chat.companyProviders);

  // Helper function to get provider name
  const getProviderName = (uuidConnection) => {
    const provider = providers.find((provider) => provider.uuid === uuidConnection);
    return provider ? provider.name : 'provider deleted';
  };
  
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
     if (scrollTop + clientHeight >= scrollHeight - 10) {
      fetchNextPage();
    }
  };

  return (
    <div className="persones-tab" onScroll={handleScroll} style={{ maxHeight: '70vh', overflowY: 'auto' }}>
      <Wrapper>
        {persones.length > 0 && persones.map((persone, index) => (
          <PersoneItem
            key={index}
            persone={persone}
            handleOpenPersone={handleOpenPersone}
            providerName={getProviderName(persone.ids.uuidConnection)}
          />
        ))}
        {loadingMin && (
          <div className="loader" style={{ textAlign: 'center', padding: '10px' }}>
            <Spinner show />
          </div>
        )}
      </Wrapper>
    </div>
  );
  
};


PersonesTab.displayName = 'PersonesTab';

PersonesTab.propTypes = {
  persones: PropTypes.array.isRequired,
  handleOpenPersone: PropTypes.func.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  loadingMin: PropTypes.bool.isRequired,
};

export default PersonesTab;







