import {
  SET_SELECTED_COMPANY,
  SET_SELECTED_EMPLOYEES,
  SET_SELECTED_PROVIDER,
  SET_SELECTED_DIALOG_TYPE,
  SET_COMPLETE_STAT,
  SET_IN_WORK_STAT,
} from 'store/actions/inWorkStatActions';

const initialState = {
  // filters
  selectedCompany: null,
  selectedProviders: null,
  selectedEmployees: [],
  selectedDialogType: "all",
  // stat
  completeStat: {
    labels: [],
    datasets: []
  },
  inWorkStat: {
    labels: [],
    datasets: []
  },
};

const inWorkStatReducer = (state = initialState, action) => {
  switch (action.type) {
    // filters
    case SET_SELECTED_COMPANY:
      return { ...state, selectedCompany: action.payload };
    case SET_SELECTED_EMPLOYEES:
      return { ...state, selectedEmployees: action.payload };
    case SET_SELECTED_PROVIDER:
      return { ...state, selectedProviders: action.payload };
    case SET_SELECTED_DIALOG_TYPE:
      return { ...state, selectedDialogType: action.payload };
    // stat
    case SET_COMPLETE_STAT:
      return { ...state, completeStat: action.payload };
    case SET_IN_WORK_STAT:
      return { ...state, inWorkStat: action.payload };
    default:
      return state;
  }
};

export default inWorkStatReducer;
