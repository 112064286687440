import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

// import { Typography } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { getStat } from 'store/actions/statisticsActions';
import { workloadTranslate } from 'utils/workloadTranslateTotal';
import { Page } from 'components';
import StatisticsLoading from './components/StatisticsLoading';
// import TagTable from './components/TagTable';
// import SearchBar from './components/SearchBar';
import Filters from './components/Filters';
// import StatFilters from './components/StatFilters';
import StatNav from './components/StatNav';
import LineChart from './components/LineChart';
import TableChart from './components/TableChart';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
}));

export default function StatPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const statisticsData = useSelector((state) => state.statistics.statistics);
  const initSelectedCompany = useSelector(
    (state) => state.session.selectedCompany,
  );
  const selectedCompany =
    useSelector((state) => state.statistics.selectedCompany) ||
    initSelectedCompany;
  const selectedProviders = useSelector(
    (state) => state.statistics.selectedProviders,
  );
  const selectedDepartment = useSelector(
    (state) => state.statistics.selectedDepartment,
  );
  const statisticsIsSubscribed = useSelector(
    (state) => state.statistics.isSubscribed,
  );
  const calendarDateRange = useSelector((state) => state.calendar.mainDate);
  const newQuestionsData = {
    total: statisticsData?.others?.total?.new
      ? statisticsData.others.total.new
      : 0,
    lineChartCallbacks: {},
    labels: statisticsData?.others?.x
      ? statisticsData.others.x.map((l) => l.label)
      : [],
    datasets: [
      {
        label: t('graphInfo.graphTitle1'),
        data: statisticsData?.others?.x
          ? statisticsData.others.x.map((i) => i.data.new)
          : [],
        backgroundColor: '#006AE4',
        borderColor: '#006AE4',
        borderWidth: 1,
      },
    ],
  };
  const resolvedIssuesData = {
    total: statisticsData?.others?.total?.closed
      ? statisticsData.others.total.closed
      : 0,
    lineChartCallbacks: {},
    labels: statisticsData?.others?.x
      ? statisticsData.others.x.map((i) => i.label)
      : [],
    datasets: [
      {
        label: t('graphInfo.graphTitle2'),
        data: statisticsData?.others?.x
          ? statisticsData.others.x.map((i) => i.data.closed)
          : [],
        backgroundColor: '#00CA2C',
        borderColor: '#00CA2C',
        borderWidth: 1,
      },
    ],
  };
  const speedOfResponseData = {
    total: moment
      .utc(
        (statisticsData?.others?.total?.responseSpeed
          ? Math.round(statisticsData.others.total.responseSpeed)
          : 0) * 1000,
      )
      .format('HH:mm:ss'),
    lineChartCallbacks: {
      tooltipCallback: {
        label: (context) => {
          const { formattedValue: value } = context;
          const modifiedValue = moment
            .utc(Math.round(+value.match(/-?\d+(\.\d+)?/g).join('')) * 1000)
            .format('HH:mm:ss');
          return `${context.dataset.label}: ${modifiedValue}`;
        },
      },
      yTicksCallback: {
        callback: (value) =>
          `${moment.utc(Math.round(+value) * 1000).format('HH:mm:ss')}`,
      },
    },
    labels: statisticsData?.others?.x
      ? statisticsData.others.x.map((i) => i.label)
      : [],
    datasets: [
      {
        data: statisticsData?.others?.x
          ? statisticsData.others.x.map((i) => i.data.responseSpeed)
          : [],
        label: t('graphInfo.graphTitle4'),
        backgroundColor: '#8900CA',
        borderColor: '#8900CA',
        borderWidth: 1,
      },
    ],
  };

  const numberOfMessagesData = {
    total: statisticsData?.others?.total?.size
      ? statisticsData.others.total.size.toFixed(1)
      : 0,
    lineChartCallbacks: {},
    labels: statisticsData?.others?.x
      ? statisticsData.others.x.map((i) => i.label)
      : [],
    datasets: [
      {
        label: t('graphInfo.graphTitle5'),
        data: statisticsData?.others?.x
          ? statisticsData.others.x.map((i) => i.data.size)
          : [],
        backgroundColor: '#EF40DE',
        borderColor: '#EF40DE',
        borderWidth: 1,
      },
    ],
  };

  // useEffect(() => {
  //   filteredByDate();
  // }, [JSON.stringify(dialogs)]);
  function transformDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  useEffect(() => {
    if (calendarDateRange && statisticsIsSubscribed) {
      const modifiedDateRange = {
        from: Array.isArray(calendarDateRange.dateBefore)
          ? transformDate(calendarDateRange.dateBefore[0])
          : transformDate(calendarDateRange.dateBefore),
        to: transformDate(calendarDateRange.dateAfter),
      };
      console.log(modifiedDateRange);
      getStat({
        uuidCompany: selectedCompany,
        dateRange: modifiedDateRange,
        uuidConnection: selectedProviders,
        uuidAssigns: selectedDepartment.assignee,
      })(dispatch);
    }
  }, [
    calendarDateRange,
    selectedCompany,
    statisticsIsSubscribed,
    selectedProviders,
    selectedDepartment,
  ]);

  const workloadTotal = workloadTranslate(
    statisticsData?.workload?.total,
    statisticsData?.workload?.key,
    t,
  );
  return (
    <Page
      className={clsx({ [classes.root]: true })}
      style={{ flexDirection: 'column' }}
      title="IntellectDialog | Stat"
    >
      <StatNav />
      <Wrap>
        {/* <Filters selectedCompany={selectedCompany} dateRange={dateRange} /> */}
        <StatisticsLoading />
        <Filters selectedCompany={selectedCompany} />
        <StyledStat>
          <GraphWrap>
            <LineChart
              title={t('generalPage.LineChart1.title')}
              statisticQuantity1={newQuestionsData.total}
              statisticDesc1={t('generalPage.LineChart1.desc1')}
              // statisticQuantity2="0"
              // statisticDesc2={t('generalPage.LineChart1.desc2')}
              desc={t('generalPage.LineChart1.desc', {
                time: `${
                  statisticsData?.others?.key
                    ? t(`time.${statisticsData.others.key}`)
                    : ''
                }`,
              })}
              // question={t('graphInfo.graphQuestion1')}
              // question2={t('graphInfo.graphQuestion1dot2')}
              footer
              data={newQuestionsData}
              propCallbacks={newQuestionsData.lineChartCallbacks}
            />
          </GraphWrap>
          <GraphWrap>
            <LineChart
              title={t('generalPage.LineChart2.title')}
              statisticQuantity1={resolvedIssuesData.total}
              statisticDesc1={t('generalPage.LineChart2.desc1')}
              // statisticQuantity2="30"
              // statisticDesc2={t('generalPage.LineChart2.desc2')}
              desc={t('generalPage.LineChart2.desc', {
                time: `${
                  statisticsData?.others?.key
                    ? t(`time.${statisticsData.others.key}`)
                    : ''
                }`,
              })}
              question={t('graphInfo.graphQuestion2')}
              question2={t('graphInfo.graphQuestion2dot2')}
              footer
              data={resolvedIssuesData}
              propCallbacks={resolvedIssuesData.lineChartCallbacks}
            />
          </GraphWrap>
          <GraphWrap>
            <TableChart
              title={t('generalPage.LineChart3.title', {
                time: `${
                  statisticsData?.others?.key
                    ? t(`time.${statisticsData.others.key}`)
                    : ''
                }`,
              })}
              statisticQuantity1={workloadTotal}
              statisticQuantity2=""
              statisticDesc1={t('generalPage.LineChart3.desc1')}
              statisticDesc2=""
              desc={t('generalPage.LineChart3.desc')}
              // question={t('graphInfo.graphQuestion3')}
              data={statisticsData?.workload ? statisticsData.workload : []}
            />
          </GraphWrap>
          <GraphWrap>
            <LineChart
              title={t('generalPage.LineChart4.title', {
                time: `${
                  statisticsData?.others?.key
                    ? t(`time.${statisticsData.others.key}`)
                    : ''
                }`,
              })}
              statisticQuantity1={speedOfResponseData.total}
              statisticQuantity2=""
              statisticDesc1={t('generalPage.LineChart4.desc1')}
              statisticDesc2=""
              desc={t('generalPage.LineChart4.desc')}
              question={t('graphInfo.graphQuestion4')}
              question2=""
              footer={false}
              data={speedOfResponseData}
              propCallbacks={speedOfResponseData.lineChartCallbacks}
            />
          </GraphWrap>
          <GraphWrap>
            <LineChart
              title={t('generalPage.LineChart5.title')}
              statisticQuantity1={numberOfMessagesData.total}
              statisticQuantity2=""
              statisticDesc1={t('generalPage.LineChart5.desc1')}
              statisticDesc2=""
              desc={t('generalPage.LineChart5.desc', {
                time: `${
                  statisticsData?.others?.key
                    ? t(`time.${statisticsData.others.key}`)
                    : ''
                }`,
              })}
              question={t('graphInfo.graphQuestion5')}
              question2=""
              footer={false}
              data={numberOfMessagesData}
              propCallbacks={numberOfMessagesData.lineChartCallbacks}
            />
          </GraphWrap>
        </StyledStat>
      </Wrap>
    </Page>
  );
}

const StyledStat = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1055px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  grid-column-gap: 50px;
  grid-row-gap: 32px;
  margin-bottom: 100px;
  padding-bottom: 60px;
`;

const GraphWrap = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1515px) {
    .lineChart-wrapper {
      min-height: 189px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  h5.graph-title {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 25px;
    color: #202020;
  }
  .statistic-quantity {
    font-weight: 400;
    font-size: 28px;
    line-height: 39px;
    color: #454545;
  }

  .statistic-desc {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #7f7f7f;
  }

  .statistic-question-mark {
    min-width: 12px;
    height: 12px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    margin-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .statistic-question-mark:before {
    content: '\\003F';
    font-size: 8px;
  }
  .statistic-question {
    top: 140%;
    color: #fff;
    width: 200px;
    text-align: center;
    padding: 7px 15px;
    position: absolute;
    display: none;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.80);
  }
  .statistic-question2 {
    left: 7%;
  }
  .statistic-question:after {
    content: '';
    position: absolute;
    bottom: calc(100%);
    left: calc(50% - 5.5px);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 9px solid rgba(0,0,0,0.5);
}
  }
  .statistic-question-mark:hover .statistic-question {
    display: block;
  }

  .graph-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #454545;
    margin-bottom: 12px;
  }

  .lineChart-wrapper {
  }
`;

export const Wrap = styled.div`
  width: 100%;
  padding: 32px 40px 0 40px;
  background-color: #fff;
  .title {
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 34px;
    line-height: 47px;
  }
  .section {
    padding-bottom: 32px;
  }
`;
