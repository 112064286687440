import * as actionTypes from '../actions';

const initialState = null;

const workTimeReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case actionTypes.SET_WORKING_TIME: {
      if (!action.payload) {
        return initialState;
      }
      return {...state, ...action.payload };
    }
    case actionTypes.RESET_WORKING_TIME: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default workTimeReducer;
