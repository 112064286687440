import { createReq } from '../../../components/tamplate/api';
import { HOST } from '../../../config';
import axios from "axios";


export const FETCH_TEMPLATES_REQUEST = 'template/FETCH_TEMPLATES_REQUEST';
export const FETCH_TEMPLATES_SUCCESS = 'template/FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAILURE = 'template/FETCH_TEMPLATES_FAILURE';

export const UPDATE_TEMPLATE_REQUEST = 'template/UPDATE_TEMPLATE_REQUEST';
export const UPDATE_TEMPLATE_SUCCESS = 'template/UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_FAILURE = 'template/UPDATE_TEMPLATE_FAILURE';

export const CREATE_TEMPLATE_REQUEST = 'template/CREATE_TEMPLATE_REQUEST';
export const CREATE_TEMPLATE_SUCCESS = 'template/CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_FAILURE = 'template/CREATE_TEMPLATE_FAILURE';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_TEMPLATE  = 'template/SET_TEMPLATE'

export const SYNC_DATA_REQUEST = 'template/SYNC_DATA_REQUEST';
export const SYNC_DATA_SUCCESS = 'template/SYNC_DATA_SUCCESS';
export const SYNC_DATA_FAILURE = 'template/SYNC_DATA_FAILURE';

export const DELETE_TEMPLATE_REQUEST = 'template/DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'template/DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'template/DELETE_TEMPLATE_FAILURE';

export const UPLOAD_TEMPLATE_SUCCESS = 'template/UPLOAD_TEMPLATE_SUCCESS';
export const UPLOAD_TEMPLATE_FAILURE = 'template/UPLOAD_TEMPLATE_FAILURE';



export const sendUploadedFile = ({ uuidProvider, file }) => {
  return async (dispatch, getState) => {
    const tokens = getState().session.tokenData;
    const formData = new FormData();
    formData.append("file", file);

    try {
      const url = `https://gate.intellectdialog.com/template/server/${uuidProvider}/media`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${tokens.access.token}`,
        },
      });
       
      dispatch({ type: UPLOAD_TEMPLATE_SUCCESS, payload: response.data.data.handleId });
    } catch (error) {
      dispatch({ type: UPLOAD_TEMPLATE_FAILURE, error: error.message });
      throw error; 
    }
  };
};



export const deleteTemplate = (uuidTemplate) => {
  return async (dispatch, getState) => {
    dispatch({ type: DELETE_TEMPLATE_REQUEST });
    const tokens = getState().session.tokenData;
    try {
      const url = `${HOST}/template/server/${uuidTemplate}`;
      const response = await createReq(
        {
          method: 'delete',
          url,
        },
        tokens
      );
      dispatch({ type: DELETE_TEMPLATE_SUCCESS, payload: uuidTemplate });
    } catch (error) {
      dispatch({ type: DELETE_TEMPLATE_FAILURE, error: error.message });
    }
  };
};



export const syncData = (info) => {
  return async (dispatch, getState) => {
    dispatch({ type: SYNC_DATA_REQUEST });
    const tokens = getState().session.tokenData;
    try {
      const url = `${HOST}/template/server/${info.uuidCompany}/sync/${info.uuidProvider}`;
      const response = await createReq(
        {
          method: 'post',
          url,
        },
        tokens
      );
    } catch (error) {
     dispatch({ type: SYNC_DATA_FAILURE, error: error.message });
    }
  };
};

export const resetError = () => ({
  type: RESET_ERROR,
});


export const setTemplate = (template) => ({
  type: SET_TEMPLATE,
  payload: template,
});

export const fetchTemplates = ({uuidCompany, uuidProvider, pageSize, page, order}) => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_TEMPLATES_REQUEST });
    const tokens = getState().session.tokenData; 
    try {
      const queryParams = new URLSearchParams({
        ...(pageSize && { pageSize }),
        ...(order && { order }),
        ...(page !== undefined && { page }),
      }).toString();
      
      const url = `${HOST}/template/server/${uuidCompany}/templates/${uuidProvider}?${queryParams}`; 
      const response = await createReq({
        method: 'get',
        url,
      }, tokens);
      dispatch({ type: FETCH_TEMPLATES_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_TEMPLATES_FAILURE, error: error.message });
    }
  };
};



export const createTemplate = (info, updatedTemplate) => {
  return async (dispatch, getState) => {
    dispatch({ type: CREATE_TEMPLATE_REQUEST });
    const template = updatedTemplate ? updatedTemplate : getState().template.template;
    const tokens = getState().session.tokenData; 
    try {
      const url = `${HOST}/template/server/${info.uuidCompany}/add/${info.uuidProvider}`
      const response = await createReq({
        method: 'post',
        url,
        body: template,  
      }, tokens);
      dispatch({ type: CREATE_TEMPLATE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: CREATE_TEMPLATE_FAILURE, error: error.message });
    }
  };
  
};

export const updateTemplate = ({ uuid, info }) => {
  return async (dispatch, getState) => {
    const { data, id, statusColor, uuidTemplate, ...updatedTemplate } = getState().template.template.template; 
    await dispatch(deleteTemplate( uuid ));
    dispatch({
      type: CREATE_TEMPLATE_REQUEST,
    });
    dispatch(createTemplate(info, { template: updatedTemplate }))  
  };
};


