import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select, MenuItem, Checkbox, ListItemText, Avatar } from '@material-ui/core';
import { setSelectedEmployees } from 'store/actions/statisticsActions';
import { getDepartmentUserName, stringToColor } from '../utils';
import { getInitials } from 'utils/userHelper';
import { useStyles } from 'components/Filter/styles';

export default function DepartmentSelect() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { selectedCompany, selectedEmployees } = useSelector((state) => state.statistics);
  const { departments = {} } = useSelector((state) => state.session);

  const [selectedList, setSelectedList] = useState(selectedEmployees || []);

  const companyDepartments = useMemo(() => departments[selectedCompany] || {}, [selectedCompany, departments]);

  const allEmployees = useMemo(() => {
    return Object.values(companyDepartments).flatMap(dept => dept.users || []);
  }, [companyDepartments]);

  const isAllSelected = selectedList.length === allEmployees.length && allEmployees.length > 0;

  // Синхронизация с глобальным store при изменении selectedEmployees
  useEffect(() => {
    setSelectedList(selectedEmployees);
  }, [selectedEmployees]);

  const handleConfirm = useCallback(() => {
    dispatch(setSelectedEmployees(selectedList));
  }, [dispatch, selectedList]);

  const handleUserSelect = useCallback((userUuid) => {
    setSelectedList((prevList) =>
      prevList.includes(userUuid) ? prevList.filter(id => id !== userUuid) : [...prevList, userUuid]
    );
  }, []);

  const handleDepartmentSelect = useCallback((departmentEmployees) => {
    setSelectedList((prevList) => {
      const departmentEmployeeUuidList = departmentEmployees.map(user => user.uuid);
      const isDeptSelected = departmentEmployeeUuidList.every(id => prevList.includes(id));

      return isDeptSelected
        ? prevList.filter(id => !departmentEmployeeUuidList.includes(id))
        : [...prevList, ...departmentEmployeeUuidList.filter(id => !prevList.includes(id))];
    });
  }, []);

  const handleSelectAll = useCallback(() => {
    setSelectedList(isAllSelected ? [] : allEmployees.map(user => user.uuid));
  }, [isAllSelected, allEmployees]);

  return (
    <Select
      className="select"
      multiple
      value={selectedList} // Отображаем актуальные выбранные элементы
      displayEmpty
      renderValue={(selected) => selected.length === 0 ? "Выберите сотрудников" : `Сотрудники: ${selected.length}`}
      style={{ width: "100%" }}
    >
      <div style={{ display: "flex", flexDirection: "column", maxHeight: "600px" }}>
        
        {/* Шапка */}
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: 2,
            background: "white",
            borderBottom: "1px solid #ddd",
          }}
        >
          <MenuItem onClick={handleSelectAll} style={{ width: "100%" }}>
            <Checkbox checked={isAllSelected} />
            <ListItemText primary={"Выбрать всех"} />
          </MenuItem>
        </div>

        {/* Прокручиваемая область */}
        <div style={{ flex: 1, overflowY: "auto" }}>
          {Object.entries(companyDepartments).map(([deptId, deptData]) => {
            const users = deptData.users || [];
            const isDeptSelected = users.length > 0 && users.every(user => selectedList.includes(user.uuid));

            return (
              <div key={deptId}>
                {/* Чекбокс для выбора всего отдела */}
                <MenuItem onClick={() => handleDepartmentSelect(users)}>
                  <ListItemText primary={deptData.name} />
                  <Checkbox checked={isDeptSelected} />
                </MenuItem>
                {/* Чекбоксы для сотрудников отдела */}
                {users.map(user => (
                  <MenuItem key={user.uuid} onClick={() => handleUserSelect(user.uuid)} style={{ paddingLeft: 30 }}>
                    <Avatar
                      className={classes.avatar}
                      style={{
                        width: 30, height: 30,
                        backgroundColor: stringToColor(getDepartmentUserName(user))
                      }}>
                      {getInitials(getDepartmentUserName(user))}
                    </Avatar>
                    <ListItemText primary={`${user.name} ${user.surname} ${user.lastname}`.trim()} />
                    <Checkbox checked={selectedList.includes(user.uuid)} />
                  </MenuItem>
                ))}
              </div>
            );
          })}
        </div>

        {/* Футер */}
        <div
          style={{
            position: "sticky",
            bottom: 0,
            zIndex: 2,
            background: "white",
            borderTop: "1px solid #ddd",
            padding: "8px 5px 0px 5px",
            display: "flex",
            gap: "8px",
          }}
        >
          <Button style={{ flex: 1 }} color="secondary" variant="outlined" onClick={() => setSelectedList([])}>
            Очистить
          </Button>
          <Button style={{ flex: 1 }} onClick={handleConfirm} color="secondary" variant="contained">
            Применить
          </Button>
        </div>
      </div>
    </Select>
  );
}
