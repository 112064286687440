import { useCallback, useMemo } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { getDepartmentUserName, stringToColor } from "views/Stat/utils";
import { GraphWrap } from "views/Stat/style";
import { FlexDiv } from "views/Stat/components/FlexDiv";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
);

function WorkTotalStatByDateRange({ employees }) {
	const { workTotalStat } = useSelector((state) => state.statistics);

	const data = useMemo(() => {
		const datasets = [];
		const totalHours = Array(workTotalStat.labels.length).fill(0);
		let count = 0;
		let totalWorkTime = 0;

		workTotalStat.datasets.forEach((dataset) => {
			const foundEmployee = employees.find((e) => e.uuid === dataset.label);
			if (foundEmployee) {
				const userName = getDepartmentUserName(foundEmployee);
				datasets.push({
					...dataset,
					backgroundColor: stringToColor(userName),
					borderColor: stringToColor(userName),
					label: userName,
					borderWidth: 1,
					fill: false,
				});

				dataset.data.forEach((value, index) => {
					totalHours[index] += value;
					totalWorkTime += value;
				});
				count++;
			}
		});

		const averageDataset = {
			label: "Среднее время работы",
			data: totalHours.map(value => (count > 0 ? (value / count) : 0)).map((value) => value || null),
			backgroundColor: stringToColor("Среднее время работы"),
			borderColor: stringToColor("Среднее время работы"),
			borderWidth: 1,
			fill: false,
		};

		let averageWorkTime = 0;
		
		if (totalHours.length > 0) {
			averageWorkTime = totalHours.reduce((sum, value) => sum + value, 0) / totalHours.length;
		}

		const formattedLabels = workTotalStat.labels.map(date => moment(date, "YYYY-MM-DD").format("DD.MM.YYYY"));

		return {
			labels: formattedLabels,
			datasets,
			totalWorkTime,
			averageDataset,
			averageWorkTime,
		};
	}, [workTotalStat, employees]);

	const options = {
		scales: {
			y: {
				suggestedMin: 0,
				suggestedMax: 24,
				ticks: {
					stepSize: 1,
					callback: (value) => `${value} ч`,
				},
			},
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: function (context) {
						const value = context.raw || 0;
						return formatTime(value);
					}
				}
			}
		}
	};

	const formatTime = useCallback((hours) => {
    let h = Math.floor(hours);
    let m = Math.round((hours - h) * 60);
    
    if (m === 60) {
        h += 1;
        m = 0;
    }

    return m > 0 ? `${h} ч ${m} мин` : `${h} ч`;
	}, []);

	return (
		<>
			<GraphWrap>
				<div className="lineChart-wrapper" style={{ minHeight: 'auto' }}>
					<h5 className="graph-title">Время работы по часам</h5>
					<FlexDiv
						style={{ width: 'calc(50%-5px)' }}
						direction="column"
						rGap="2px"
						margin="0 0 24px 0"
					>
						<span className="statistic-quantity">
							{formatTime(data.totalWorkTime)}
						</span>
						<span className="statistic-desc">
							Общее время работы за данный период
						</span>
					</FlexDiv>
				</div>
				<div style={{ display: 'flex', border: '1px solid rgba(0, 0, 0, 0.16)', borderRadius: '4px', padding: '12px' }}>
					<Line data={{ labels: data.labels, datasets: data.datasets }} options={options} />
				</div>
			</GraphWrap>
			<GraphWrap>
				<div className="lineChart-wrapper" style={{ minHeight: 'auto' }}>
					<h5 className="graph-title">Среднее время работы</h5>
					<FlexDiv
						style={{ width: 'calc(50%-5px)' }}
						direction="column"
						rGap="2px"
						margin="0 0 24px 0"
					>
						<span className="statistic-quantity">
							{formatTime(data.averageWorkTime)}
						</span>
						<span className="statistic-desc">
							Среднее время работы за данный период
						</span>
					</FlexDiv>
				</div>
				<div style={{ display: 'flex', border: '1px solid rgba(0, 0, 0, 0.16)', borderRadius: '4px', padding: '12px' }}>
					<Line data={{ labels: data.labels, datasets: [data.averageDataset] }} options={options} />
				</div>
			</GraphWrap>
		</>
	);
}

WorkTotalStatByDateRange.propTypes = {
	employees: PropTypes.array.isRequired,
};

export default WorkTotalStatByDateRange;