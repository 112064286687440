/* eslint-disable react/jsx-key */
import {
  Avatar,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Typography,
  IconButton,
  colors,
} from '@material-ui/core';
import CodeIcon from '@material-ui/icons/Code';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  Delete as DeletedIcon,
  DoneOutline as DeliveredIcon,
  Error as ErrorIcon,
  DoneAll as ReadedIcon,
  Done as SentIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { DebugView, MarkdownElement } from 'components';
import { fetchGetSupportById } from 'store/actions/supportActions';
import { getAvatar, getName, getPersone } from 'utils/personeHelper';
import * as galleryActions from 'store/actions/galleryActions';
import * as chatActions from 'store/actions/chatActions';
import { handleOpenRightPanel, setAiMessage, showModal } from 'store/actions';
import { PROVIDERS_CAN_REPLY } from 'config';
import { getMsgOriginalIDForReply } from 'utils/getMsgOriginalID';
import { getMenuItems } from 'layouts/Dashboard/components/ToolsBar/ToolsBar';

import { isToday } from 'utils/dateHelper';
import AudioContent from './components/Audio';
import DocumentContent from './components/Document';
import StickerContent from './components/Sticker';
import VideoContent from './components/Video';
import PictureContent from './components/Picture';
import MapContent from './components/Map';
import PictureGroup from './components/PictureGroup/PictureGroup';
import genAvatar from '../../../../../../utils/genAvatar';

import { ReactComponent as CheckBoxOff } from '../../../../../../assets/img/checkbox_off.svg';
import { ReactComponent as CheckBoxOn } from '../../../../../../assets/img/checkbox_on.svg';
import ConversationMenu from './components/ConversationMenu';

import * as selector from 'store/selectors';

export const useMsgStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  authUser: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& $body': {
      backgroundColor: '#d2deef',
      color: '#000',
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  selectCheckBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 26px',
    paddingTop: '15px',
    cursor: 'pointer',
  },
  selectCheckBoxInput: {
    cursor: 'pointer',
  },
  avatar: {
    border: '2px solid #fff',
    marginRight: theme.spacing(2),
  },
  body: {
    backgroundColor: colors.grey[100],
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
  },
  msgBody: {
    wordBreak: 'break-all',
  },
  head: {
    display: 'flex',
    alignItems: 'ceneter',
    justifyContent: 'space-between',
    gap: 12,
  },
  reply: {
    borderLeft: '1px solid #006AE4',
    paddingLeft: 8,
    cursor: 'pointer',
  },
  options: {
    cursor: 'pointer',
    width: 10,
    height: 10,
    display: 'flex',
    flexDirection: 'center',
    justifyContent: 'center',
  },
  reply_name: {
    fontSize: 14,
    lineHeight: '19px',
    color: '#000000',
  },
  reply_icon: {
    color: '#454545',
    cursor: 'pointer',
  },
  delete_icon: {
    color: '#454545',
    cursor: 'pointer',
  },
  forward_icon: {
    color: '#454545',
    cursor: 'pointer',
    transform: 'rotateY(180deg)',
  },
  content: {
    marginTop: theme.spacing(1),
    whiteSpace: 'pre-wrap',
  },
  image: {
    marginTop: theme.spacing(2),
    height: 'auto',
    width: 380,
    maxWidth: '100%',
  },
  footer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const OrgName = styled.p`
  font-size: 12px;
  opacity: 0.8;
`;

const Message = styled.div`
  ${(props) =>
    props.selectMode && props.isSelected && 'background-color: #b8bbbe3d'};
  ${(props) =>
    props.selectMode &&
    css`
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      &:hover {
        background-color: #b8bbbe3d;
      }
    `};
`;

export const MessageWrapper = styled.div`
  ${(props) => props.selectMode && 'pointer-events: none'};
  flex: 1;
  position: relative;
  padding-top: 8px;
  .inner {
    display: flex;
    maxwidth: 500;
  }
  .message-body {
    background-color: #fff;
  }
  &.makeStyles-authUser-340 {
    .message-body {
      background-color: #006ae4;
    }
  }
`;
const MenuContainer = styled.div`
  padding-top: 5px;
  flex-grow: 1;
`;
const RemovedMessage = styled.div`
  font-family: 'Helvetica';
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding-bottom: 4px;
  color: #000000;
`;

export const ConversationMessage = (props) => {
  const { message, reply, fileStorage, id, providerName, moveToReply, move } =
    props;
  // eslint-disable-next-line no-unused-vars
  const selectedMsg = useSelector((state) => state.chat.selectedMsg);
  const selectMode = selectedMsg.length > 0;
  const isSelected = selectedMsg.includes(getMsgOriginalIDForReply(message));

  const ref = React.useRef();
  const [participantPersone, setParticipantPersone] = React.useState(undefined);
  const user = useSelector((state) => state.session.user);
  const selectedCompany = useSelector((state) => state.session.selectedCompany);
  const dispatch = useDispatch();
  const classes = useMsgStyles();
  const { t } = useTranslation();

  const dialogs = useSelector((state) => state.chat.dialogs);
  const posts = useSelector((state) => state.post.posts);
  const postMode = useSelector((state) => state.settings.postMode);
  const selectedDialog = useSelector(selector.selectCurrentDialog);
  const dialog = !postMode
    ? dialogs.find((o) => o.uuid === selectedDialog)
    : posts.find((o) => o.uuid === selectedDialog);

    let mainInfo;
  if(dialog) {
    const { persone } = dialog;
    mainInfo = persone.info.filter((item) =>
      ['name', 'username', 'phone', 'uuidUser'].includes(item.key),
    );
  }
 

  const transformText = (text) => {  
    return text.replace(/{{\s*(\w+)\s*}}/g, (_, key) => {
      const matchedItem = mainInfo.find((item) => item.key === key);
      return matchedItem ? matchedItem.val : ' '; 
    });
  };


  React.useEffect(() => {
    if (ref && move) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
      });
      moveToReply(null);
    }
  }, [move]);

  const handleOpenGallery = () => {
    dispatch(galleryActions.fetchChatImages(message));
  };

  // TODO:убрать этот мусор
  const getImage = (msg) => {
    const { content: { data: { sizes = [] } = {} } = {} } = msg;
    if (!sizes.length) {
      return {
        src: null,
        loading: true,
      };
    }
    const thumb = sizes[sizes.length - 1];
    if (thumb.storage === 'messenger') {
      return (
        { src: fileStorage[`file:${thumb.fileId}`] } || {
          src: null,
          loading: true,
        }
      );
    }
    return (
      { src: thumb.fileUrl || thumb.fileSrc } || {
        src: null,
        loading: true,
      }
    );
  };

  const getVideo = (msg) => {
    const {
      content: {
        data: { thumb, files },
      },
    } = msg;
    // TODO: Something wrong here
    if (!files) {
      return {
        src: null,
        loading: true,
      };
    }

    const src =
      _.get(files, '0.storage') === 'messenger'
        ? fileStorage[`file:${_.get(files, '0.fileUrl')}`]
        : _.get(files, '0.fileUrl');
    const thumbSrc =
      _.get(thumb, 'sizes[0].storage') === 'messenger'
        ? fileStorage[`file:${_.get(thumb, 'sizes[0].fileId')}`]
        : _.get(thumb, 'sizes[0].fileUrl');
    return {
      src,
      thumb: thumbSrc,
      loading: false,
    };
  };

  const getSticker = (msg) => {
    const { content: { data: { sizes: [size] = [], srcFiles } = {} } = {} } =
      msg;
    const { render: { type: renderType, driver: renderDriver } = {} } = size;
    if (renderType === 'animation' && renderDriver === 'lottie') {
      const file = fileStorage[`sticker:${srcFiles[0].fileId}`];
      let loading = false;
      if (!file) {
        loading = true;
      }
      return {
        src: '',
        loading,
        json: file,
        lottie: true,
      };
    }

    // if (
    //   size.storage === 'messenger' ||
    //   _.get(size, 'render.type') === 'animation'
    // ) {
    //   const { render: { type: renderType, driver: renderDriver } = {} } = size;
    //   if (renderType === 'animation' && renderDriver === 'lottie') {
    //     // UNZIP
    //     const file = fileStorage[`file:${srcFiles[0].fileId}`];
    //     if (!file) return { src: null, loading: true };
    //     const zip = file.split(',')[1];
    //     const unziped = unzipB64(zip);
    //     return (
    //       { src: '', json: unziped, lottie: true } || {
    //         src: null,
    //         loading: true,
    //       }
    //     );
    //   }
    //   return (
    //     { src: fileStorage[`file:${srcFiles[0].fileId}`] } || {
    //       src: null,
    //       loading: true,
    //     }
    //   );
    // }
    return (
      {
        src: size.fileUrl,
        loading: false,
      } || {
        src: null,
        loading: true,
      }
    );
  };

  const renderMessageMenu = (options) => (
    <MenuContainer>
      {options.map(({ text, callback_data: cbd }, i) => (
        <Grid key={`kl-${cbd}-${i}`} container justify="center" spacing={2}>
          <Grid key={`kb-${cbd}`} item xs>
            <Paper className={classes.paper}>{text}</Paper>
          </Grid>
        </Grid>
      ))}
    </MenuContainer>
  );
  const renderMenu = (_message) => {
    const {
      uuid: key,
      content: {
        data: {
          additional: { keyboard },
        },
      },
    } = _message;
    return (
      <MenuContainer>
        {keyboard.map((line, i) => (
          <Grid key={`kl-${key}-${i}`} container justify="center" spacing={2}>
            {line.map(({ text, callback_data: cbd }) => (
              <Grid key={`kb-${key}-${i}-${cbd}`} item xs>
                <Paper className={classes.paper}>{text}</Paper>
              </Grid>
            ))}
          </Grid>
        ))}
      </MenuContainer>
    );
  };

  const renderChoice = (choice) => (
    <Paper className={classes.paper}>
      {choice.answerText || choice.answer}
    </Paper>
  );

  const makeLinksClickable = (text) => {
    const urlRegex = /(\bhttps?:\/\/[^\s]+)/g;

    return text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a href={part} key={index} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  const renderContent = (_message) => {
  
    switch (_message.content.type) {
      case 'message.text': {
        const messageText = _.get(_message, 'content.data.text', t('chatMessages.notSupported'));
        if (_message.type === 'out') {
          return (
            <Typography
              color="inherit"
              variant="body1"
              style={{ wordBreak: 'break-word' }}
            >
              {makeLinksClickable(transformText(messageText))}
            </Typography>
          );
        }
        return (
          <Typography
            color="inherit"
            variant="body1"
            style={{ wordBreak: 'break-word' }}
          >
            {makeLinksClickable(messageText)}
          </Typography>
        );
      }
   
      // TODO: Very baaaad!
      case 'message.menu':
        return (
          <>
            <Typography
              color="inherit"
              variant="body1"
              style={{ wordBreak: 'break-word' }}
            >
              <MarkdownElement
                data={_.get(_message, 'content.data.caption')}
                isTelegramMarkdown={providerName === 'telegram'}
              />
              {/* <Emojione
                size={64}
                svg
                text={}
              /> */}
            </Typography>
            {renderMessageMenu(_.get(_message, 'content.data.options', []))}
          </>
        );
      case 'message.choice':
        return renderChoice(_message.content.data);
      case 'message.photo': {
        const fileUrl = _.get(
          _.get(_message, 'content.data.sizes', []).sort(
            (left, right) => left.size - right.size,
          ),
          '0.fileUrl',
        );
        return (
          <PictureContent
            src={_.get(
              _message,
              'content.data.photo.url',
              _.get(_message, 'content.data.sizes.0.fileUrl'),
            )}
            caption={_.get(_message, 'content.data.caption')}
            downloadUrl={fileUrl}
            onOpenGallery={handleOpenGallery}
          />
        );
      }
      case 'message.photo_group': {
        return (
          <PictureGroup
            src={_.get(
              _message,
              'content.data.photo.url',
              _.get(_message, 'content.data.sizes.0.fileUrl'),
            )}
            caption={_.get(_message, 'content.data.caption')}
            downloadUrl={
              _.get(_message, 'content.data.sizes').sort(
                (left, right) => left.size - right.size,
              )[0].fileUrl
            }
            onOpenGallery={handleOpenGallery}
          />
        );
      }

      case 'message.image': {
        const image = getImage(_message);
        const sizes = _.get(_message, 'content.data.sizes');
        const imageSource = sizes
          ? _.get(
              sizes.sort((left, right) => left.size - right.size),
              '0.fileUrl',
              undefined,
            )
          : null;
        return (
          <PictureContent
            onOpenGallery={handleOpenGallery}
            src={image.src}
            loading={image.loading}
            caption={_message.content.data.caption}
            downloadUrl={imageSource}
          />
        );
      }
      case 'message.video': {
        const video = getVideo(_message);
        return (
          <VideoContent
            src={video.src}
            caption={_message.content.data.caption}
            size={_.get(_message, 'content.data.files.0.size')}
          />
        );
      }
      case 'message.sticker': {
        const sticker = getSticker(_message);
        return (
          <StickerContent
            src={sticker.src}
            lottie={sticker.lottie}
            json={sticker.json}
            loading={sticker.loading}
            caption={_message.content.data.caption}
          />
        );
      }
      case 'message.location': {
        return <MapContent data={_.get(_message, 'content.data')} />;
      }
      case 'message.audio': {
        return (
          <AudioContent
            src={_.get(_message, 'content.data.files.0.fileUrl')}
            size={_.get(_message, 'content.data.files.0.size')}
            fileName={_.get(_message, 'content.data.files.0.title')}
            duration={_.get(_message, 'content.data.additional.duration')}
            caption={_.get(_message, 'content.data.caption')}
          />
        );
      }
      case 'message.file': {
        return (
          <>
            {_message.content.data.files.map((file, index) => (
              <DocumentContent
                key={index}
                openGallery={handleOpenGallery}
                src={_.get(file, 'fileUrl')}
                fileName={_.get(file, 'title')}
                size={_.get(file, 'size')}
                caption={_.get(_message, 'content.data.caption')}
              />
            ))}
            {_message.content.data.caption && (
              <Typography
                color="inherit"
                variant="body1"
                style={{ wordBreak: 'break-word' }}
              >
                <MarkdownElement
                  data={_.get(_message, 'content.data.caption')}
                  isTelegramMarkdown={providerName === 'telegram'}
                />
              </Typography>
            )}
          </>
        );
      }
      default:
        return (
          <Typography color="inherit" variant="body1">
            {t('chatMessages.notSupported')}
            {/* <Paper elevation={3}>{JSON.stringify(_message.content)}</Paper> */}
          </Typography>
        );
    }
  };
  React.useEffect(() => {
    if (message.ids.uuidPersone) {
      getPersone(message.ids.uuidPersone).then((personeData) => {
        if (_.get(personeData, 'info')) {
          setParticipantPersone(personeData);
        } else {
          dispatch(chatActions.fetchPersoneByUuid(message.ids.uuidPersone));
        }
      });
    }
  }, [message]);

  const supportStore = useSelector((state) => state.support);
  // TODO: оптимизировать данную функцию
  const getUserName = (msg) => {
    if (msg.type === 'in') {
      if (participantPersone) {
        return getName(participantPersone);
      }
    }
    if (msg.type === 'out') {
      const { uuidUser } = msg.ids;
      if (uuidUser) {
        const pureUuid =
          uuidUser.indexOf('e:') === 0 ? uuidUser.split('e:')[1] : uuidUser;

        const findSupport = supportStore.data.find((s) => s.uuid === pureUuid);
        const isLoaded = supportStore.userLoaded.find(
          (uuid) => uuid === uuidUser,
        );
        if (findSupport) {
          return findSupport.name || findSupport.email;
        }
        if (!isLoaded) {
          dispatch(fetchGetSupportById(uuidUser));
        }
        return 'Support';
      }
      return 'Bot';
    }
    return null;
  };

  const renderStatus = (msg) => {
    const { status /* , provider: { viewed } = {} */ } = msg;
    switch (status) {
      case 'wait': {
        if (msg.date.created + 15000 < moment().valueOf()) {
          return (
            <>
              <Tooltip title={t('errors:logic.timeout')}>
                <WarningIcon color="error" fontSize="small" />
              </Tooltip>
              <CircularProgress size="15px" />
            </>
          );
        }
        return <CircularProgress size="15px" />;
      }
      case 'sended':
      case 'sent': {
        // if (viewed) return <ReadedIcon color="primary" fontSize="small" />;
        return <SentIcon color="primary" fontSize="small" />;
      }
      case 'read': {
        return <ReadedIcon color="primary" fontSize="small" />;
      }
      case 'delivered': {
        return <DeliveredIcon color="primary" fontSize="small" />;
      }
      case 'deleted': {
        return <DeletedIcon color="primary" fontSize="small" />;
      }
      case 'failed': {
        const errorText = _.get(msg, 'additional.errors', []).map(
          ({ name, message: flb }) => `${t(`errors:${name}`, flb || name)}\n`,
        );
        return (
          <Tooltip title={errorText}>
            <ErrorIcon color="error" fontSize="small" />
          </Tooltip>
        );
      }
      default:
        return null;
    }
  };

  const [isDebug, setDebug] = React.useState(false);

  const { status } = message;
  const username = getUserName(message);
 

  const replyID = getMsgOriginalIDForReply(message);
  const handleReply = () => {
    dispatch(
      chatActions.chatReplyMessage({
        uuid: replyID,
        username,
        content: renderContent(message),
      }),
    );
  };
  const handleDelete = () => {
    dispatch(
      showModal('ConfirmDialog', {
        title: t('chatMessages.delete'),
        subtitle: t('chatMessages.confirmDelete'),
        handleConfirm: () => {
          dispatch(
            chatActions.deleteMessage({
              providerName,
              messageIds: [replyID],
              ids: message.ids,
            }),
          );
        },
      }),
    );
  };
  const handleAiSelect = () => {
    if (message.content.type === 'message.text') {
      dispatch(setAiMessage(message.content.data.text));
      const menuItems = getMenuItems(t);
      const item = menuItems.find((menuItem) => menuItem.id === 'ai');
      if (item) {
        dispatch(handleOpenRightPanel(item));
      }
    }
  };
  const handleSelect = () => {
    dispatch(chatActions.addSelectedMsg(replyID));
  };
  const handleForward = () => {
    const { uuidMessage, uuidMessengerUser, uuidConnection } = message.ids;
    if (uuidMessage || uuidMessengerUser) {
      dispatch(
        showModal('ForwardModal', {
          msgID: [replyID],
          fromID: uuidMessengerUser || uuidMessage,
          providerName,
          uuidConnection,
        }),
      );
    }
  };
  const handleMoveToReply = () => {
    if (reply) {
      moveToReply(reply.uuid);
    }
  };
  const avatarSrc = React.useMemo(
    () =>
      username === 'Bot'
        ? '/img/avatar-robot.svg'
        : getAvatar(participantPersone) || genAvatar(message.ids.uuidPersone),
    [username],
  );
  const isDeveloper = _.get(user, 'group', '') === 'developer';
  const memorizedDate = React.useMemo(
    () =>
      isToday(message.date.created)
        ? `${t('common.date.today')} ${moment(message.date.created).format(
            'HH:mm',
          )}`
        : moment(message.date.created).format('HH:mm DD.MM'),
    [message.date.created],
  );

 


   

  return React.useMemo(
    () => (
      <Message
        onClick={() => selectMode && handleSelect()}
        isSelected={isSelected}
        selectMode={
          selectMode && ['sent', 'read', 'delivered'].includes(status)
        }
      >
        {selectMode && ['sent', 'read', 'delivered'].includes(status) && (
          <div className={classes.selectCheckBox}>
            {isSelected ? <CheckBoxOn /> : <CheckBoxOff />}
          </div>
        )}
        <MessageWrapper
          className={clsx(classes.root, {
            [classes.authUser]: message.type === 'out',
          })}
          id={`chatMessage-${id}`}
        >
          <div className="inner" ref={ref}>
            <Avatar
              className={classes.avatar}
              // component={RouterLink}
              src={avatarSrc}
            />
            <div className={classes.msgBody}>
              <div className={`${classes.body} message-body`}>
                <div>
                  {reply && PROVIDERS_CAN_REPLY.includes(providerName) && (
                    <div className={classes.reply} onClick={handleMoveToReply}>
                      <div className={classes.reply_name}>
                        {getUserName(reply) || <span>&nbsp;</span>}
                      </div>
                      {renderContent(reply)}
                    </div>
                  )}
                  <div>
                    <div className={classes.head}>
                      {status === 'deleted'
                        ? ''
                        : username || <span>&nbsp;</span>}
                      {['sent', 'read', 'delivered'].includes(status) && (
                        <ConversationMenu
                          providerName={providerName}
                          handleReply={handleReply}
                          handleSelect={handleSelect}
                          handleForward={handleForward}
                          handleDelete={handleDelete}
                          handleAiSelect={handleAiSelect}
                          isText={message.content.type === 'message.text'}
                        />
                      )}
                    </div>
                    {isDebug && isDeveloper && (
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => setDebug(false)}
                      >
                        <CancelIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </div>
                  <OrgName>
                    {message.type === 'out' &&
                      user.companies &&
                      user.companies.find(
                        (item) => item.uuid === selectedCompany,
                      )?.name}
                  </OrgName>
                </div>
                {status !== 'deleted' ? (
                  <div className={classes.content}>
                    {isDebug ? (
                      <>
                        <DebugView src={message} title="Отладка" />
                      </>
                    ) : (
                      <>
                        {renderContent(message)}
                        {_.has(message, 'content.data.additional.keyboard') &&
                          renderMenu(message)}
                      </>
                    )}
                  </div>
                ) : (
                  <RemovedMessage>{t('chatMessages.deleted')}</RemovedMessage>
                )}
              </div>
              <div className={classes.footer}>
                <Grid container alignItems="center" justify="flex-end">
                  <Grid item>
                    <Tooltip title={moment(message.date.created).fromNow()}>
                      <Typography className={classes.time} variant="body2">
                        {memorizedDate}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    {message.type === 'out' && renderStatus(message)}
                  </Grid>
                  {isDeveloper && (
                    <Grid item>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => setDebug(true)}
                      >
                        <CodeIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </div>
        </MessageWrapper>
      </Message>
    ),
    [
      fileStorage,
      username,
      status,
      isDebug,
      participantPersone,
      selectMode,
      isSelected,
      t,
      reply,
    ],
  );
};

ConversationMessage.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  fileStorage: PropTypes.object.isRequired,
  providerName: PropTypes.string.isRequired,
  participantPersone: PropTypes.object,
  moveToReply: PropTypes.func.isRequired,
  move: PropTypes.bool.isRequired,
};

ConversationMessage.defaultProps = {
  className: '',
  fileStorage: {},
  participantPersone: undefined,
};

export default ConversationMessage;
